import { OrderDateType } from "@ero/app-common/models";
import { Typography } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { getOrderStatusColor, getOrderStatusLabel } from "Utils";
import { displayDateRestriction } from "Utils/date";
import i18n from "i18n";
import React from "react";
import { useTranslation } from "react-i18next";

const DisplayOrdersDate: React.FC<{
  dateRestrictions: OrderDateType;
}> = ({ dateRestrictions }) => {
  const [t] = useTranslation();
  const content = displayDateRestriction(
    dateRestrictions,
    t("orders.create_modal.toDo"),
    t("orders.create_modal.until"),
  );
  return <div>{content}</div>;
};

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();
  return [
    {
      field: "internalId",
      maxWidth: 100,
      headerName: t("orders.fields.id"),
      valueGetter: (params) => params.row.internalId,
    },
    {
      field: "name",
      minWidth: 200,
      headerName: t("general.fields.name"),
    },
    {
      field: "status",
      minWidth: 100,
      maxWidth: 200,
      headerName: t("general.fields.status"),
      valueGetter: (params) => getOrderStatusLabel(params.row.status),
      renderCell: (params) => {
        const color = getOrderStatusColor(params.row.status);
        return (
          <Typography variant="body2" color={color}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "customer",
      minWidth: 200,
      headerName: t("general.fields.customer"),
      valueGetter: (params) => params.row.customer.companyName,
    },
    {
      field: "jobs",
      maxWidth: 100,
      headerName: t("orders.labels.jobs_tab"),
      valueGetter: (params) => params.row?.jobDetails?.jobCount || 0,
    },
    {
      field: "dateRestrictions",
      renderCell: (params) => (
        <DisplayOrdersDate dateRestrictions={params.value} />
      ),
      minWidth: 200,
      headerName: t("orders.create_modal.date"),
      valueFormatter: (params) =>
        displayDateRestriction(
          params.value,
          i18n.t("orders.create_modal.toDo"),
          i18n.t("orders.create_modal.until"),
        ),
    },
  ];
};
