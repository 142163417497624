import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";

import { Help } from "@mui/icons-material";
import {
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { useStaticDropdownValues } from "Hooks/dropdownValues";
import { t } from "i18next";
import { YouTubeModal } from "ProjectComponents/youtubeModal/YouTubeModal";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { NotificationService } from "Services";
import { Coordinate } from "Types";
import { ImportParcelTableRow } from "../../types";
import { ImportMap } from "./components/map/ImportMap";
import { usePolygonOperations } from "./components/map/polygonOperations/usePolygonOperations";
import { POLYGON_EDIT_MODE } from "./components/map/utils";
import { ParcelsTable } from "./components/table/table";

type ParcelsOverviewProps = {
  isOpen: boolean;
  onClose: () => void;
  parcelData: ImportParcelTableRow[];
  setParcelData: React.Dispatch<React.SetStateAction<ImportParcelTableRow[]>>;
  onAddSelectedParcels: (selectedParcels: ImportParcelTableRow[]) => void;
  accessToken?: string;
};

const ParcelsOverview: React.FC<ParcelsOverviewProps> = ({
  isOpen,
  onClose,
  parcelData,
  setParcelData,
  onAddSelectedParcels,
  accessToken,
}) => {
  const mapRef = useRef<google.maps.Map>();
  const tableRef = useGridApiRef();
  const splitLineRef = useRef<google.maps.Polyline | null>(null);

  const [selectedParcelIds, setSelectedParcels] = useState<number[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [editParcelId, setEditParcelId] = useState<GridRowId>();
  const [parcelIdsToMerge, setParcelIdsToMerge] = useState<number[]>([]);
  const [polygonEditMode, setPolygonEditMode] = useState<POLYGON_EDIT_MODE>(
    POLYGON_EDIT_MODE.NONE,
  );
  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const openHelpDialog = useCallback(() => setShowHelpDialog(true), []);
  const closeHelpDialog = useCallback(() => setShowHelpDialog(false), []);

  const parcelInEditMode: boolean = useMemo(
    () =>
      parcelData.filter(
        (parcel) => editParcelId && parcel._id === +editParcelId,
      ).length > 0,
    [editParcelId, parcelData],
  );

  const handleAddSelectedParcels = useCallback(() => {
    onAddSelectedParcels(
      parcelData.filter((parcel: ImportParcelTableRow) =>
        selectedParcelIds.includes(parcel?._id ?? -1),
      ),
    );
    setSelectedParcels([]);
  }, [onAddSelectedParcels, parcelData, selectedParcelIds]);

  const handleScrollTable = useCallback(
    (rowIndex: number) => {
      const currentApiRef = tableRef?.current;

      if (rowIndex >= 0 && currentApiRef) {
        const pageSize =
          currentApiRef.state.pagination.paginationModel.pageSize;

        const currentPage = currentApiRef.state.pagination.paginationModel.page;

        const pageIndex = Math.floor(rowIndex / pageSize);

        if (pageIndex !== currentPage) {
          currentApiRef.setPage(pageIndex);
        }

        setTimeout(() => {
          currentApiRef.scrollToIndexes({ rowIndex, colIndex: 0 });
        }, 100);
      }
    },

    [tableRef],
  );

  const fitMap = useCallback((coordinates: Coordinate[]) => {
    const bounds = new google.maps.LatLngBounds();
    coordinates.forEach((coord) => bounds.extend(coord));
    mapRef.current?.fitBounds(bounds);
  }, []);

  const onCancelEditParcelData = useCallback((id: GridRowId) => {
    setRowModesModel((val) => ({
      ...val,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));
    setEditParcelId(undefined);
    setParcelIdsToMerge([]);
    setPolygonEditMode(POLYGON_EDIT_MODE.NONE);
  }, []);

  const onEditParcelData = useCallback(
    (id: GridRowId) => {
      setRowModesModel((val) => ({
        ...val,
        [id]: { mode: GridRowModes.Edit },
      }));
      if (editParcelId !== undefined) {
        onCancelEditParcelData(editParcelId);
      }
      setEditParcelId(id);
    },
    [editParcelId, onCancelEditParcelData],
  );

  const onRowClick = useCallback(
    ({ row }: GridRowParams<ImportParcelTableRow>) => {
      onEditParcelData(row._id);
      if (row.shape) {
        fitMap(row.shape);
      }
    },
    [fitMap, onEditParcelData],
  );

  const onRowSelect = useCallback(
    (ids: GridRowId[]) => {
      setSelectedParcels(ids as number[]);
    },
    [setSelectedParcels],
  );

  const onEndEditParcelData = useCallback(
    (id: GridRowId | undefined, ignoreModifications?: boolean) => {
      setRowModesModel(() =>
        parcelData.reduce(
          (acc, curr) => ({
            ...acc,
            [curr._id]: { mode: GridRowModes.View, ignoreModifications },
          }),
          {},
        ),
      );
      setEditParcelId(undefined);
      setParcelIdsToMerge([]);
      if (id !== undefined) {
        setSelectedParcels((val) => (val.includes(+id) ? val : [...val, +id]));
      }
    },
    [parcelData],
  );

  const handleUpdateRow = useCallback(
    (newRow: GridValidRowModel): GridValidRowModel => {
      setParcelData((val) =>
        val.map((parcel: ImportParcelTableRow) => {
          if (parcel._id !== newRow._id) {
            return parcel;
          }
          return {
            ...newRow,
            _id: newRow._id,
            shape: newRow.shape,
            name: newRow.name,
            sizeField: newRow.sizeField,
            rowAmount: newRow.rowAmount > 0 ? newRow.rowAmount : undefined,
          };
        }),
      );

      fitMap(parcelData.map((parcel) => parcel.shape).flat());

      return {
        ...newRow,
        rowAmount: newRow.rowAmount > 0 ? newRow.rowAmount : undefined,
      };
    },
    [fitMap, parcelData, setParcelData],
  );

  const handleUpdateRowError = useCallback((error: any) => {
    NotificationService.error(
      "Process row update failed",
      JSON.stringify(error),
    );
  }, []);

  const onParcelClick = useCallback(
    (id: number) => {
      switch (polygonEditMode) {
        case POLYGON_EDIT_MODE.NONE: {
          if (editParcelId === undefined) {
            onEditParcelData(id);
            const rowIndex = parcelData.findIndex(
              (parcel) => parcel._id === id,
            );
            handleScrollTable(rowIndex); // table is zero based
          }
          break;
        }
        case POLYGON_EDIT_MODE.MERGE: {
          const parcelAlreadySelected =
            parcelIdsToMerge.find((parcel) => parcel === id) !== undefined;
          setParcelIdsToMerge(
            parcelAlreadySelected
              ? [...parcelIdsToMerge.filter((parcel) => parcel !== id)]
              : [...parcelIdsToMerge, id],
          );
          break;
        }
      }
    },
    [
      polygonEditMode,
      editParcelId,
      onEditParcelData,
      parcelData,
      handleScrollTable,
      parcelIdsToMerge,
    ],
  );

  const onSplitComplete = useCallback(
    (newParcelData: ImportParcelTableRow[]) => {
      setParcelData(newParcelData);
      setPolygonEditMode(POLYGON_EDIT_MODE.NONE);
      onEndEditParcelData(undefined, true);
    },
    [onEndEditParcelData, setParcelData],
  );

  const onMergeComplete = useCallback(
    (newParcelData: ImportParcelTableRow[]) => {
      setParcelData(newParcelData);
      setPolygonEditMode(POLYGON_EDIT_MODE.NONE);
      onEndEditParcelData(undefined, true);
    },
    [onEndEditParcelData, setParcelData],
  );

  const enableSplitMode = useCallback(() => {
    setPolygonEditMode(POLYGON_EDIT_MODE.SPLIT);
  }, []);

  const enableMergeMode = useCallback(() => {
    setPolygonEditMode(POLYGON_EDIT_MODE.MERGE);
    if (editParcelId !== undefined) {
      setParcelIdsToMerge([+editParcelId]);
    }
  }, [editParcelId]);

  const cancelOperation = useCallback(() => {
    setPolygonEditMode(POLYGON_EDIT_MODE.NONE);
    setParcelIdsToMerge([]);
  }, []);

  const polygonOperations = usePolygonOperations(
    parcelData,
    editParcelId,
    parcelIdsToMerge,
    splitLineRef,
    onSplitComplete,
    onMergeComplete,
  );

  const { values: cropOptions } = useStaticDropdownValues("crop", accessToken);

  const showSelectParcelsAlert = useMemo(
    () => selectedParcelIds.length == 0,
    [selectedParcelIds.length],
  );

  const showSaveParcelAlert = useMemo(
    () => parcelInEditMode && selectedParcelIds.length > 0,
    [parcelInEditMode, selectedParcelIds.length],
  );

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={false}>
        <DialogTitle sx={{ pb: 0 }}>
          {t("parcels.upload.overview.title")}
        </DialogTitle>
        <CloseButton onClose={onClose} />
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{ pt: 1, pl: 2, width: "100%", height: "80vh" }}
          >
            <Grid item xs={6}>
              <ImportMap
                mapRef={mapRef}
                splitLineRef={splitLineRef}
                parcels={parcelData}
                selectedParcelIds={selectedParcelIds}
                editParcelId={editParcelId}
                mergeParcelIds={parcelIdsToMerge}
                onParcelClick={onParcelClick}
                editMode={polygonEditMode}
                polygonOperations={{
                  ...polygonOperations,
                  enableMergeMode,
                  enableSplitMode,
                  cancelOperation,
                }}
              />
            </Grid>
            <Grid item xs={6} sx={{ height: "100%" }}>
              <Box
                sx={
                  showSelectParcelsAlert || showSaveParcelAlert
                    ? { height: "calc(100% - 48px)" }
                    : { height: "100%" }
                }
              >
                <ParcelsTable
                  apiRef={tableRef}
                  cropOptions={cropOptions}
                  parcelData={parcelData}
                  handleUpdateRow={handleUpdateRow}
                  handleUpdateRowError={handleUpdateRowError}
                  selectedParcelIds={selectedParcelIds}
                  rowModesModel={rowModesModel}
                  onRowClick={onRowClick}
                  onRowSelect={onRowSelect}
                  editRowId={editParcelId}
                  actions={{
                    onEditClick: onEditParcelData,
                    onSaveClick: onEndEditParcelData,
                    onCancelClick: onCancelEditParcelData,
                  }}
                />
              </Box>
              {showSelectParcelsAlert && (
                <Alert severity="warning">
                  {t(
                    "parcels.upload.overview.alertNotification.selectParcelsToImport",
                  )}
                </Alert>
              )}
              {showSaveParcelAlert && (
                <Alert severity="info">
                  {t(
                    "parcels.upload.overview.alertNotification.saveCurrentParcel",
                  )}
                </Alert>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={openHelpDialog} startIcon={<Help />} color="info">
            {t("general.navigation.help")}
          </Button>
          <Button
            onClick={handleAddSelectedParcels}
            disabled={selectedParcelIds.length == 0 || parcelInEditMode}
            variant="contained"
          >
            {t("parcels.upload.overview.addParcels", {
              count: selectedParcelIds.length,
            })}
          </Button>
        </DialogActions>
      </Dialog>
      <YouTubeModal
        open={showHelpDialog}
        onClose={closeHelpDialog}
        videoUrl="https://www.youtube-nocookie.com/embed/0lPeW03bgXc?si=zTc0_d_jKgQOCMss"
      />
    </>
  );
};

export default ParcelsOverview;
