import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface IHelpText {
  translations: string;
}

export const HelpText: React.FC<IHelpText> = ({ translations }) => {
  const { t } = useTranslation();

  const title = useMemo(() => t(`${translations}.title`), [t, translations]);

  const blocks = useMemo(
    () =>
      t(`${translations}.blocks`, {
        returnObjects: true,
        defaultValue: [],
      }) as { title: string; text: string }[],
    [t, translations],
  );

  const content = useMemo(() => {
    return blocks.map((block) => (
      <>
        <Typography variant="h6">{block.title}</Typography>
        <Typography variant="body1">{block.text}</Typography>
      </>
    ));
  }, [blocks]);

  return (
    <>
      <Typography variant="h5">{title}</Typography>
      {content}
    </>
  );
};
