import * as Yup from "yup";

export type ValuesType = {
  customer?: string[];
  service?: string[];
  crop?: string[];
  cropColor?: string;
  regionette?: string[];
  mark?: string[];
  useOrderDateRange: boolean;
};

export const validationSchema = Yup.object<ValuesType>().shape({
  customer: Yup.array(),
  service: Yup.array(),
  crop: Yup.array(),
  cropColor: Yup.string(),
  regionette: Yup.array(),
  mark: Yup.array(),
  useOrderDateRange: Yup.boolean(),
});

export const initialValues: ValuesType = {
  customer: undefined,
  service: undefined,
  crop: undefined,
  cropColor: undefined,
  regionette: undefined,
  mark: undefined,
  useOrderDateRange: false,
};
