import { Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Steps } from "../steps";

interface IStepperContent {
  activeStep: number;
  stepsExtended: boolean;
}

export const StepperContent: React.FC<IStepperContent> = ({
  activeStep,
  stepsExtended,
}) => {
  const [t] = useTranslation();
  const stepLabels = useMemo(
    () => [
      t("orders.create_modal.steps.general"),
      t("orders.create_modal.steps.parcelsAndServices"),
      t("orders.create_modal.steps.date"),
    ],
    [t],
  );
  const extendedStepLabels = useMemo(
    () => [
      t("orders.create_modal.steps.general"),
      t("orders.create_modal.steps.parcels"),
      t("orders.create_modal.steps.services"),
      t("orders.create_modal.steps.overview"),
      t("orders.create_modal.steps.date"),
    ],
    [t],
  );

  const steps = useMemo(() => {
    const currentStepLabels = stepsExtended ? extendedStepLabels : stepLabels;
    return currentStepLabels.map((label, index) => (
      <Step
        key={label}
        completed={
          /* mark step for order details as incomplete when skipped */
          index == Steps.PARCELS && !stepsExtended && activeStep > Steps.PARCELS
            ? false
            : undefined
        }
      >
        <StepLabel
          StepIconProps={{
            ...(activeStep == index && { sx: { fontSize: 40 } }),
          }}
        >
          <Typography fontSize={activeStep == index ? 20 : 14}>
            {label}
          </Typography>
        </StepLabel>
      </Step>
    ));
  }, [activeStep, extendedStepLabels, stepLabels, stepsExtended]);

  return <Stepper activeStep={activeStep}>{steps}</Stepper>;
};
