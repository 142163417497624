import { JOBSTATUS } from "@ero/app-common/enums";
import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { MarkerClusterer } from "@react-google-maps/api";
import { MapParcel } from "ProjectComponents/map/components";
import React, { useMemo } from "react";
import { markerIcons } from "Utils";

const parcelClusterMaxZoom = 16;
interface IParcels {
  zoom?: number;
  parcels: ParcelsResponseBody["data"];
  itemOnClick?: (id: number) => void;
  selectedParcels: number[];
}

export const ParcelFigures: React.FC<IParcels> = ({
  zoom,
  parcels,
  itemOnClick,
  selectedParcels,
}) => {
  const showPolygon = useMemo(
    () => (zoom ? zoom > parcelClusterMaxZoom : false),
    [zoom],
  );

  const parcelFigures = useMemo(
    () => (
      <MarkerClusterer
        options={{
          maxZoom: parcelClusterMaxZoom,
          averageCenter: true,
        }}
      >
        {(clusterer) => (
          <>
            {parcels.map((parcel) => (
              <MapParcel
                key={parcel._id}
                showPolygon={showPolygon}
                parcel={parcel}
                itemOnClick={itemOnClick}
                markerProps={{
                  clusterer,
                  customIcon: selectedParcels.includes(parcel._id)
                    ? markerIcons[JOBSTATUS.DONE]
                    : markerIcons[-1],
                }}
                fillColor={
                  selectedParcels.includes(parcel._id) ? "#8efbcc" : "#fff"
                }
              />
            ))}
          </>
        )}
      </MarkerClusterer>
    ),
    [parcels, showPolygon, itemOnClick, selectedParcels],
  );

  return <>{parcelFigures}</>;
};
