import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import {
  DriverMachineCard,
  MapCard,
  OverviewCard,
  StatusesCard,
  TelemetryCard,
  TimeCard,
} from "./components";
import { NotesCard } from "./components/notesCard/notesCard";

interface IJobDetailsModal {
  isOpen: boolean;
  onClose: () => void;
  jobId?: number;
}

export const JobDetailsModal: React.FC<IJobDetailsModal> = ({
  isOpen,
  onClose,
  jobId,
}) => {
  const allOrdersJobs = useSelector(
    (state: AppState) => state.orderDetails.order?.jobDetails?.jobs,
  );

  const job = useMemo(
    () => allOrdersJobs?.find((job) => job._id === jobId),
    [allOrdersJobs, jobId],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      scroll="paper"
    >
      <DialogTitle>
        {job?.name} - {job?.parcel.name}
      </DialogTitle>
      <CloseButton onClose={onClose} />
      <DialogContent dividers>
        <Grid container padding={3} spacing={2}>
          <Grid item xs={12} lg={4} container spacing={2}>
            <Grid item xs={12}>
              <OverviewCard job={job} />
            </Grid>
            <Grid item xs={12}>
              <TimeCard job={job} />
            </Grid>
            <Grid item xs={12}>
              <DriverMachineCard job={job} />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={8} container spacing={2}>
            <Grid
              item
              xs={12}
              lg={6}
              sx={{ height: "100%" }}
              container
              spacing={2}
            >
              <Grid item xs={12}>
                {job && (
                  <StatusesCard statusOperations={job.statusOperations} />
                )}
              </Grid>
              <Grid item xs={12}>
                <NotesCard jobId={job?._id} notes={job?.notes} />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ height: "100%" }}>
              {job && <TelemetryCard jobId={job?._id} />}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MapCard job={job} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
