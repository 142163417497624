import { DROPDOWN_DEBOUNCE } from "Constants";
import { errorToast } from "Services/notifications";
import { type OptionType } from "Types";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { type ISelect } from "./autocomplete";

export function useServerSearch(props: ISelect) {
  const { fetchOptions, options: userOptions } = props;

  const [options, setOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const lastQuery = useRef<string>(); // last query

  const [hasMore, setHasMore] = useState(true);

  const isMountedRef = useRef(true);

  useEffect(() => {
    if (Array.isArray(userOptions)) {
      setOptions(userOptions);
    }
  }, [userOptions]);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const updateOptions = useCallback(
    async (query: string | number = "", loadMore = false) => {
      const offset = loadMore ? options.length : 0;
      if (!fetchOptions || (query === lastQuery.current && !loadMore)) {
        return;
      }
      /** If form value was set outside component */
      const searchByValue = typeof query === "number";

      const optionsContainQuery = options.some(
        (opt) => opt.label === query || opt.value === query,
      );
      if (optionsContainQuery) {
        // Prevent loading if query is in one of already loaded options
        // (double request issue)
        return;
      }

      setIsLoading(true);

      try {
        let newOptions: OptionType[] = [];
        let totalMaxCount: number = 0;

        const requestObj = searchByValue
          ? { id: +query, offset }
          : { query: query.toString(), offset };

        const { chunk, maxCount } = await fetchOptions(requestObj);
        if (!isMountedRef.current) {
          return;
        }
        if (searchByValue) {
          const optionById = chunk.find((option) => option.value === query);
          if (optionById) {
            const { chunk: chunk2, maxCount: maxCount2 } = await fetchOptions({
              query: optionById.label,
              offset: 0,
            });
            if (!isMountedRef.current) {
              return;
            }
            const chunkWithSameLabel = chunk2.filter(
              (option) => option.value !== optionById.value,
            );
            newOptions = [optionById, ...chunkWithSameLabel];
            totalMaxCount = maxCount2;
            lastQuery.current = optionById.label;
          } else {
            newOptions = [...chunk];
          }
        } else {
          if (loadMore) {
            newOptions = options.concat(chunk);
          } else {
            newOptions = chunk;
          }
          totalMaxCount = maxCount;
          lastQuery.current = query.toString();
        }

        setHasMore(newOptions.length < totalMaxCount);

        setOptions(newOptions);

        setIsLoading(false);
      } catch (err) {
        errorToast(err);
      }
    },
    [options, fetchOptions],
  );

  const updateOptionsDebounced = useMemo(
    () => debounce(updateOptions, DROPDOWN_DEBOUNCE),
    [updateOptions],
  );

  const onListScroll = useCallback(
    (e) => {
      if (!hasMore || isLoading) {
        return;
      }
      const target = e.target;
      const diff = target.scrollHeight - target.scrollTop - target.offsetHeight;
      if (diff < 10) {
        updateOptions(lastQuery.current, true);
      }
    },
    [updateOptions, hasMore, isLoading],
  );

  return {
    updateOptions,
    updateOptionsDebounced,
    hasMore,
    isLoading,
    onListScroll,
    options,
  };
}

export function useHalfClick(props: ISelect) {
  const [open, setOpen] = useState(false);
  const enabled = props.extendedKeyboardBehavior;

  const onPointerDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const div = e.currentTarget;
      const rect = div.getBoundingClientRect();
      const frac = (e.clientX - rect.x) / rect.width;
      const shouldOpenPopup = frac >= 0.5;
      setOpen(shouldOpenPopup);
    },
    [setOpen],
  );

  const onBlur = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    isRightSideClick: enabled ? open : undefined,
    onPointerDown: enabled ? onPointerDown : undefined,
    onBlur: enabled ? onBlur : undefined,
  };
}
