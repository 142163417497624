import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import { OneFieldForm } from "Components";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "Store";
import { updateJobNotes } from "Store/orderDetails";

interface INotesCard {
  jobId?: number;
  notes?: JobResponseBody["notes"];
}

export const NotesCard: React.FC<INotesCard> = ({ notes, jobId }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const notesUpdating = useSelector(
    (state: AppState) => state.orderDetails.jobNotesUpdating,
  );

  const handleUpdateJob = useCallback(
    (notes: string) => {
      jobId && dispatch(updateJobNotes({ _id: jobId, notes }));
    },
    [dispatch, jobId],
  );

  const notesContent = useMemo(() => {
    return (
      <>
        {notesUpdating && (
          <Skeleton height={200} width="100%" animation="pulse" />
        )}
        {!notesUpdating && (
          <OneFieldForm
            label=""
            value={notes}
            onSubmit={(values) => handleUpdateJob(values.jobNotes)}
            success={!notesUpdating}
            name="jobNotes"
            showButtonsBelow
            multiline
          ></OneFieldForm>
        )}
      </>
    );
  }, [handleUpdateJob, notes, notesUpdating]);

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">{t("general.fields.notes")}</Typography>
          </Grid>
          <Grid item xs={12} marginTop={2}>
            {notesContent}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
