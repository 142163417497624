import { OrdersRequestQuery } from "@ero/app-common/v2/routes/models/order";
import { createOrder, deleteOrders, getOrdersV2 } from "Api";
import { errorToast } from "Services";
import { AppState } from "Store/store";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  ORDERS_ACTION_TYPES,
  type ICreateOrdersRequestAction,
  type IDeleteOrdersRequestAction,
  type IGetOrdersRequestAction,
} from "./action-types";
import { getOrdersSuccess, setError, setLoading } from "./actions";

export function* getOrdersSaga({ payload }: IGetOrdersRequestAction) {
  try {
    yield put(setLoading());
    const { limit, offset, search } = payload;
    const store: AppState = yield select();

    const params: OrdersRequestQuery = {
      limit: limit ?? store.orders.limit,
      offset: offset ?? store.orders.offset,
      search: search ?? store.orders.search,
      sortBy: ["status", "date"],
    };

    const { data, maxCount, lastOrderId } = yield call(getOrdersV2, params);
    yield put(getOrdersSuccess({ data, maxCount, lastOrderId }));
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* createOrderSaga({ payload }: ICreateOrdersRequestAction) {
  const { order } = payload;

  try {
    yield put(setLoading());

    const { data: orderData } = yield call(createOrder, {
      ...order,
      dateRestrictions: {
        dateMode: order.dateRestrictions?.dateMode || undefined,
        timeMode: order.dateRestrictions?.timeMode || undefined,
        date: order.dateRestrictions?.date || undefined,
        startDate: order.dateRestrictions?.startDate || undefined,
        endDate: order.dateRestrictions?.endDate || undefined,
        dayTime: {
          startTime: order.dateRestrictions?.dayTime?.startTime || undefined,
          endTime: order.dateRestrictions?.dayTime?.endTime || undefined,
        },
      },
    });

    const store: AppState = yield select();

    const params: OrdersRequestQuery = {
      limit: store.orders.limit,
      offset: store.orders.offset,
      sortBy: ["status", "date"],
    };

    const { data, maxCount, lastOrderId } = yield call(getOrdersV2, params);
    yield put(
      getOrdersSuccess({
        data,
        maxCount,
        lastOrderId,
        createdId: orderData._id,
      }),
    );
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* deleteOrdersSaga({ payload }: IDeleteOrdersRequestAction) {
  try {
    yield put(setLoading());

    yield call(deleteOrders, payload);

    const store = yield select();

    const params: OrdersRequestQuery = {
      limit: store.orders.limit,
      offset: store.orders.offset,
      sortBy: ["status", "date"],
    };

    const { data, maxCount, lastOrderId } = yield call(getOrdersV2, params);
    yield put(getOrdersSuccess({ data, maxCount, lastOrderId }));
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export default function* ordersSagas() {
  yield all([
    takeEvery(ORDERS_ACTION_TYPES.GET_ORDERS_REQUEST, getOrdersSaga),
    takeEvery(ORDERS_ACTION_TYPES.CREATE_ORDERS_REQUEST, createOrderSaga),
    takeEvery(ORDERS_ACTION_TYPES.DELETE_ORDERS_REQUEST, deleteOrdersSaga),
  ]);
}
