import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Edit } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { ROUTES } from "Constants";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// const dateTimeFormat = "DD.MM.YYYY HH:mm";
const dateTimeFormat = new Intl.DateTimeFormat([], {
  dateStyle: "medium",
  timeStyle: "short",
});

interface ITimeCard {
  job?: JobResponseBody;
}

export const TimeCard: React.FC<ITimeCard> = ({ job }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const jobStart = useMemo(
    () =>
      job?.start && job?.start !== -1
        ? dateTimeFormat.format(new Date(job?.start))
        : "-",
    [job?.start],
  );

  const jobEnd = useMemo(
    () =>
      job?.end && job?.end !== -1
        ? dateTimeFormat.format(new Date(job?.end))
        : "-",
    [job?.end],
  );

  const navigateToPlanning = useCallback(() => {
    const state: {
      initialDate?: number;
      scrollTime?: number;
      orderId?: number;
    } = {};
    if (job?.start !== -1 && job?.end !== -1) {
      state.initialDate = job?.start;
      state.scrollTime = job?.start;
    } else {
      state.orderId = job.order;
    }
    navigate(`${ROUTES.MAIN.PLANNING}`, {
      state,
    });
  }, [job, navigate]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h6">
              {t("general.navigation.calendar")}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={navigateToPlanning}>
              <Edit fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemText
                  primary={jobStart}
                  secondary={t("general.fields.start")}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary={jobEnd}
                  secondary={t("general.fields.end")}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
