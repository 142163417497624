import {
  api_customers_addPerson,
  deleteCustomerEmployees,
  getCustomerEmployees,
  updateCustomerEmployee,
} from "Api";
import { errorToast } from "Services";
import { type ApiResponse, type CustomerEmployeesListResponse } from "Types";
import { getTableParamsAfterDeletion } from "Utils";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  CUSTOMER_PERSONS_ACTION_TYPES,
  type ICreateCustomerPersonAction,
  type IDeleteCustomerPersonAction,
  type IGetCustomerPersonsRequestAction,
  type IGetInitialCustomerPersonsRequestAction,
  type IUpdateCustomerPersonAction,
} from "./action-types";
import {
  getCustomerPersonSuccess,
  getCustomerPersonsInitialError,
  getCustomerPersonsInitialSuccess,
  resetMeta,
  setError,
  setLoading,
  setSuccess,
} from "./actions";

export function* getInitialCustomerEmployeesSaga({
  payload,
}: IGetInitialCustomerPersonsRequestAction) {
  const { params, customerId } = payload;

  try {
    const store = yield select();

    const { data }: ApiResponse<CustomerEmployeesListResponse> = yield call(
      getCustomerEmployees,
      customerId,
      params,
      store.customerEmployees.searchData,
    );

    yield put(
      getCustomerPersonsInitialSuccess(data.data, data.maxCount, customerId),
    );
  } catch (error) {
    yield put(getCustomerPersonsInitialError());

    errorToast(error);
  }
}

export function* getCustomerEmployeesSaga({
  payload,
}: IGetCustomerPersonsRequestAction) {
  const { params, search, withSuccess } = payload;

  try {
    const store = yield select();

    const { data }: ApiResponse<CustomerEmployeesListResponse> = yield call(
      getCustomerEmployees,
      store.customerEmployees.customerId,
      params,
      search,
    );

    yield put(
      getCustomerPersonSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: params,
        search,
      }),
    );

    if (withSuccess) {
      yield put(setSuccess());

      yield put(resetMeta());
    }
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* createCustomerEmployeeSaga({
  payload,
}: ICreateCustomerPersonAction) {
  const { data } = payload;

  try {
    const store = yield select();

    yield put(setLoading());

    yield call(api_customers_addPerson, data, {
      customer: store.customerEmployees.customerId,
    });

    const customerEmployeesResult: ApiResponse<CustomerEmployeesListResponse> =
      yield call(
        getCustomerEmployees,
        store.customerEmployees.customerId,
        store.customerEmployees.listMeta,
        store.customerEmployees.searchData,
      );

    yield put(
      getCustomerPersonSuccess({
        list: customerEmployeesResult.data.data,
        maxCount: customerEmployeesResult.data.maxCount,
        listMeta: store.customerEmployees.listMeta,
        search: store.customerEmployees.searchData,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* updateCustomerEmployeeSaga({
  payload,
}: IUpdateCustomerPersonAction) {
  const { updateData, id } = payload;

  try {
    const store = yield select();

    yield put(setLoading());

    yield call(
      updateCustomerEmployee,
      updateData,
      id,
      store.customerEmployees.customerId,
    );

    const customerEmployeesResult: ApiResponse<CustomerEmployeesListResponse> =
      yield call(
        getCustomerEmployees,
        store.customerEmployees.customerId,
        store.customerEmployees.listMeta,
        store.customerEmployees.searchData,
      );

    yield put(
      getCustomerPersonSuccess({
        list: customerEmployeesResult.data.data,
        maxCount: customerEmployeesResult.data.maxCount,
        listMeta: store.customerEmployees.listMeta,
        search: store.customerEmployees.searchData,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* deleteCustomerEmployeesSaga({
  payload,
}: IDeleteCustomerPersonAction) {
  const { ids } = payload;

  try {
    const store = yield select();

    yield call(
      deleteCustomerEmployees,
      ids,
      store.customerEmployees.customerId,
    );

    const listMeta = getTableParamsAfterDeletion(
      store.customerEmployees.listMeta,
      store.customerEmployees.list.length,
      ids.length,
    );

    const customerEmployeesResult: ApiResponse<CustomerEmployeesListResponse> =
      yield call(
        getCustomerEmployees,
        store.customerEmployees.customerId,
        listMeta,
        store.customerEmployees.searchData,
      );

    yield put(
      getCustomerPersonSuccess({
        list: customerEmployeesResult.data.data,
        maxCount: customerEmployeesResult.data.maxCount,
        listMeta,
        search: store.customerEmployees.searchData,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export default function* customersSagas() {
  yield all([
    takeEvery(
      CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_REQUEST,
      getInitialCustomerEmployeesSaga,
    ),
    takeEvery(
      CUSTOMER_PERSONS_ACTION_TYPES.GET_CUSTOMER_PERSONS_REQUEST,
      getCustomerEmployeesSaga,
    ),
    takeEvery(
      CUSTOMER_PERSONS_ACTION_TYPES.CREATE_CUSTOMER_PERSON,
      createCustomerEmployeeSaga,
    ),
    takeEvery(
      CUSTOMER_PERSONS_ACTION_TYPES.UPDATE_CUSTOMER_PERSON,
      updateCustomerEmployeeSaga,
    ),
    takeEvery(
      CUSTOMER_PERSONS_ACTION_TYPES.DELETE_CUSTOMER_PERSON,
      deleteCustomerEmployeesSaga,
    ),
  ]);
}
