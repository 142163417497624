import { type spec_shape } from "@ero/app-common/routes/validators";
import {
  createService,
  deleteServices,
  getExampleFile,
  getServices,
  removeFileFromService,
  updateService,
  uploadFileToService,
} from "Api";
import { errorToast, successToast } from "Services";
import { type ApiResponse, type ServicesListResponse } from "Types";
import { downloadCsvFile, getTableParamsAfterDeletion } from "Utils";
import i18n from "i18next";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import z from "zod";
import {
  SERVICES_ACTION_TYPES,
  type ICreateServiceAction,
  type IDeleteServicesAction,
  type IGetExampleFileRequestAction,
  type IGetInitialServicesRequestAction,
  type IGetServicesRequestAction,
  type IUpdateServiceAction,
} from "./action-types";
import {
  getServicesInitialError,
  getServicesInitialSuccess,
  getServicesSuccess,
  resetMeta,
  setError,
  setLoading,
  setSuccess,
} from "./actions";
import { servicesSpec as servicesSpec_ } from "./specs";
const servicesSpec = servicesSpec_ as unknown as z.infer<typeof spec_shape>;

export function* getInitialServicesSaga({
  payload,
}: IGetInitialServicesRequestAction) {
  const { params } = payload;

  try {
    const store = yield select();

    const { data }: ApiResponse<ServicesListResponse> = yield call(
      getServices,
      {
        params,
        search: store.services.searchData,
        filters: store.services.filters,
        spec: servicesSpec,
      },
    );

    yield put(getServicesInitialSuccess(data.data, data.maxCount));
  } catch (error) {
    yield put(getServicesInitialError());

    errorToast(error);
  }
}

export function* getServicesSaga({ payload }: IGetServicesRequestAction) {
  const { params, withSuccess, search, filters } = payload;

  try {
    const { data }: ApiResponse<ServicesListResponse> = yield call(
      getServices,
      {
        params,
        search,
        filters,
        spec: servicesSpec,
      },
    );

    yield put(
      getServicesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: params,
        search,
        filters,
      }),
    );

    if (withSuccess) {
      yield put(setSuccess());

      yield put(resetMeta());
    }
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* createServiceSaga({ payload }: ICreateServiceAction) {
  const { data } = payload;

  try {
    yield put(setLoading());

    yield call(createService, data);

    const store = yield select();

    const servicesResult: ApiResponse<ServicesListResponse> = yield call(
      getServices,
      {
        params: store.services.listMeta,
        search: store.services.searchData,
        filters: store.services.filters,
        spec: servicesSpec,
      },
    );

    yield put(
      getServicesSuccess({
        list: servicesResult.data.data,
        maxCount: servicesResult.data.maxCount,
        listMeta: store.services.listMeta,
        search: store.services.searchData,
        filters: store.services.filters,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* updateServiceSaga({ payload }: IUpdateServiceAction) {
  const { updateData, id, notificationData, filesData } = payload;

  try {
    yield put(setLoading());

    yield call(updateService, updateData, id);

    if (filesData) {
      const { removedFiles, addedFiles } = filesData;

      yield all(
        removedFiles.map((fileId) => call(removeFileFromService, id, fileId)),
      );
      yield all(
        addedFiles.map((file) => call(uploadFileToService, id, file, () => {})),
      );
    }

    const store = yield select();

    const servicesResult: ApiResponse<ServicesListResponse> = yield call(
      getServices,
      {
        params: store.services.listMeta,
        search: store.services.searchData,
        filters: store.services.filters,
        spec: servicesSpec,
      },
    );

    yield put(
      getServicesSuccess({
        list: servicesResult.data.data,
        maxCount: servicesResult.data.maxCount,
        listMeta: store.services.listMeta,
        search: store.services.searchData,
        filters: store.services.filters,
      }),
    );

    yield put(setSuccess());

    if (notificationData) {
      successToast(notificationData.text, notificationData.title);
    }
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* deleteServicesSaga({ payload }: IDeleteServicesAction) {
  const { ids } = payload;

  try {
    yield call(deleteServices, ids);

    const store = yield select();

    const listMeta = getTableParamsAfterDeletion(
      store.services.listMeta,
      store.services.list.length,
      ids.length,
    );

    const servicesResult: ApiResponse<ServicesListResponse> = yield call(
      getServices,
      {
        params: listMeta,
        search: store.services.searchData,
        filters: store.services.filters,
        spec: servicesSpec,
      },
    );

    yield put(
      getServicesSuccess({
        list: servicesResult.data.data,
        maxCount: servicesResult.data.maxCount,
        listMeta,
        search: store.services.searchData,
        filters: store.services.filters,
      }),
    );

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* getExampleFileSaga({ payload }: IGetExampleFileRequestAction) {
  const { table } = payload;

  try {
    const { data } = yield call(getExampleFile, table);

    downloadCsvFile(data, i18n.t("documents.example_template"));
  } catch (error) {
    yield put(setError());

    errorToast("documents.example_template_error");
  }
}

export default function* servicesSagas() {
  yield all([
    takeEvery(
      SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_REQUEST,
      getInitialServicesSaga,
    ),
    takeEvery(SERVICES_ACTION_TYPES.GET_SERVICES_REQUEST, getServicesSaga),
    takeEvery(SERVICES_ACTION_TYPES.CREATE_SERVICE, createServiceSaga),
    takeEvery(SERVICES_ACTION_TYPES.UPDATE_SERVICE, updateServiceSaga),
    takeEvery(SERVICES_ACTION_TYPES.DELETE_SERVICES, deleteServicesSaga),
    takeEvery(
      SERVICES_ACTION_TYPES.GET_EXAMPLE_FILE_REQUEST,
      getExampleFileSaga,
    ),
  ]);
}
