import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface ITopControls {}

export const TopControls: React.FC<PropsWithChildren<ITopControls>> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        height: "fit-content",
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 15,
        right: 10,
        gridGap: 10,
      }}
    >
      {children}
    </Box>
  );
};
