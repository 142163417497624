import { GridRowId } from "@mui/x-data-grid";
import { EmployeeModal } from "ProjectComponents";
import { BaseDataTable } from "ProjectComponents/tableV2/baseDataTable/BaseDataTable";
import { type AppState } from "Store";
import {
  deleteEmployees,
  getEmployees,
  getEmployeesInitial,
  resetMeta,
  resetSearch,
  setTableHiddenColumns,
} from "Store/employees";
import { searchSpecShorthand } from "Utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultHiddenColumns,
  quickSearchColumns,
  useColumns,
} from "./tableConfig";

export const Employees = () => {
  const dispatch = useDispatch();

  const [isEmployeeModalShown, setIsEmployeeModalShown] = useState(false);
  const [employeeToUpdateId, setEmployeeToUpdateId] = useState<number>();

  const userOnlineStates = useSelector(
    (state: AppState) => state.employees.onlineStates,
  );

  const {
    listMeta: employeesMeta,
    list: employeesList,
    maxCount: employeesMaxCount,
    listUpdateLoading: tableLoading,
    hiddenColumns,
    searchData,
    filters,
    loading,
  } = useSelector((state: AppState) => state.employees);

  const onlineMap = useMemo(
    () =>
      userOnlineStates.reduce((acc, state) => {
        acc[state._id] = state.online;
        return acc;
      }, {} as { [key: number]: boolean }),
    [userOnlineStates],
  );

  const employeeToUpdate = useMemo(
    () => employeesList.find((employee) => employee._id === employeeToUpdateId),
    [employeesList, employeeToUpdateId],
  );

  const employeesListWithOnlineStatus = useMemo(() => {
    return employeesList.map((employee) => ({
      ...employee,
      online: onlineMap[employee._id],
    }));
  }, [onlineMap, employeesList]);

  useEffect(() => {
    dispatch(getEmployeesInitial(employeesMeta));

    return (): void => {
      dispatch(resetMeta());
      dispatch(resetSearch());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeesMeta]);

  const handleTableRowsDelete = useCallback((ids: GridRowId[]): void => {
    dispatch(deleteEmployees({ ids: ids as number[] }));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTablePagination = useCallback(
    (currentPage: number, numberOfRows: number): void => {
      const config = {
        params: {
          ...employeesMeta,
          page: currentPage,
          pageLength: numberOfRows,
        },
        search: searchData,
        filters,
      };

      dispatch(getEmployees(config));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [employeesMeta, searchData, filters],
  );

  const handleTableSearch = useCallback(
    (query: string): void => {
      const config = {
        params: employeesMeta,
        filters,
        search: searchSpecShorthand(query, quickSearchColumns),
      };

      dispatch(getEmployees(config));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [employeesMeta, filters],
  );

  const handleRowClick = useCallback((id: GridRowId): void => {
    setIsEmployeeModalShown(true);
    setEmployeeToUpdateId(id as number);
  }, []);

  const onChangeColumnsView = useCallback(
    (values: Record<string, boolean>): void => {
      dispatch(setTableHiddenColumns(values));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const currentHiddenColumns = useMemo(
    () => ({ ...defaultHiddenColumns, ...hiddenColumns }),
    [hiddenColumns],
  );

  const handleAddClick = useCallback(() => {
    setIsEmployeeModalShown(true);
  }, []);

  const columns = useColumns();

  const closeEmployeeModal = useCallback(() => {
    setIsEmployeeModalShown(false);
    setEmployeeToUpdateId(undefined);
  }, []);

  const getEmployeeStoreState = useCallback(
    (state: AppState) => state.employees,
    [],
  );

  return (
    <>
      <BaseDataTable
        columnVisibilityModel={currentHiddenColumns}
        columns={columns}
        handlePagination={handleTablePagination}
        handleQuickSearch={handleTableSearch}
        loading={loading || tableLoading}
        maxCount={employeesMaxCount}
        onColumnVisibilityModelChange={onChangeColumnsView}
        onRowClick={handleRowClick}
        rows={employeesListWithOnlineStatus}
        toolbarProps={{
          onAddClick: handleAddClick,
          onDeleteClick: handleTableRowsDelete,
        }}
        paginationModel={{
          page: employeesMeta.page,
          pageSize: employeesMeta.pageLength,
        }}
      />
      <EmployeeModal
        isOpen={isEmployeeModalShown}
        onClose={closeEmployeeModal}
        employee={employeeToUpdate}
        getStoreState={getEmployeeStoreState}
      />
    </>
  );
};
