import { CustomerOutside } from "@ero/app-common/models/Customer";
import {
  Create,
  Delete,
  Email,
  Phone,
  PhoneAndroid,
} from "@mui/icons-material";
import Card from "@mui/material/Card/Card";
import CardActions from "@mui/material/CardActions/CardActions";
import CardContent from "@mui/material/CardContent/CardContent";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import Typography from "@mui/material/Typography/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

interface PropsType {
  person: CustomerOutside["persons"][0];
  mainContact?: boolean;
  setPersonIdToUpdate: (number) => void;
  setPersonIdToDelete: (number) => void;
}

export const PersonItem: React.FC<PropsType> = ({
  person,
  mainContact,
  setPersonIdToUpdate,
  setPersonIdToDelete,
}: PropsType) => {
  const [t] = useTranslation();

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={12} container justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">
                {person.firstName} {person.lastName}
              </Typography>
            </Grid>
            {mainContact && (
              <Grid item>
                <Typography variant="h6" color="primary">
                  {t("customers.fields.mainContact")}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={1}
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Phone />
            </Grid>
            <Grid item>
              <Typography variant="body1"> {person.phone}</Typography>
            </Grid>
            <Grid item>
              <PhoneAndroid />
            </Grid>
            <Grid item>
              <Typography variant="body1"> {person.mobile}</Typography>
            </Grid>
            <Grid item>
              <Email />
            </Grid>
            <Grid item>
              <Typography variant="body1"> {person.email}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">{person.description}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton onClick={() => setPersonIdToUpdate(person._id)}>
          <Create />
        </IconButton>
        <IconButton onClick={() => setPersonIdToDelete(person._id)}>
          <Delete />
        </IconButton>
      </CardActions>
    </Card>
  );
};
