export {
  ERR as ApiError,
  JOBSTATUS as JobStatus,
  OrderStatus as OrderStatus,
  TELEMETRY_DEVICE as TelemetryDevice,
  TELEMATICS as TelemetryType,
  MessageType as WsMessageType,
} from "@ero/app-common/enums";

export enum Topics {
  DASHBOARD = 3,
  ORDERS = 2,
  BILLING = 4,
  EMPLOYEES = 7,
  MACHINES = 1,
  FIELDS = 0,
  PROFILE = 5,
  TABLET_APP = 6,
}

export enum JobStatusColor {
  OPEN = "status.open",
  DONE = "status.done",
  DRIVING_TO_LOCATION = "status.driving",
  IN_WORK = "status.inwork",
  PAUSE = "status.pause",
}

/** Websocket connection status */
export enum ConnectionStatus {
  Disconnected,
  Authorizing,
  Connected,
}

export enum TotalsType {
  TotalTime = "totalTime",
  WorkTime = "workTime",
}

export enum CalendarView {
  MONTH = "dayGridMonth",
  WEEK = "resourceTimeGridWeek",
  DAY = "resourceTimeGridDay",
  TIMELINE = "listWeek",
}

export enum CalendarEventColor {
  SingleColor = "singlecolor",
  Status = "status",
  GrapeColor = "grapecolor",
}

export enum ParcelKindColor {
  RED = "#d13369",
  WHITE = "#B8FD94",
}

export enum SCREEN_SIZE {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum InternetConnectionStatus {
  ONLINE = "online",
  OFFLINE = "offline",
}

export enum PARCELS_FILTER {
  BY_ORDER = "filter-by-order",
  BY_CUSTOMER = "filter-by-customer",
  BY_DATE = "filter-by-date",
}
