import { PERM } from "@ero/app-common/enums";
import { LANGUAGE } from "@ero/app-common/enums/language";
import { MAX_LENGTH, VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";
import * as Yup from "yup";

export const getValidationSchema = (editMode: boolean) =>
  Yup.object().shape({
    userName: Yup.string()
      .required(VALIDATION_ERRORS.REQUIRED)
      .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
      .matches(VALIDATION_REGEX.userName, VALIDATION_ERRORS.USERNAME),
    email: Yup.string()
      .matches(VALIDATION_REGEX.email, VALIDATION_ERRORS.VALID_EMAIL)
      .when("manualPasswordSelection", (manualPasswordSelection, schema) => {
        return !manualPasswordSelection[0] && !editMode
          ? schema.required(VALIDATION_ERRORS.REQUIRED)
          : schema.optional();
      }),
    password: Yup.string()
      .matches(VALIDATION_REGEX.password, {
        message: VALIDATION_ERRORS.NO_SAFE_PASSWORD,
      })
      .when("manualPasswordSelection", (manualPasswordSelection, schema) => {
        return manualPasswordSelection[0] && !editMode
          ? schema.required(VALIDATION_ERRORS.REQUIRED)
          : schema.optional();
      }),
    permission: Yup.number().oneOf([PERM.ORGANIZER, PERM.DRIVER]),
    language: Yup.string().oneOf([LANGUAGE.DE, LANGUAGE.EN]),
    firstName: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    lastName: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    phone: Yup.string().matches(
      VALIDATION_REGEX.phone,
      VALIDATION_ERRORS.PHONE,
    ),
    qualification: Yup.string().max(
      MAX_LENGTH.DEFAULT,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
    driversLicense: Yup.number().optional(),
    notes: Yup.string().max(
      MAX_LENGTH.LONG_STRING,
      VALIDATION_ERRORS.MAX_LENGTH,
    ),
  });
