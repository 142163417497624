import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { GridColumnVisibilityModel, GridRowId } from "@mui/x-data-grid";
import { SelectionTable } from "ProjectComponents/tableV2/selectionTable/SelectionTable";
import { useColumns } from "Screens/parcels/tableConfig";
import { AppState } from "Store";
import { setTableHiddenColumns, TABLE_TYPES } from "Store/parcels";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

interface ISelectParcelTable {
  parcels: ParcelsResponseBody["data"];
  selectedParcels: number[];
  setSelectedParcels: Dispatch<SetStateAction<number[]>>;
}

export const SelectParcelTable: React.FC<ISelectParcelTable> = ({
  parcels,
  selectedParcels,
  setSelectedParcels,
}) => {
  const dispatch = useDispatch();

  const { hiddenColumns } = useSelector((state: AppState) => state.parcels);

  const unitOfMeasurement = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const columns = useColumns(unitOfMeasurement);

  const onChangeColumnsView = useCallback(
    (values: GridColumnVisibilityModel) => {
      dispatch(
        setTableHiddenColumns(TABLE_TYPES.CREATE_ORDER_PARCELS_TABLE, values),
      );
    },
    [dispatch],
  );

  const onRowSelectionModalChange = useCallback(
    (ids: GridRowId[]) => {
      setSelectedParcels(ids as number[]);
    },
    [setSelectedParcels],
  );
  return (
    <SelectionTable
      columnVisibilityModel={
        hiddenColumns[TABLE_TYPES.CREATE_ORDER_PARCELS_TABLE]
      }
      columns={columns}
      rowSelectionModel={selectedParcels}
      clientSidePagination
      clientSideFiltering
      onRowSelectionModelChange={onRowSelectionModalChange}
      loading={!parcels}
      onColumnVisibilityModelChange={onChangeColumnsView}
      rows={parcels}
      toolbarProps={{}}
    />
  );
};
