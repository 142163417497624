import { Avatar, Divider, Grid, Typography } from "@mui/material";
import { AppState } from "Store";
import { t } from "i18next";
import React from "react";
import { useSelector } from "react-redux";

interface IProps {
  item: any;
  index: number;
  itemList: any;
}

export const HistoryItem: React.FC<IProps> = ({ item, index, itemList }) => {
  const language = useSelector((state: AppState) => state.auth.selectedLang);

  const checkLastItem = (itemList: any, index: number) => {
    return index === itemList.length - 1;
  };

  const endDate = new Intl.DateTimeFormat(language, {
    dateStyle: "medium",
    timeStyle: "short",
  })
    .format(new Date(item.end))
    .replace(",", "");

  const isLastItem = checkLastItem(itemList, index);

  return (
    <Grid
      container
      maxWidth="60%"
      spacing={5}
      justifyContent="center"
      alignItems="center"
      marginBottom="10px"
    >
      <Grid item xs={1}>
        <Avatar
          src={item.employees[0].avatar ? item.employees[0].avatar : undefined}
        />
      </Grid>
      <Grid item xs={4}>
        <Grid item>
          <Typography variant="h6">{item.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">
            {item.employees[0].firstName} {item.employees[0].lastName}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Grid item>
          <Typography variant="caption">
            {t("parcels.jobHistory.completed_on")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">{endDate}</Typography>
        </Grid>
      </Grid>
      {isLastItem ? null : (
        <Divider
          sx={{
            width: "70%",
            marginLeft: "35px",
            marginTop: "10px",
          }}
        />
      )}
    </Grid>
  );
};
