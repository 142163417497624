import { IMPORT_SOURCE_RLP } from "@ero/app-common/enums/parcelImport";
import { ImportResponseBody } from "@ero/app-common/v2/routes/models/import";
import { Phone, Publish } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Loader } from "Components";
import { useAllCustomerOptions } from "Hooks/customerOptions";
import { SelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/SelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { FieldImportValuesType } from "Types";
import { Form, Formik } from "formik";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ImportSourceSelection } from "./components/importSourceSelection";
import { StateInputs } from "./components/stateInputs";
import { useStateOptions } from "./hooks/useStateOptions";
import { useUploadProgress } from "./hooks/useUploadProgress";

interface ParcelImportFormPropsType {
  onSuccess: (responseData?: ImportResponseBody) => void;
  hideCustomerAutocomplete?: boolean;
  externalAccessToken?: string;
  trimfilenames?: boolean;
}

const initialValues: FieldImportValuesType = {};

export const ParcelImportForm: React.FC<ParcelImportFormPropsType> = ({
  onSuccess,
  hideCustomerAutocomplete,
  externalAccessToken,
  trimfilenames,
}) => {
  const [t] = useTranslation();
  const { isUploading, uploadFiles } = useUploadProgress((responseData) => {
    onSuccess(responseData);
  }, externalAccessToken);

  const { fetchCustomerOptions } = useAllCustomerOptions();

  const states = useStateOptions();

  const forwardedRef = useRef(null);

  const validationSchema = useMemo(() => {
    const baseValidationSchemaShape = {
      state: Yup.string().required(t("validation_errors.fields_empty")),
      type: Yup.string().required(t("validation_errors.fields_empty")),
      flurstuecksDaten: Yup.mixed().when("type", {
        is: IMPORT_SOURCE_RLP.FLORLP,
        then: (schema) => schema.required(t("validation_errors.fields_empty")),
      }),
      schlagDaten: Yup.mixed().required(t("validation_errors.fields_empty")),
      wipDaten: Yup.mixed(),
    };
    let validationSchemaShape;
    if (!hideCustomerAutocomplete) {
      validationSchemaShape = {
        ...baseValidationSchemaShape,
        customer: Yup.number().required(t("validation_errors.fields_empty")),
      };
    } else {
      validationSchemaShape = baseValidationSchemaShape;
    }
    return Yup.object().shape(validationSchemaShape);
  }, [hideCustomerAutocomplete, t]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={uploadFiles}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ values, isValid, dirty }) => (
        <Form encType="multipart/form-data">
          <DialogContent dividers>
            {!hideCustomerAutocomplete && (
              <Tooltip title={t("parcels.upload.selectcustomer")}>
                <SelectWithAutoComplete
                  name="customer"
                  required={true}
                  fetchOptions={fetchCustomerOptions}
                  label={t("general.fields.customer")}
                  sx={{ mt: 1, mb: 1 }}
                />
              </Tooltip>
            )}
            <Tooltip
              title={
                hideCustomerAutocomplete
                  ? t("parcels.upload.selectregion.external")
                  : t("parcels.upload.selectregion.internal")
              }
            >
              <StaticSelectWithAutoComplete
                label={t("parcels.upload.state")}
                required={true}
                name="state"
                options={states}
                sx={{ mt: 1, mb: 1 }}
              />
            </Tooltip>
            <Tooltip title={t("parcels.upload.importTypeHint")}>
              <ImportSourceSelection ref={forwardedRef} />
            </Tooltip>
            <Typography variant="h5">
              {t("parcels.upload.fileupload", {
                count: values.type === IMPORT_SOURCE_RLP.FLORLP ? 2 : 1,
              })}
            </Typography>
            <StateInputs
              state={values.state}
              trimFilenames={trimfilenames}
              importSource={values.type}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption">
                {t("parcels.contactText")}
              </Typography>
              <Phone />
              <Typography variant="caption">
                {process.env.REACT_APP_ERO_SERVICE_PHONE}
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant={"contained"}
              type="submit"
              disabled={!isValid || !dirty || isUploading}
              startIcon={<Publish />}
            >
              {isUploading && <Loader size={30} />}
              {t("parcels.upload.overview.next")}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
