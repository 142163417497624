import { BILLINGUNIT } from "@ero/app-common/enums";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { UNIT } from "@ero/app-common/util/Units";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useBillingUnitOptions = (unit: UNIT) => {
  const [t] = useTranslation();
  const options = useMemo(
    () => [
      {
        label: t("billing_unit.none"),
        value: BILLINGUNIT.NONE,
      },
      {
        label: t("billing_unit.meter_harvested"),
        value: BILLINGUNIT.METERHARVESTED,
      },
      {
        label: t("billing_unit.meter_shaked"),
        value: BILLINGUNIT.METERSHAKED,
      },
      {
        label: t("billing_unit.time_harvested"),
        value: BILLINGUNIT.TIMEHARVESTED,
      },
      {
        label: t("billing_unit.time_shaked"),
        value: BILLINGUNIT.TIMESHAKED,
      },
      {
        label: t("billing_unit.area", {
          unit: UnitSymbol[unit],
        }),
        value: BILLINGUNIT.AREA,
      },
      {
        label: t("billing_unit.fixed_rate"),
        value: BILLINGUNIT.FIXEDRATE,
      },
    ],
    [t, unit],
  );
  return options;
};
