import { type spec_shape } from "@ero/app-common/routes/validators";
import {
  api_parcels_create,
  deleteParcels,
  getParcel,
  getParcels,
  sendImportLink,
  updateParcel,
} from "Api";
import { errorToast, successToast } from "Services";
import { getTableParamsAfterDeletion } from "Utils";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import z from "zod";
import {
  ISendImportLink,
  PARCELS_ACTION_TYPES,
  type ICreateParcelAction,
  type IDeleteParcelsAction,
  type IGetInitialParcelsRequestAction,
  type IGetParcelAction,
  type IGetParcelsRequestAction,
  type IUpdateParcelAction,
} from "./action-types";
import {
  getParcelSuccess,
  getParcelsInitialError,
  getParcelsInitialSuccess,
  getParcelsSuccess,
  resetMeta,
  setError,
  setLoading,
  setSuccess,
} from "./actions";
import { parcelViewSpecs, parcelsSpec as parcelsSpec_ } from "./specs";
const parcelsSpec = parcelsSpec_ as unknown as z.infer<typeof spec_shape>;

export function* getInitialParcelsSaga({
  payload,
}: IGetInitialParcelsRequestAction) {
  const { params } = payload;

  try {
    const store = yield select();

    const { data } = yield call(getParcels, {
      params,
      search: store.parcels.searchData,
      filters: store.parcels.filters,
      spec: parcelsSpec,
    });

    yield put(
      getParcelsInitialSuccess(data.data, data.maxCount, data.lastParcelId),
    );
  } catch (error) {
    yield put(getParcelsInitialError());

    errorToast(error);
  }
}

export function* getParcelsSaga({ payload }: IGetParcelsRequestAction) {
  const { params, search, filters } = payload;

  try {
    const { data } = yield call(getParcels, {
      params,
      search,
      filters,
      spec: parcelsSpec,
    });

    yield put(
      getParcelsSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: params,
        search,
        filters,
        lastId: data.lastParcelId,
      }),
    );
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* getParcelSaga({ payload }: IGetParcelAction) {
  const { id } = payload;

  try {
    const { data } = yield call(getParcel, {
      id,
      spec: parcelViewSpecs,
    });

    yield put(
      getParcelSuccess({
        parcel: data,
      }),
    );
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* createParcelSaga({ payload }: ICreateParcelAction) {
  const { data } = payload;

  try {
    yield put(setLoading());

    const store = yield select();

    if (data.customer === -1) {
      data.customer = undefined;
    }
    yield call(api_parcels_create, data, {});

    const parcelsResult = yield call(getParcels, {
      params: store.parcels.listMeta,
      search: store.parcels.searchData,
      filters: store.parcels.filters,
      spec: parcelsSpec,
    });

    yield put(
      getParcelsSuccess({
        list: parcelsResult.data.data,
        maxCount: parcelsResult.data.maxCount,
        listMeta: store.parcels.listMeta,
        search: store.parcels.searchData,
        filters: store.parcels.filters,
        lastId: parcelsResult.data.lastParcelId,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* updateParcelSaga({ payload }: IUpdateParcelAction) {
  const { updateData } = payload;

  try {
    yield put(setLoading());

    const store = yield select();

    yield call(updateParcel, updateData);

    const parcelsResult = yield call(getParcels, {
      params: store.parcels.listMeta,
      search: store.parcels.searchData,
      filters: store.parcels.filters,
      spec: parcelsSpec,
    });

    yield put(
      getParcelsSuccess({
        list: parcelsResult.data.data,
        maxCount: parcelsResult.data.maxCount,
        listMeta: store.parcels.listMeta,
        search: store.parcels.searchData,
        filters: store.parcels.filters,
        lastId: parcelsResult.data.lastParcelId,
      }),
    );

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* deleteParcelsSaga({ payload }: IDeleteParcelsAction) {
  const { ids } = payload;

  try {
    yield call(deleteParcels, ids);

    const store = yield select();

    const listMeta = getTableParamsAfterDeletion(
      store.parcels.listMeta,
      store.parcels.list.length,
      ids.length,
    );

    const parcelsResult = yield call(getParcels, {
      params: listMeta,
      search: store.parcels.searchData,
      filters: store.parcels.filters,
      spec: parcelsSpec,
    });

    yield put(
      getParcelsSuccess({
        list: parcelsResult.data.data,
        maxCount: parcelsResult.data.maxCount,
        listMeta,
        search: store.parcels.searchData,
        filters: store.parcels.filters,
        lastId: parcelsResult.data.lastParcelId,
      }),
    );

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* sendImportParcelLinkSaga({ payload }: ISendImportLink) {
  const { data } = payload;

  try {
    yield put(setLoading());

    yield call(sendImportLink, data);

    yield put(setSuccess());

    successToast("success_notifications.link_sent");
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export default function* parcelsSagas() {
  yield all([
    takeEvery(
      PARCELS_ACTION_TYPES.GET_INITIAL_PARCELS_REQUEST,
      getInitialParcelsSaga,
    ),
    takeLatest(PARCELS_ACTION_TYPES.GET_PARCELS_REQUEST, getParcelsSaga),
    takeEvery(PARCELS_ACTION_TYPES.CREATE_PARCEL, createParcelSaga),
    takeEvery(PARCELS_ACTION_TYPES.UPDATE_PARCEL, updateParcelSaga),
    takeEvery(PARCELS_ACTION_TYPES.GET_PARCEL_REQUEST, getParcelSaga),
    takeEvery(PARCELS_ACTION_TYPES.DELETE_PARCELS, deleteParcelsSaga),
    takeEvery(PARCELS_ACTION_TYPES.SEND_IMPORT_LINK, sendImportParcelLinkSaga),
  ]);
}
