import { OrdersResponseBody } from "@ero/app-common/v2/routes/models/order";
import { type ColumnFilterDataType, type InitialScreenMetaType } from "Types";
import { ORDERS_ACTION_TYPES, type OrdersAction } from "./action-types";

export type OrdersStateType = {
  list: OrdersResponseBody["data"];
  maxCount: number;
  lastOrderId: number;
  offset: number;
  limit: number;
  search?: string;
  hiddenColumns: Record<string, boolean>;
  columnsOrder?: number[];
  filters?: ColumnFilterDataType[];
  initialMeta: InitialScreenMetaType;
  isOrderNameUnique: boolean;
  listUpdateLoading: boolean;
  success: boolean;
  loading: boolean;
  error: boolean;
  createdOrderId: number | undefined;
};

const initialState: OrdersStateType = {
  list: [] as OrdersResponseBody["data"],
  maxCount: 0,
  lastOrderId: 0,
  hiddenColumns: {},
  offset: 0,
  limit: 25,
  search: undefined,
  columnsOrder: undefined,
  filters: undefined,
  initialMeta: {
    loading: true,
    error: false,
  },
  listUpdateLoading: false,
  isOrderNameUnique: false,
  success: false,
  loading: false,
  error: false,
  createdOrderId: undefined,
};

export const ordersReducer = (
  state = initialState,
  action: OrdersAction,
): OrdersStateType => {
  switch (action.type) {
    case ORDERS_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case ORDERS_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
      };
    case ORDERS_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        offset: 0,
        isOrderNameUnique: false,
      };
    case ORDERS_ACTION_TYPES.GET_ORDERS_SUCCESS:
      return {
        ...state,
        list: action.payload.data,
        lastOrderId: action.payload.lastOrderId || 0,
        maxCount: action.payload.maxCount || 0,
        createdOrderId: action.payload.createdId,
        loading: false,
        success: true,
        error: false,
      };
    case ORDERS_ACTION_TYPES.SET_SEARCH:
      return {
        ...state,
        search: action.payload.search,
      };
    case ORDERS_ACTION_TYPES.RESET_SEARCH:
      return {
        ...state,
        search: undefined,
      };
    case ORDERS_ACTION_TYPES.SET_PAGINATION_MODEL:
      return {
        ...state,
        offset: action.payload.currentPage * action.payload.numberOfRows,
        limit: action.payload.numberOfRows,
      };
    case ORDERS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS: {
      return {
        ...state,
        hiddenColumns: action.payload.hiddenColumns,
      };
    }
    default:
      return state;
  }
};
