import {
  type AddOrderRequestBody,
  type DeleteOrdersRequestQuery,
  type OrdersRequestQuery,
  type OrdersResponseBody,
} from "@ero/app-common/v2/routes/models/order";
import { type Action } from "redux";

export enum ORDERS_ACTION_TYPES {
  CHANGE_COLUMNS_ORDER = "orders/change-columns-order",
  CHECK_ORDER_NAME_UNIQUENESS = "orders/check-order-name-uniqueness",
  CREATE_ORDERS_REQUEST = "orders/create-order-request",
  DELETE_ORDERS_REQUEST = "orders/delete-orders-request",
  GET_INITIAL_ORDERS_ERROR = "orders/get-initial-orders-error",
  GET_INITIAL_ORDERS_REQUEST = "orders/get-initial-orders-request",
  GET_INITIAL_ORDERS_SUCCESS = "orders/get-initial-orders-success",
  GET_ORDERS_REQUEST = "orders/get-orders-request",
  GET_ORDERS_SUCCESS = "orders/get-orders-success",
  RESET_META = "orders/reset-meta",
  SET_SEARCH = "orders/set-search",
  RESET_SEARCH = "orders/reset-search",
  SET_ERROR = "orders/set-error",
  SET_LOADING = "orders/set-loading",
  SET_ORDER_NAME_UNIQUENESS = "orders/set-order-name-uniqueness",
  SET_SUCCESS = "orders/set-success",
  SET_TABLE_HIDDEN_COLUMNS = "orders/set-table-hidden-columns",
  SET_PAGINATION_MODEL = "orders/set-pagination-model",
}

export interface ISetLoadingAction extends Action {
  type: ORDERS_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: ORDERS_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: ORDERS_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: ORDERS_ACTION_TYPES.RESET_META;
}

export interface ISetSearchAction extends Action {
  type: ORDERS_ACTION_TYPES.SET_SEARCH;
  payload: { search: string };
}

export interface IResetSearchAction extends Action {
  type: ORDERS_ACTION_TYPES.RESET_SEARCH;
}

export interface IGetOrdersRequestAction extends Action {
  type: ORDERS_ACTION_TYPES.GET_ORDERS_REQUEST;
  payload: OrdersRequestQuery;
}

export interface IGetOrdersSuccessAction extends Action {
  type: ORDERS_ACTION_TYPES.GET_ORDERS_SUCCESS;
  payload: {
    data: OrdersResponseBody["data"];
    maxCount?: number;
    lastOrderId?: number;
    search?: string;
    createdId?: number;
  };
}

export interface ISetOrdersPaginationModelAction extends Action {
  type: ORDERS_ACTION_TYPES.SET_PAGINATION_MODEL;
  payload: {
    currentPage: number;
    numberOfRows: number;
  };
}

export interface ICreateOrdersRequestAction extends Action {
  type: ORDERS_ACTION_TYPES.CREATE_ORDERS_REQUEST;
  payload: {
    order: AddOrderRequestBody;
  };
}

export interface IDeleteOrdersRequestAction extends Action {
  type: ORDERS_ACTION_TYPES.DELETE_ORDERS_REQUEST;
  payload: DeleteOrdersRequestQuery;
}

export interface ISetTableHiddenColumns extends Action {
  type: ORDERS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: { hiddenColumns: Record<string, boolean>; subTable?: string };
}

export interface IChangeColumnsOrder extends Action {
  type: ORDERS_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export type OrdersAction =
  | IGetOrdersRequestAction
  | IGetOrdersSuccessAction
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | ISetSearchAction
  | IResetSearchAction
  | ICreateOrdersRequestAction
  | IDeleteOrdersRequestAction
  | ISetTableHiddenColumns
  | ISetOrdersPaginationModelAction
  | IChangeColumnsOrder;
