import { AccessTime, ErrorOutlineOutlined } from "@mui/icons-material";
import { InputAdornment, TextFieldProps } from "@mui/material";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { useField } from "formik";
import moment, { Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { type IInput } from "./input";

const getTimeFromString = (time: string) => {
  return new Date(`1970-01-01T${time}:00.000Z`).getTime();
};

export interface IProps extends IInput {
  timeOnly?: boolean;
}

export const TimeInput: React.FC<IProps> = ({
  label,
  mandatory,
  disabled,
  timeOnly = false,
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest.name);
  const [momentValue, setMomentValue] = useState<Moment | null>(null);

  const inputProps: TextFieldProps = useMemo(
    () => ({
      required: mandatory,
      endAdornment: (
        <InputAdornment position="end">
          <AccessTime />
        </InputAdornment>
      ),
    }),
    [mandatory],
  );

  const isError = meta.error && meta.touched;

  useEffect(() => {
    if (field.value) {
      if (timeOnly) {
        setMomentValue(moment(field.value).utc());
      } else {
        setMomentValue(moment(field.value));
      }
    } else {
      if (timeOnly) {
        setMomentValue(moment(0).utc());
        helpers.setValue(+moment(0).utc().format("x"));
      } else {
        setMomentValue(moment());
        helpers.setValue(+moment().format("x"));
      }
    }
  }, [field.value, helpers, timeOnly]);

  const changeTimeHandler = (date: Moment | null) => {
    if (timeOnly) {
      date != null && setMomentValue(date.utc());
    } else {
      setMomentValue(date);
    }

    if (!date) {
      helpers.setValue(null);
    }

    if (date?.isValid()) {
      let timestamp = +moment(date).format("x");
      if (timeOnly) {
        timestamp = getTimeFromString(moment(date).format("HH:mm"));
      }
      helpers.setValue(timestamp);
    }
  };

  return (
    <>
      <DesktopTimePicker
        {...field}
        disabled={disabled}
        label={label}
        onChange={changeTimeHandler}
        value={momentValue}
        ampm={false}
        slotProps={{
          textField: inputProps,
        }}
      />
      {isError && (
        <>
          <ErrorOutlineOutlined />
          {meta.error}
        </>
      )}
    </>
  );
};
