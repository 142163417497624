import { AccessTime, Speed } from "@mui/icons-material";
import { TelemetryType } from "Enums";
import React from "react";

export interface TelemetryEntry {
  name: string;
  tooltip: string;
  key: string;
  telemetryType?: TelemetryType;
  suffix: string;
  inputType: "time" | "number" | "percent";
  noEdit?: boolean;
  emptyValue?: string;
  icon?: React.ReactElement;
}

export const telemetryEntries: TelemetryEntry[] = [
  {
    key: "totalTime",
    name: "telemetry.total_time",
    tooltip: "telemetry.total_time_tooltip",
    suffix: "telemetry.units.time",
    inputType: "time",
    noEdit: true,
    icon: <AccessTime />,
  },
  {
    key: "workTime",
    name: "telemetry.work_time",
    tooltip: "telemetry.work_time_tooltip",
    suffix: "telemetry.units.time",
    inputType: "time",
    noEdit: true,
    icon: <AccessTime />,
  },
  {
    key: "meterShaked",
    name: "telemetry.meter_shaked",
    tooltip: "telemetry.meter_shaked_tooltip",
    telemetryType: TelemetryType.meter_shaked,
    suffix: "telemetry.units.meters",
    inputType: "number",
    icon: <Speed />,
  },
  {
    key: "meterHarvested",
    name: "telemetry.meter_harvested",
    tooltip: "telemetry.meter_harvested_tooltip",
    telemetryType: TelemetryType.meter_harvested,
    suffix: "telemetry.units.meters",
    inputType: "number",
    icon: <Speed />,
  },
  {
    key: "timeShaked",
    name: "telemetry.time_shaked",
    tooltip: "telemetry.time_shaked_tooltip",
    telemetryType: TelemetryType.time_shaked,
    suffix: "telemetry.units.time",
    inputType: "time",
    icon: <AccessTime />,
  },
  {
    key: "timeHarvested",
    name: "telemetry.time_harvested",
    tooltip: "telemetry.time_harvested_tooltip",
    telemetryType: TelemetryType.time_harvested,
    suffix: "telemetry.units.time",
    inputType: "time",
    icon: <AccessTime />,
  },
];
