import { Autocomplete, SxProps, TextField, Theme } from "@mui/material";
import { OptionType } from "Types/internal";
import { useField } from "formik";
import React, { useCallback, useMemo } from "react";

type Props = {
  name: string;
  label: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  options: OptionType[];
  disabledValues?: (number | string)[];
  disableClearable?: boolean;
  sx?: SxProps<Theme>;
  placeholder?: string;
  onChange?: (values: OptionType[]) => void;
  valueSource?: "value" | "label";
  size?: "small" | "medium";
};

export const StaticMultiSelectWithAutoCompleteComponent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = (props, ref) => {
  const {
    name,
    label,
    required = false,
    disableClearable = false,
    loading = false,
    disabled = false,
    disabledValues,
    sx,
    options,
    placeholder,
    onChange: propsOnChange,
    valueSource = "value",
    size = "medium",
    ...rest
  } = props;
  const [field, _, helpers] = useField<(string | number)[] | null | undefined>(
    name,
  );

  const autocompleteValue: OptionType[] = useMemo(() => {
    const selectedOptions = options.filter((option) =>
      field.value?.includes(option[valueSource]),
    );
    return selectedOptions ?? [];
  }, [field.value, options, valueSource]);

  const optionsToDisable = (option: OptionType) => {
    return !!disabledValues?.includes(option.value);
  };

  const getOptionLabel = useCallback((option: OptionType) => option.label, []);

  const onChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, values: OptionType[]) => {
      helpers.setValue(values.map((entry) => entry[valueSource]));
      propsOnChange && propsOnChange(values);
    },
    [helpers, propsOnChange, valueSource],
  );

  return (
    <Autocomplete
      {...rest}
      {...field}
      ref={ref}
      sx={sx}
      disabled={disabled}
      disableClearable={disableClearable}
      value={autocompleteValue}
      loading={loading}
      multiple={true}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          placeholder={placeholder || params.inputProps.placeholder}
          inputProps={{ ...params.inputProps, placeholder }}
        />
      )}
      options={options}
      getOptionDisabled={optionsToDisable}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      size={size}
    />
  );
};

export const StaticMultiSelectWithAutoComplete = React.forwardRef(
  StaticMultiSelectWithAutoCompleteComponent,
);
StaticMultiSelectWithAutoComplete.displayName =
  "StaticMultiSelectWithAutoComplete";
