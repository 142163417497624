import { configureStore } from "@reduxjs/toolkit";
import { PERSIST, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { reducer } from "./root-reducer";
import rootSaga from "./root-saga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }).concat(sagaMiddleware);
    return middleware;
  },
});

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof store.getState>;

export { persistor, store };
