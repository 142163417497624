import { IconButton, useTheme } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "Store";

import { DarkMode, LightMode } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { changeColorMode } from "Store/auth";
import i18n from "i18next";

export const DarkModeSwitch: React.FC = () => {
  const dispatch = useDispatch();
  const colorMode = useSelector((state: AppState) => state.auth.colorMode);

  const toggleState = () => {
    if (colorMode == "light") dispatch(changeColorMode("dark"));
    else dispatch(changeColorMode("light"));
  };
  const theme = useTheme();

  return (
    <>
      <Tooltip title={i18n.t("auth.login.Color_scheme") as string}>
        <IconButton
          onClick={toggleState}
          size="large"
          sx={{
            width: "52px",
            height: "52px",
            boxShadow: "0px 10px 20px #00000029",
            background: theme.palette.background.default,
            "&:hover": {
              background: theme.palette.background.paper,
            },
          }}
        >
          {colorMode === "light" && <DarkMode />}
          {colorMode === "dark" && <LightMode />}
        </IconButton>
      </Tooltip>
    </>
  );
};
