import "./styles.css";

import { type JobOutside, type ParcelOutside } from "@ero/app-common/models";
import { UNIT } from "@ero/app-common/util/Units";
import { type UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import MapMarkerOnlineSvg from "Assets/icons/map-marker-anim.svg";
import { Loader } from "Components/loader";
import { type AppState } from "Store";
import {
  Coordinate,
  type EntityType,
  type FieldType,
  type GPSCoords,
  type LanguageType,
  type MachineTrackType,
  type MachineType,
  type PolygonValuesType,
  type SocketMachine,
} from "Types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";
import ReactDOM from "react-dom/client";
import { useSelector } from "react-redux";
import {
  CenteredControl,
  DriversTrackControl,
  FullScreenControl,
  MachineTrackButton,
  RoundedHelpButton,
  Search,
  ZoomControl,
} from "./components/controls";
import {
  DriversLiveTrack,
  MachineMarker,
  MachineTrack,
  ParcelFigure,
} from "./components/figures";
import { usePolygonArea } from "./components/hooks";
import { DirectionsManager, DrawManager } from "./components/managers";
import { PolygonFilter } from "./components/managers/polygon-filter";
import { MachineCompass } from "./components/misc";
import {
  defaultCenterCoordinates,
  getMachineIconOptions,
  mapStyles,
} from "./mapConfig";
import { MachineSelector } from "./types";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY!;
const mapLibraries: Libraries = ["places", "drawing", "geometry"];
const mapOptions = {
  mapTypeId: "hybrid",
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  styles: mapStyles,
  fullscreenControl: false,
};

type Machine = MachineType | SocketMachine | EntityType<Partial<MachineType>>;

interface IMap {
  machines?: Machine[];
  // NB: store has type `any`, to prevent clash of Organizer vs DriverApp
  machineSelector?: MachineSelector;
  machineIds?: number[];
  fields?: EntityType<Partial<FieldType>>[];
  field?: EntityType<Partial<FieldType>>;
  parcels?: Partial<ParcelOutside>[];
  isNavigationPrevented?: boolean;
  drawMode?: boolean;
  drawMarkerMode?: boolean;
  drawMarkerModeOnly?: boolean;
  editMode?: boolean;
  machineTrackData?: MachineTrackType;
  machineTrackSelection?: {
    start: number;
    end: number;
  };
  trackOnSelect?: (job: number) => void;
  trackOnCancel?: () => void;
  machineTrackOnSelect?: (machine: number, start: number, end: number) => void;
  machineTrackOnCancel?: () => void;
  onPolygonComplete?: (value: PolygonValuesType) => void;
  fieldEditDataOnClick?: (field: EntityType<Partial<FieldType>>) => void;
  fieldEditMapOnClick?: (field: EntityType<Partial<FieldType>>) => void;
  parcelEditDataOnClick?: (parcel: Partial<ParcelOutside>) => void;
  parcelJobsCallback?: (parcel: Partial<ParcelOutside>) => void;
  zoom?: number;
  withSearch?: boolean;
  defaultCenter?: Coordinate | null;
  /** Center map on this coordinate when it changes */
  centerAt?: Coordinate;
  defaultAddress?: string;
  openMachineId?: number;
  openParcelId?: number;
  drawDirectionToField?: FieldType;
  drawDirectionToParcel?: ParcelOutside;
  fullScreenControl?: boolean;
  zoomControl?: boolean;
  centerControl?: boolean;
  driversTrackControl?: boolean;
  currentMachineID?: number;
  positionProvider: () => { position?: Coordinate; error: boolean };
  showCompass?: boolean;
  showCurrentPosition?: boolean;
  noInfoWindow?: boolean;
  showTrackButton?: boolean;
  showMachineTrackButton?: boolean;
  trackDisabled?: boolean;
  searchPosition?: {
    top?: number | string;
    left?: number | string;
    bottom?: number | string;
    right?: number | string;
  };
  noBorderRadius?: boolean;
  onFilterParcels?: (parcels: ParcelOutside[]) => void;
  highlightedParcelIds?: number[] | null;
  onParcelSelect?: (parcel: Partial<ParcelOutside>) => void;
  parcelSelectedColor?: string;
  lang?: LanguageType;
  disableScrollWheel?: boolean;
  onNavigateToParcel?: (parcel: Partial<ParcelOutside>) => void;
  showHelpBtn?: boolean;
  onHelpBtnClick?: () => void;
  resetMachinePosition?: (id: number) => void;
  defaultMarker?: Coordinate;
  drawParcel?: boolean;
  setFieldValue?: any;
  itemOnClick?: (id: number) => void;
  isClosed?: boolean;
  gpsCoords?: GPSCoords[] | number | undefined;
  setlivetrackingEnabledCallback?: Dispatch<SetStateAction<boolean>>;
  mapOnClick?: () => void;
  handleEditEntry?: (
    type: "machine" | "parcel" | "job",
    entry: Machine | Partial<ParcelOutside> | JobOutside,
    orderId: number | undefined,
  ) => void;
  drivers?: UserResponseBody[];
  showDriversTrack?: boolean;
  driversTrackBtnOnClick?: (show: boolean) => void;
  unitOfMeasurement: UNIT;
}
const machineTrackButtonDiv = ReactDOM.createRoot(
  document.createElement("div") as HTMLElement,
);
const helpButton = ReactDOM.createRoot(
  document.createElement("div") as HTMLElement,
);
const zoomButtonDiv = ReactDOM.createRoot(
  document.createElement("div") as HTMLElement,
);
const centeredButtonDiv = ReactDOM.createRoot(
  document.createElement("div") as HTMLElement,
);
const searchInputDiv = ReactDOM.createRoot(
  document.createElement("div") as HTMLElement,
);
const fullscreenButtonDiv = ReactDOM.createRoot(
  document.createElement("div") as HTMLElement,
);
const driverTrackButtonDiv = ReactDOM.createRoot(
  document.createElement("div") as HTMLElement,
);

/*
Map
... is instantiated by
parcelsmap/Mapwrapper,
machinesmap/Mapwrapper
OrganisationMapForm
DriverAPP/Screens/map.tsx
*/

export const Map: React.FC<IMap> = ({
  isNavigationPrevented,
  machines = [],
  machineIds,
  machineSelector,
  parcels = [],
  machineTrackData = [],
  machineTrackSelection,
  editMode,
  drawMode,
  drawMarkerMode,
  drawMarkerModeOnly,
  onPolygonComplete,
  parcelJobsCallback,
  machineTrackOnSelect,
  machineTrackOnCancel,
  zoom = 13,
  withSearch,
  defaultCenter, // Set initial position to plot and center to
  centerAt,
  defaultAddress,
  openMachineId,
  openParcelId,
  noInfoWindow,
  drawDirectionToParcel,
  fullScreenControl = true,
  zoomControl = true,
  centerControl,
  driversTrackControl = false,
  currentMachineID,
  positionProvider,
  showCompass = false,
  showCurrentPosition = false,
  noBorderRadius = false,
  showMachineTrackButton = false,
  trackDisabled = false,
  searchPosition,
  onFilterParcels,
  highlightedParcelIds,
  onParcelSelect,
  parcelSelectedColor = "#ff5900",
  lang,
  disableScrollWheel = false,
  onNavigateToParcel,
  showHelpBtn,
  onHelpBtnClick,
  resetMachinePosition,
  defaultMarker,
  drawParcel = false,
  setFieldValue,
  itemOnClick,
  isClosed,
  gpsCoords,
  mapOnClick,
  handleEditEntry,
  drivers,
  showDriversTrack,
  driversTrackBtnOnClick,
  unitOfMeasurement,
}) => {
  const [closeAllInfoWindows, toggleCloseAllInfoWindows] = useState(false);
  const [isTrackSelected, setTrackSelected] = useState(false);
  const { colorMode } = useSelector((state: AppState) => state.auth);
  const [marker, setMarker] = useState<any>(null);
  const [polygon, setPolygon] = useState<any>(null);
  const { AreaOverlay, unsetPolygonArea, submitPolygonVertices } =
    usePolygonArea();
  const mapRef = useRef<any>();

  const containerStyle: React.CSSProperties = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      flex: 1,
      // position: 'position',
      borderRadius: noBorderRadius ? 0 : 20,
    }),
    [noBorderRadius],
  );

  const mapOptionsWithProps = { ...mapOptions, gestureHandling: "cooperative" };
  if (!disableScrollWheel) {
    mapOptionsWithProps.gestureHandling = isNavigationPrevented
      ? "none"
      : "auto";
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: mapLibraries,
  });

  const { position, error } = positionProvider();

  const [defaultMapCenter, setDefaultMapCenter] = useState<Coordinate>(
    defaultCenter || position || defaultCenterCoordinates,
  );

  const [zoomLevel, setZoomLevel] = useState(zoom);
  const [bounds, setBounds] = useState();

  const onZoomChanged = () => {
    setZoomLevel(mapRef.current?.getZoom());
  };

  const onBoundsChanged = () => {
    setBounds(mapRef.current?.getBounds());
  };

  const { markerSize, markerLabel } = useMemo(() => {
    const initialZoomLevel = 13;
    const maxZoomLevel = 16;
    const minZoomLevel = 7;

    const minPixelAmount = 1;

    let currentZoomLevelCalculated: number;

    if (zoomLevel < minZoomLevel) currentZoomLevelCalculated = minZoomLevel;
    if (zoomLevel > maxZoomLevel) currentZoomLevelCalculated = maxZoomLevel;
    else currentZoomLevelCalculated = zoomLevel;

    const markerSize =
      Math.ceil(
        24 *
          (((currentZoomLevelCalculated || initialZoomLevel) - minZoomLevel) /
            (maxZoomLevel - minZoomLevel)),
      ) || minPixelAmount;

    const markerLabel = {
      showText: zoomLevel >= 17,
      color: "#fff",
      fontSize: "24px",
      fontWeight: "500",
    };

    return { markerSize, markerLabel };
  }, [zoomLevel]);

  useEffect((): void => {
    if (!isClosed) {
      return;
    }

    if (marker) {
      marker.setMap(null);
    }
    setMarker(null);
    if (setFieldValue) {
      setFieldValue("position", null);
      setFieldValue("shape", []);
      setFieldValue("sizeAutomatic", 0);
    }

    if (polygon) {
      polygon.setMap(null);
    }
    setPolygon(null);
    unsetPolygonArea();

    if (onPolygonComplete !== undefined) {
      onPolygonComplete({ path: undefined, area: undefined });
    }
  }, [
    isClosed,
    marker,
    onPolygonComplete,
    polygon,
    setFieldValue,
    unsetPolygonArea,
  ]);

  // Initial center
  useEffect(() => {
    if (!defaultCenter && !defaultAddress && position) {
      if (mapRef.current) {
        mapRef.current.setCenter(position);
      }
    }
  }, [defaultCenter, defaultAddress, position]);

  useEffect(() => {
    if (!showMachineTrackButton) {
      return;
    }
    machineTrackButtonDiv.render(
      <MachineTrackButton
        trackOnSelect={machineTrackOnSelect}
        trackOnCancel={machineTrackOnCancel}
        isTrackSelected={isTrackSelected}
        setTrackSelected={setTrackSelected}
        disabled={trackDisabled}
        selectedMachine={openMachineId}
        lang={lang}
        trackSelection={machineTrackSelection}
      />,
    );
  }, [
    trackDisabled,
    showMachineTrackButton,
    openMachineId,
    isTrackSelected,
    machineTrackSelection,
    colorMode,
    lang,
    machineTrackOnCancel,
    machineTrackOnSelect,
  ]);

  //create useEffect for controls that already created in mapOnLoad function using this example

  useEffect(() => {
    if (centerControl) {
      const centerHandler = (center) =>
        onManualCenter(center ? center : defaultMapCenter);
      centeredButtonDiv.render(<CenteredControl onCenter={centerHandler} />);
    }

    if (zoomControl) {
      zoomButtonDiv.render(<ZoomControl map={mapRef.current} />);
    }

    if (fullScreenControl) {
      fullscreenButtonDiv.render(<FullScreenControl map={mapRef.current} />);
    }

    if (driversTrackControl && showDriversTrack !== undefined) {
      driverTrackButtonDiv.render(
        <DriversTrackControl
          showDriversTrack={showDriversTrack}
          driversTrackBtnOnClick={driversTrackBtnOnClick}
        />,
      );
    }
  }, [
    colorMode,
    centerControl,
    defaultMapCenter,
    driversTrackBtnOnClick,
    driversTrackControl,
    fullScreenControl,
    showDriversTrack,
    zoomControl,
  ]);

  useEffect(() => {
    if (isLoaded && centerAt && mapRef.current) {
      mapRef.current.setCenter(centerAt);
    }
  }, [centerAt, isLoaded]);

  const onManualCenter = (center: Coordinate | undefined): void => {
    if (center !== undefined) {
      setDefaultMapCenter(center);
    }
    if (mapRef.current) {
      mapRef.current.setCenter(center);
    }
  };

  const internalMapOnClick = useCallback(() => {
    if (mapOnClick) mapOnClick();
    toggleCloseAllInfoWindows(!closeAllInfoWindows);
  }, [closeAllInfoWindows, mapOnClick]);

  useEffect(() => {
    if (position === undefined) {
      return;
    }
    if (defaultCenter !== undefined) {
      return;
    }

    setDefaultMapCenter(position);
    if (mapRef.current) {
      mapRef.current.setCenter(position);
    }
  }, [defaultCenter, position]);

  const mapOnLoad = useCallback(
    (map: any) => {
      mapRef.current = map;
      if (defaultMapCenter) {
        map.setCenter(defaultMapCenter);
      }
      const googleMaps = (window as any).google;

      if (gpsCoords && typeof gpsCoords !== "number") {
        const fitBounds = (map: { fitBounds: (arg: any) => void }) => {
          const bounds = new googleMaps.maps.LatLngBounds();
          for (const place of gpsCoords) {
            bounds && bounds.extend(place.pos);
          }
          map.fitBounds(bounds);
        };
        fitBounds(map);
      }

      if (zoomControl) {
        zoomButtonDiv.render(<ZoomControl map={map} />);
      }
      if (centerControl) {
        const centerHandler = (center) =>
          onManualCenter(center ? center : defaultMapCenter);
        centeredButtonDiv.render(<CenteredControl onCenter={centerHandler} />);
      }
      const RB = googleMaps.maps.ControlPosition.RIGHT_BOTTOM;
      map.controls[RB].push(zoomButtonDiv);
      map.controls[RB].push(centeredButtonDiv);

      if (withSearch) {
        searchInputDiv.render(
          <Search map={mapRef} position={searchPosition} />,
        );
        map.controls[googleMaps.maps.ControlPosition.TOP_LEFT].push(
          searchInputDiv,
        );
      }

      if (fullScreenControl) {
        fullscreenButtonDiv.render(<FullScreenControl map={map} />);
        map.controls[googleMaps.maps.ControlPosition.TOP_RIGHT].push(
          fullscreenButtonDiv,
        );
      }

      if (driversTrackControl && showDriversTrack !== undefined) {
        driverTrackButtonDiv.render(
          <DriversTrackControl
            showDriversTrack={showDriversTrack}
            driversTrackBtnOnClick={driversTrackBtnOnClick}
          />,
        );
        map.controls[googleMaps.maps.ControlPosition.TOP_RIGHT].push(
          driverTrackButtonDiv,
        );
      }

      if (showMachineTrackButton) {
        machineTrackButtonDiv.render(
          <MachineTrackButton
            trackOnSelect={machineTrackOnSelect}
            trackOnCancel={machineTrackOnCancel}
            isTrackSelected={isTrackSelected}
            setTrackSelected={setTrackSelected}
            disabled={trackDisabled}
            selectedMachine={openMachineId}
            lang={lang}
            trackSelection={machineTrackSelection}
          />,
        );
        map.controls[googleMaps.maps.ControlPosition.TOP_RIGHT].push(
          machineTrackButtonDiv,
        );
      }

      if (showHelpBtn) {
        helpButton.render(
          <RoundedHelpButton
            show={showHelpBtn}
            onHelpBtnClick={onHelpBtnClick}
          />,
        );
        map.controls[googleMaps.maps.ControlPosition.TOP_RIGHT].push(
          helpButton,
        );
      }

      if (defaultCenter !== null && defaultCenter !== undefined) {
        setDefaultMapCenter(defaultCenter);
        map.setCenter(defaultCenter);
      }

      if (defaultAddress && !defaultCenter) {
        const Geocoder = new googleMaps.maps.Geocoder();

        Geocoder.geocode({ address: defaultCenter }, (results, status) => {
          if (status == googleMaps.maps.GeocoderStatus.OK) {
            const coordinates = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            };

            setDefaultMapCenter(coordinates);
            map.setCenter(coordinates);
          }
        });
      }
    },
    [
      defaultMapCenter,
      centerControl,
      defaultAddress,
      defaultCenter,
      driversTrackBtnOnClick,
      driversTrackControl,
      fullScreenControl,
      gpsCoords,
      isTrackSelected,
      lang,
      machineTrackOnCancel,
      machineTrackOnSelect,
      machineTrackSelection,
      onHelpBtnClick,
      openMachineId,
      searchPosition,
      showDriversTrack,
      showHelpBtn,
      showMachineTrackButton,
      trackDisabled,
      withSearch,
      zoomControl,
    ],
  );

  if (!isLoaded || (!position && !error)) {
    return <Loader />;
  }

  let machineMarkers: React.ReactNode;
  if (machines.length) {
    machineMarkers = machines.map((machine) => (
      <MachineMarker
        machine={machine}
        handleEditEntry={handleEditEntry!}
        key={`m${machine._id}`}
        closeInfoWindow={closeAllInfoWindows}
        openId={openMachineId}
        onCenter={onManualCenter}
        resetMachinePosition={resetMachinePosition!}
        itemOnClick={itemOnClick}
      />
    ));
  } else if (machineSelector && machineIds) {
    machineMarkers = machineIds.map((id) => (
      <MachineMarker
        machineId={id}
        handleEditEntry={handleEditEntry!}
        machineSelector={machineSelector}
        key={`m${id}`}
        closeInfoWindow={closeAllInfoWindows}
        openId={openMachineId}
        onCenter={onManualCenter}
        resetMachinePosition={resetMachinePosition!}
        itemOnClick={itemOnClick}
      />
    ));
  }

  return (
    <GoogleMap
      mapContainerClassName={colorMode == "dark" ? "darkmode" : ""}
      mapContainerStyle={containerStyle}
      zoom={zoom}
      onLoad={mapOnLoad}
      onClick={internalMapOnClick}
      options={mapOptionsWithProps}
      onZoomChanged={onZoomChanged}
      onBoundsChanged={onBoundsChanged}
    >
      {machineMarkers}
      {showCompass && machineSelector && currentMachineID !== undefined && (
        <MachineCompass
          currentMachineId={currentMachineID}
          machineSelector={machineSelector}
        />
      )}
      {showCurrentPosition && position && (
        <Marker
          position={position}
          icon={getMachineIconOptions(MapMarkerOnlineSvg)}
        />
      )}
      {defaultMarker && <Marker position={defaultMarker} />}
      {!!machineTrackData.length && <MachineTrack track={machineTrackData} />}
      {parcels.map((parcel, index) => (
        <ParcelFigure
          parcel={parcel}
          handleEditEntry={handleEditEntry!}
          onPolygonComplete={onPolygonComplete}
          key={`p${parcel._id || index}`}
          editable={editMode}
          jobsIconOnClick={parcelJobsCallback}
          closeInfoWindow={closeAllInfoWindows}
          noInfoWindow={noInfoWindow}
          openId={openParcelId}
          onCenter={onManualCenter}
          onParcelSelect={onParcelSelect}
          highlighted={
            highlightedParcelIds &&
            highlightedParcelIds.includes(parcel._id as number)
          }
          color={
            highlightedParcelIds &&
            highlightedParcelIds.includes(parcel._id as number)
              ? parcelSelectedColor
              : undefined
          }
          onNavigateToParcel={onNavigateToParcel}
          marker={marker}
          setMarker={setMarker}
          polygon={polygon}
          setPolygon={setPolygon}
          AreaOverlay={AreaOverlay}
          unsetPolygonArea={unsetPolygonArea}
          submitPolygonVertices={submitPolygonVertices}
          zoomLevel={zoomLevel}
          bounds={bounds}
          markerSize={markerSize}
          markerLabel={markerLabel}
          unitOfMeasurement={unitOfMeasurement}
        />
      ))}
      {drawMode && (
        <DrawManager
          mark={marker}
          onPolygonComplete={onPolygonComplete}
          drawMarkerMode={drawMarkerMode}
          drawMarkerModeOnly={drawMarkerModeOnly}
          drawParcel={drawParcel}
        />
      )}
      {machineSelector && currentMachineID !== undefined && (
        <DirectionsManager
          machineSelector={machineSelector}
          currentMachineId={currentMachineID}
          destinationParcel={drawDirectionToParcel}
        />
      )}
      {onFilterParcels && (
        <PolygonFilter
          parcels={parcels as any[]}
          onFilter={onFilterParcels}
          highlightedParcelIds={highlightedParcelIds}
        />
      )}
      {drivers && <DriversLiveTrack drivers={drivers} />}
    </GoogleMap>
  );
};
