import { Alert, Box } from "@mui/material";
import { FormikValues } from "ProjectComponents/parcelInformationModal/validationConfig";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DRAWINGMODE } from "./utils";

interface IInfoAlert {
  drawingMode: DRAWINGMODE;
  polyCoordsCount?: number;
}

export const InfoAlert: React.FC<IInfoAlert> = ({
  drawingMode,
  polyCoordsCount,
}) => {
  const [t] = useTranslation();
  const { values } = useFormikContext<FormikValues>();

  const markerInfo = useMemo(
    () =>
      drawingMode === DRAWINGMODE.MARKER &&
      t("parcels.notification.markerDrawing.info"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [drawingMode],
  );

  const polygonInfo = useMemo(() => {
    if (drawingMode !== DRAWINGMODE.POLYGON) return "";
    else {
      if (!polyCoordsCount || polyCoordsCount < 3)
        return t("parcels.notification.polygonDrawing.infoEmptyPolygon");
      if (values.isClosedPolygon)
        return t("parcels.notification.polygonDrawing.infoClosedPolygon");
      else return t("parcels.notification.polygonDrawing.infoPolyDrawing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingMode, values.isClosedPolygon, polyCoordsCount]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 15,
        left: 150,
        opacity: 1,
        marginRight: "15px",
      }}
    >
      <Alert severity="info">
        {markerInfo}
        {polygonInfo}
      </Alert>
    </Box>
  );
};
