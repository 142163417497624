import { CROPCOLOR } from "@ero/app-common/enums";
import { Grid } from "@mui/material";
import { Input } from "Components";
import { useDropdownValues } from "Hooks/dropdownValues";
import { SelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/SelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { DateInput } from "ProjectComponents/FormikDateInput/DateInput";
import { FormikValues } from "ProjectComponents/parcelInformationModal/validationConfig";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useOrientationOptions,
  useStainOptions,
} from "../parcelData/autoCompletes";

export const StructuralInformation: React.FC = () => {
  const [t] = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const { fetchDropdownValues: fetchCropOptions } = useDropdownValues("crop");
  const { fetchDropdownValues: fetchSoilOptions } = useDropdownValues("soil");
  const { fetchDropdownValues: fetchTrainingTypeOptions } =
    useDropdownValues("trainingType");

  const stainOptions = useStainOptions();
  const orientationOptions = useOrientationOptions();

  useEffect(() => {
    const fetchColor = async () => {
      const crops = await fetchCropOptions();
      const colorObj = crops.find((crop) => crop.value == values.crop)?.meta;
      let colorTranslated: string | undefined;
      switch (colorObj?.color) {
        case CROPCOLOR.WHITE:
          colorTranslated = t("parcels.kind.white");
          break;
        case CROPCOLOR.RED:
          colorTranslated = t("parcels.kind.red");
          break;
        default:
          colorTranslated = colorObj?.color;
      }
      setFieldValue("color", colorTranslated);
    };
    fetchColor();
  }, [values.crop, t, fetchCropOptions, setFieldValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SelectWithAutoComplete
          name="crop"
          fetchOptions={fetchCropOptions}
          label={t("parcels.fields.grape_variety")}
        />
      </Grid>
      <Grid item xs={3}>
        <Input name="color" label={t("parcels.fields.color")} disabled={true} />
      </Grid>
      <Grid item xs={3}>
        <StaticSelectWithAutoComplete
          name="stain"
          options={stainOptions}
          label={t("parcels.fields.stain")}
        />
      </Grid>
      <Grid item xs={6}>
        <Input name={"clone"} type="text" label={t("parcels.fields.clone")} />
      </Grid>
      <Grid item xs={6}>
        <Input name={"base"} type="text" label={t("parcels.fields.base")} />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          format={"DD.MM.YYYY"}
          name="plantingDate"
          label={t("orders.fields.plantingDate")}
        />
      </Grid>
      <Grid item xs={6}>
        <DateInput
          format={"DD.MM.YYYY"}
          name="clearingDate"
          label={t("orders.fields.clearingDate")}
        />
      </Grid>
      <Grid item xs={3}>
        <Input
          name={"slope"}
          type="number"
          label={t("orders.fields.steep_slope")}
        />
      </Grid>
      <Grid item xs={3}>
        <StaticSelectWithAutoComplete
          name="slopeDirection"
          options={orientationOptions}
          label={t("parcels.fields.orientation")}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectWithAutoComplete
          name="soil"
          fetchOptions={fetchSoilOptions}
          label={t("orders.fields.soil")}
        />
      </Grid>
      <Grid item xs={6}>
        <SelectWithAutoComplete
          name="trainingType"
          fetchOptions={fetchTrainingTypeOptions}
          label={t("parcels.fields.cultivation_method")}
        />
      </Grid>
      <Grid item xs={12}>
        <Input multiline name="notes" label={t("general.fields.notes")} />
      </Grid>
    </Grid>
  );
};
