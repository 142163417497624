import { PERM } from "@ero/app-common/enums";
import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { Avatar, Badge, Tooltip } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();

  return [
    {
      field: "avatar",
      headerName: t("employees.fields.avatar"),
      renderCell: (params) => (
        <Tooltip title={params.row.online ? "Online" : "Offline"}>
          <Badge variant="dot" color={params.row.online ? "success" : "error"}>
            <Avatar src={params.value} style={{ width: 30, height: 30 }} />
          </Badge>
        </Tooltip>
      ),
      disableExport: true,
      maxWidth: 70,
    },
    {
      field: "name",
      headerName: t("general.fields.name"),
      valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
    },
    {
      field: "userName",
      headerName: t("general.fields.userName"),
    },
    {
      field: "permission",
      headerName: t("employees.fields.permission"),
      valueGetter: (params) => {
        switch (params.value) {
          case PERM.ORGANIZER:
            return t("employees.permission.organizer");
          case PERM.DRIVER:
            return t("employees.permission.driver");
        }
      },
    },
    {
      field: "email",
      headerName: t("general.fields.email"),
    },
    {
      field: "phone",
      headerName: t("general.fields.phone"),
    },
    {
      field: "qualification",
      headerName: t("employees.fields.qualification"),
    },
    {
      field: "notes",
      headerName: t("general.fields.notes"),
    },
    {
      field: "driversLicense",
      headerName: t("general.fields.personal_data.drivers_license"),
      valueGetter: (params) => {
        if (params.value._id === -1) {
          return "-";
        }
        return params.value.name;
      },
    },
  ];
};

export const defaultHiddenColumns = {
  status: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "string", access: ["firstName"] },
  { type: "string", access: ["lastName"] },
  { type: "string", access: ["userName"] },
  { type: "string", access: ["email"] },
  { type: "string", access: ["phone"] },
  { type: "string", access: ["qualification"] },
  { type: "string", access: ["notes"] },
];
