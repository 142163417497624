import { ParcelOutside } from "@ero/app-common/models";
import flatten from "@flatten-js/core";
import { DrawingManager } from "@react-google-maps/api";
import { filterShapeOptions } from "Components/map/mapConfig";
import React, { useEffect, useMemo, useState } from "react";

type Props = {
  onFilter?: (parcels: ParcelOutside[]) => void;
  parcels: ParcelOutside[];
  highlightedParcelIds?: number[] | null;
};

export const PolygonFilter: React.FC<Props> = ({
  parcels,
  onFilter,
  highlightedParcelIds,
}) => {
  const [shape, setShape] = useState<any>(null);

  const parcelShapes = useMemo(() => {
    return parcels.map((parcel) => {
      if (!!parcel.shape && parcel.shape.length) {
        return new flatten.Polygon(
          parcel.shape.map(({ lat, lng }) => flatten.point(lng, lat)),
        );
      } else if (parcel.position) {
        const { lat, lng } = parcel.position;
        return flatten.point(lng, lat);
      }
    });
  }, [parcels]);

  const overlayCompleteHandler = ({ overlay: newShape, type }) => {
    if (!onFilter) {
      return;
    }
    if (shape) {
      shape.setMap(null);
    }
    setShape(newShape);

    if (type === "circle") {
      const center = newShape.getCenter();
      const xCenter = center.lng();
      const yCenter = center.lat();
      const radius = newShape.getRadius() / 111111;

      const circle = flatten.circle(flatten.point(xCenter, yCenter), radius);

      const filteredParcels = parcels.filter((_, index) => {
        const parcelShape = parcelShapes[index];
        if (parcelShape) {
          return (
            !!circle.intersect(parcelShape).length ||
            circle.contains(parcelShape)
          );
        }
        return false;
      });
      onFilter(filteredParcels);
      return;
    }

    let polygon: flatten.Polygon;
    if (type === "rectangle") {
      const bounds = newShape.getBounds();
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      polygon = new flatten.Polygon([
        flatten.point(sw.lng(), sw.lat()),
        flatten.point(sw.lng(), ne.lat()),
        flatten.point(ne.lng(), ne.lat()),
        flatten.point(ne.lng(), sw.lat()),
      ]);
    } else {
      polygon = new flatten.Polygon(
        newShape
          .getPath()
          .getArray()
          .map((ll) => flatten.point(ll.lng(), ll.lat())),
      );
    }

    const filteredParcels = parcels.filter((_, index) => {
      const parcelShape = parcelShapes[index];
      if (parcelShape) {
        return (
          !!polygon.intersect(parcelShape).length ||
          polygon.contains(parcelShape)
        );
      }
      return false;
    });

    onFilter(filteredParcels);
  };

  useEffect(() => {
    if (highlightedParcelIds === null && shape) {
      shape.setMap(null);
      setShape(null);
    }
  }, [highlightedParcelIds, shape]);

  const drawingManagerOptions: google.maps.drawing.DrawingManagerOptions = {
    drawingControl: true,
    drawingControlOptions: {
      drawingModes: [
        google.maps.drawing.OverlayType.RECTANGLE,
        google.maps.drawing.OverlayType.CIRCLE,
        google.maps.drawing.OverlayType.POLYGON,
      ],
      position: 11,
    },
    polygonOptions: filterShapeOptions,
    circleOptions: filterShapeOptions,
    rectangleOptions: filterShapeOptions,
  };

  return (
    <DrawingManager
      drawingMode={null}
      onOverlayComplete={overlayCompleteHandler}
      options={drawingManagerOptions}
    />
  );
};
