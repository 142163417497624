import i18n from "i18next";

export enum ORGANIZATION_TYPE {
  CUSTOMER_CATEGORIES = "category",
  MACHINE_MANUFACTURERS = "manufacturer",
  SERVICE_TYPES = "serviceKind",
  GRAPE_VARIETIES = "crop",
  CULTIVATION_METHODS = "trainingType",
  SOIL_TYPES = "soil",
  DRIVING_LICENSE_TYPES = "driversLicense",
}

export const customerCatgeories = () => [
  {
    title: i18n.t("orders.fields.categories"),
    id: "ct125",
    route: ORGANIZATION_TYPE.CUSTOMER_CATEGORIES,
  },
];

export const machineManufacturers = () => [
  {
    title: i18n.t("machines.fields.manufacturer"),
    id: "ert139",
    route: ORGANIZATION_TYPE.MACHINE_MANUFACTURERS,
  },
];

export const serviceTypes = () => [
  {
    title: i18n.t("services.fields.serviceKind"),
    id: "serviceServiceKind123",
    route: ORGANIZATION_TYPE.SERVICE_TYPES,
  },
];

export const parcels = () => [
  {
    title: i18n.t("orders.fields.crop"),
    id: "ag125",
    route: ORGANIZATION_TYPE.GRAPE_VARIETIES,
  },
  {
    title: i18n.t("orders.fields.formOfEducationTitle"),
    id: "ert127",
    route: ORGANIZATION_TYPE.CULTIVATION_METHODS,
  },
  {
    title: i18n.t("orders.fields.soilTitle"),
    id: "gfg124",
    route: ORGANIZATION_TYPE.SOIL_TYPES,
  },
];

export const drivingLicenseTypes = () => [
  {
    title: i18n.t("general.fields.personal_data.drivers_license"),
    id: "A_license",
    route: ORGANIZATION_TYPE.DRIVING_LICENSE_TYPES,
  },
];
