import { GridRowId } from "@mui/x-data-grid";
import { CustomerInformationModal } from "ProjectComponents";
import { BaseDataTable } from "ProjectComponents/tableV2/baseDataTable/BaseDataTable";
import { type AppState } from "Store";
import {
  deleteCustomers,
  getCustomers,
  getCustomersInitial,
  resetSearch,
  setTableHiddenColumns,
} from "Store/customers";
import { LocalCustomerType } from "Store/customers/specs";
import { searchSpecShorthand } from "Utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  defaultHiddenColumns,
  quickSearchColumns,
  useColumns,
} from "./tableConfig";

export const Customers: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    initialMeta,
    listMeta,
    list: customersList,
    maxCount: listMaxCount,
    listUpdateLoading: tableLoading,
    hiddenColumns,
    searchData,
    loading,
  } = useSelector((state: AppState) => state.customers);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customerToUpdate, setCustomerToUpdate] = useState<LocalCustomerType>();

  useEffect(() => {
    if (location.state?.create) setShowCustomerModal(true);
  }, [location.state]);

  useEffect(() => {
    if (customerToUpdate) {
      const customer = customersList.find(
        (item) => item._id === customerToUpdate?._id,
      );
      setCustomerToUpdate(customer);
    }
  }, [customerToUpdate, customersList]);

  useEffect(() => {
    dispatch(getCustomersInitial(listMeta));

    return () => {
      dispatch(resetSearch());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMeta]);

  const handleTableRowsDelete = useCallback((deletedIds: GridRowId[]) => {
    dispatch(deleteCustomers(deletedIds as number[]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTablePagination = useCallback(
    (currentPage: number, numberOfRows: number) => {
      const config = {
        params: {
          ...listMeta,
          page: currentPage,
          pageLength: numberOfRows,
        },
        search: searchData,
      };

      dispatch(getCustomers(config));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta, searchData],
  );

  const handleTableSearch = useCallback(
    (query: string) => {
      const config = {
        params: listMeta,
        search: searchSpecShorthand(query, quickSearchColumns),
      };
      dispatch(getCustomers(config));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listMeta],
  );

  const onRowClick = useCallback(
    (id: GridRowId) => {
      const selectedCustomer = customersList.find((item) => item._id === id);
      setCustomerToUpdate(selectedCustomer);
      setShowCustomerModal(true);
    },
    [customersList],
  );

  const onChangeColumnsView = (values: Record<string, boolean>) => {
    dispatch(setTableHiddenColumns(values));
  };

  const currentHiddenColumns = useMemo(
    () => ({ ...defaultHiddenColumns, ...hiddenColumns }),
    [hiddenColumns],
  );

  const columns = useColumns();

  return (
    <>
      <BaseDataTable
        columnVisibilityModel={currentHiddenColumns}
        columns={columns}
        handlePagination={handleTablePagination}
        handleQuickSearch={handleTableSearch}
        loading={tableLoading || loading || initialMeta.loading}
        maxCount={listMaxCount}
        onColumnVisibilityModelChange={onChangeColumnsView}
        onRowClick={onRowClick}
        rows={customersList}
        toolbarProps={{
          onAddClick: () => {
            setCustomerToUpdate(undefined);
            setShowCustomerModal(true);
          },
          onDeleteClick: handleTableRowsDelete,
        }}
        paginationModel={{ page: listMeta.page, pageSize: listMeta.pageLength }}
      />
      <CustomerInformationModal
        isOpen={showCustomerModal}
        onClose={() => setShowCustomerModal(false)}
        customer={customerToUpdate}
      />
    </>
  );
};
