import { LICENSETYPE } from "@ero/app-common/v2/routes/models/license";
import { RegistrationConfirmResponseBody } from "@ero/app-common/v2/routes/models/registration";
import {
  CancelOutlined,
  CheckCircleOutlineOutlined,
} from "@mui/icons-material";
import { Box, Button, LinearProgress, useTheme } from "@mui/material";
import * as Sentry from "@sentry/react";
import { confirmRegistration } from "Api";
import eroGoLogoNegativ from "Assets/Logo_EROgo_negativ_RGB.png";
import eroGoLogo from "Assets/Logo_EROgo_positiv_RGB.png";
import eroPlanLogo from "Assets/Logo_EROplan_RGB.png";
import eroPlanLogoNegativ from "Assets/Logo_EROplan_negativ_RGB.png";
import { AppState } from "Store";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ResponseContent } from "./responseContent";

export const ConfirmRegistration: React.FC = () => {
  const [t] = useTranslation();

  const theme = useTheme();

  const [searchParams] = useSearchParams();

  const { colorMode } = useSelector((state: AppState) => state.auth);

  const [error, setError] = useState(false);
  const [confirmationResponse, setConfirmationResponse] =
    useState<RegistrationConfirmResponseBody>();

  const validateToken = useCallback(async (token: string) => {
    try {
      const response = await confirmRegistration(token);
      setConfirmationResponse(response.data);
    } catch (error) {
      setError(true);
      Sentry.captureException(error);
    }
  }, []);

  useEffect(() => {
    const token = searchParams.get("token");

    if (token && token.length > 0) {
      validateToken(token);
    }
  }, [searchParams, validateToken]);

  const logo = useMemo(() => {
    if (colorMode === "light") {
      if (confirmationResponse?.licenseType === LICENSETYPE.FREEMIUM) {
        return eroGoLogo;
      } else {
        return eroPlanLogo;
      }
    } else {
      if (confirmationResponse?.licenseType === LICENSETYPE.FREEMIUM) {
        return eroGoLogoNegativ;
      } else {
        return eroPlanLogoNegativ;
      }
    }
  }, [colorMode, confirmationResponse?.licenseType]);

  return (
    <>
      <img src={logo} alt="logo" style={{ maxWidth: "100%", padding: 32 }} />
      <Box textAlign={"center"} display="flex" flexDirection={"column"}>
        {error ? (
          <ResponseContent
            icon={
              <CancelOutlined
                sx={{ color: theme.palette.primary.main, fontSize: "75px" }}
              />
            }
            heading={t("confirm_registration.failed_heading")}
            body={t("confirm_registration.failed_body")}
          />
        ) : (
          <>
            {confirmationResponse && (
              <ResponseContent
                icon={
                  <CheckCircleOutlineOutlined
                    sx={{
                      color: theme.palette.success.main,
                      fontSize: "75px",
                    }}
                  />
                }
                heading={t("confirm_registration.success_heading")}
                body={
                  confirmationResponse.resetToken
                    ? t("confirm_registration.success_body")
                    : t("confirm_registration.success_body_no_password")
                }
              >
                {confirmationResponse && confirmationResponse.resetToken && (
                  <Button
                    variant="contained"
                    color="primary"
                    href={`/reset-password?token=${confirmationResponse.resetToken}`}
                  >
                    {t("confirm_registration.set_password")}
                  </Button>
                )}
              </ResponseContent>
            )}
          </>
        )}
        {!confirmationResponse && !error && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </>
  );
};
