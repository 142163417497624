import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { getOrdersV2 } from "Api";
import { MAX_LENGTH, VALIDATION_ERRORS } from "Constants";
import { useDateRestrictionsSchema } from "Hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

export type ValuesType = {
  name: string;
  dateRestrictions: {
    dateMode: DateMode | undefined;
    timeMode: DateTimeMode | undefined;
    date: number | undefined;
    startDate: number | undefined;
    endDate: number | undefined;
    dayTime: {
      startTime: number | undefined;
      endTime: number | undefined;
    };
  };
  customer: string;
  jobs: ({ parcel: number; service: number; create: boolean } | undefined)[];
};

export const initialValues: ValuesType = {
  name: "",
  customer: "",
  dateRestrictions: {
    dateMode: DateMode.FIXEDDATE,
    timeMode: DateTimeMode.WITHOUTTIME,
    date: undefined,
    startDate: undefined,
    endDate: undefined,
    dayTime: {
      startTime: undefined,
      endTime: undefined,
    },
  },
  jobs: [],
};

export const useValidationSchema = (jobsRequired = false) => {
  const [t] = useTranslation();

  const dateRestrictionsSchema = useDateRestrictionsSchema();

  const jobsSchema = useMemo(() => {
    let schema = Yup.array(
      Yup.object({
        parcel: Yup.number().required(),
        service: Yup.number().required(),
        create: Yup.bool().optional(),
      })
        .default(null)
        .nullable(),
    );
    if (jobsRequired) {
      schema = schema.test(
        "create-at-least-one-job",
        t("notification.jobsRequired"),
        (jobs) => jobs?.some((job) => job?.create) ?? false,
      );
    } else {
      schema = schema.optional();
    }
    return schema;
  }, [jobsRequired, t]);

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
          .required(VALIDATION_ERRORS.REQUIRED)
          .test(
            "is-unique",
            t("notification.order_name_not_unique"),
            async (search) => (await getOrdersV2({ search })).maxCount === 0,
          ),
        customer: Yup.number().positive().required(VALIDATION_ERRORS.REQUIRED),
        jobs: jobsSchema,
        dateRestrictions: dateRestrictionsSchema,
      }),
    [jobsSchema, dateRestrictionsSchema, t],
  );
  return schema;
};
