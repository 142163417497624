import { ParcelOutside, PlotOutside } from "@ero/app-common/models";
import { PickBySpec } from "@ero/app-common/util/Spec";

export const parcelsSpec = [
  ["base"],
  ["clearingDate"],
  ["clone"],
  ["color"],
  ["stain"],
  ["crop", "_id"],
  ["crop", "name"],
  ["customer", "companyName"],
  ["customer", "label"],
  ["flnr"],
  ["flurstuecke", "_id"],
  ["flurstuecke", "flurstuecksnummer"],
  ["flurstuecke", "size"],
  ["gemarkungsnummer"],
  ["growingarea"],
  ["internalId"],
  ["largeRegion"],
  ["mark"],
  ["name"],
  ["notes"],
  ["plantAmount"],
  ["plantGap"],
  ["plantingDate"],
  ["position"],
  ["region"],
  ["regionette"],
  ["rowAmount"],
  ["rowGap"],
  ["separatedregion"],
  ["shape"],
  ["sizeField"],
  ["sizeAutomatic"],
  ["sizeImportedKAFL"],
  ["sizeImportedNUFL"],
  ["sizeManual"],
  ["size"],
  ["slope"],
  ["slopeDirection"],
  ["soil", "name"],
  ["trainingType", "name"],
  ["jobHistory"],
  ["jobInfo", "_id"],
  ["jobInfo", "serviceDifficulty"],
  ["jobInfo", "name"],
  ["jobInfo", "status"],
  ["jobInfo", "order"],
] as const;

export const parcelViewSpecs = [...parcelsSpec] as const;

export type LocalParcelType = Omit<
  PickBySpec<ParcelOutside, typeof parcelsSpec>,
  "flurstuecke"
> & { flurstuecke: PlotOutside[] };
export type LocalViewParcelType = PickBySpec<
  ParcelOutside,
  typeof parcelViewSpecs
>;
