import { type Milliseconds } from "@ero/app-common/util/Milliseconds";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import FullCalendar from "@fullcalendar/react";
import { Box } from "@mui/material";
import { Calendar } from "Components";
import { type AppState } from "Store";
import { printEmployeeCalendar, setRowScale } from "Store/planning";
import { scrollToTime } from "Utils/calendar";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToggleResources } from "./components";
import { type MarkedEventType } from "./helpers";
import { useDate, useEvents, useJobs, useResources } from "./hooks";

const emptyMarkedEvent = {
  id: 0,
  dayRange: { start: 0 as Milliseconds, end: 0 as Milliseconds },
  selectedResource: "",
};

interface ICalendarWrapper {
  droppableContainerEl?: HTMLDivElement;
  isSidebarCollapsed: boolean;
  setPlannedJob: Dispatch<SetStateAction<JobResponseBody | undefined>>;
  openSelectMachineModal: () => void;
  inititalDate: number | undefined;
  scrollTime: number | undefined;
}

export const CalendarWrapper: React.FC<ICalendarWrapper> = ({
  droppableContainerEl,
  isSidebarCollapsed,
  setPlannedJob,
  openSelectMachineModal,
  inititalDate,
  scrollTime,
}) => {
  const dispatch = useDispatch();

  const calendarWrapRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<FullCalendar | null>(null);

  const [markedEvent, setMarkedEvent] =
    useState<MarkedEventType>(emptyMarkedEvent);
  const [eventUpdating, setEventUpdating] =
    useState<MarkedEventType>(emptyMarkedEvent);

  const {
    calendarCurrentView,
    dateRange,
    employees,
    error,
    eventColor,
    initialMeta,
    jobs,
    loading,
    rowScale,
    success,
    updateJobLoading,
    displayedDateRange,
    selectedEmployees,
  } = useSelector((state: AppState) => state.planning);
  const {
    selectedLang,
    companyData: { unitOfMeasurement },
  } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (!success) {
      return;
    }

    if (markedEvent.id && markedEvent.id === eventUpdating.id) {
      setMarkedEvent(eventUpdating);
    }

    setEventUpdating(emptyMarkedEvent);
  }, [success, eventUpdating, markedEvent.id]);

  useEffect(() => {
    scrollToTime(calendarRef, scrollTime);
  }, [displayedDateRange, scrollTime]);

  const { onDateSet } = useDate({
    calendarCurrentView,
    dateRange,
    displayedDateRange,
    initialMeta,
    loading,
  });

  const { resources, resourceDidMount } = useResources({
    employees,
    selectedEmployees,
  });

  const { jobEvents, renderJobEventContent } = useJobs({
    calendarCurrentView,
    emptyMarkedEvent,
    eventColor,
    jobs,
    markedEvent,
    setMarkedEvent,
    unitOfMeasurement,
    updateJobLoading,
  });

  const {
    eventOverlap,
    onEventColorChange,
    onEventClick,
    onEventDrag,
    onEventDragStart,
    onEventDragStop,
  } = useEvents({
    droppableContainerEl,
    jobs,
    jobEvents,
    markedEvent,
    setEventUpdating,
    setPlannedJob,
    openSelectMachineModal,
  });

  const handlePrint = useCallback(
    (values: { employee: number; start: number; end: number }) => {
      dispatch(printEmployeeCalendar(values));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onRowScaleChange = useCallback((value: number) => {
    dispatch(setRowScale(value));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box ref={calendarWrapRef} sx={{ height: "100%", padding: 2 }}>
      <Calendar
        calendarRef={calendarRef}
        droppableOutsideContainer={droppableContainerEl}
        events={jobEvents}
        eventColorValue={eventColor}
        eventOverlap={eventOverlap}
        hasError={error}
        initialDate={inititalDate}
        initialView={calendarCurrentView}
        isSelectable={false}
        isToolbarLeftPadding={isSidebarCollapsed}
        loading={loading || updateJobLoading}
        locale={selectedLang}
        onDateSet={onDateSet}
        onEventClick={onEventClick}
        onEventColorChange={onEventColorChange}
        onEventDrag={onEventDrag}
        onEventDragStart={onEventDragStart}
        onEventDragStop={onEventDragStop}
        onPrintButtonClick={handlePrint}
        onRowScaleChange={onRowScaleChange}
        renderEventContent={renderJobEventContent}
        resources={resources}
        resourceLaneDidMount={resourceDidMount}
        resourceLabelDidMount={resourceDidMount}
        resourceOrder="index"
        rowScaleValue={rowScale}
        showEventColorSwitchers
        showMenu
        showRowScale
        appointmentColorization={eventColor}
      >
        <ToggleResources
          employees={employees}
          selectedEmployees={selectedEmployees}
        />
      </Calendar>
    </Box>
  );
};
