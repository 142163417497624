import {
  addDropdown,
  getDropDownOptionsV2,
  removeDropdown,
  updateDropdown,
} from "Api";
import { errorToast } from "Services";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { ORGANIZATION_ACTION_TYPES } from "./action-types";
import { getAllDropdownsSuccess, setError, setLoading } from "./actions";

export function* getAllDropdownsSaga(payload) {
  const { key } = payload;
  try {
    yield put(setLoading());
    const res = yield call(getDropDownOptionsV2, key);

    yield put(getAllDropdownsSuccess({ name: key, dropDown: res.data.data }));
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export function* addNewDropdownSaga(payload) {
  const { key, name, meta } = payload.payload;
  try {
    yield put(setLoading());
    yield addDropdown({ key: key, name: name, meta });
    const res = yield call(getDropDownOptionsV2, key);
    yield put(getAllDropdownsSuccess({ name: key, dropDown: res.data.data }));
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export function* updateDropdownSaga(payload) {
  try {
    yield put(setLoading());
    yield call(updateDropdown, payload.payload);
    const res = yield call(getDropDownOptionsV2, payload.payload.key);
    yield put(
      getAllDropdownsSuccess({
        name: payload.payload.key,
        dropDown: res.data.data,
      }),
    );
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export function* deleteDropdownSaga(payload) {
  const { key } = payload.payload;
  try {
    yield put(setLoading());
    yield removeDropdown(payload.payload);
    const res = yield call(getDropDownOptionsV2, key);
    yield put(getAllDropdownsSuccess({ name: key, dropDown: res.data.data }));
  } catch (error) {
    yield put(setError());
    errorToast(error);
  }
}

export default function* organizationSagas() {
  yield all([
    takeEvery(ORGANIZATION_ACTION_TYPES.GET_ALL_DROPDOWNS, getAllDropdownsSaga),
    takeEvery(ORGANIZATION_ACTION_TYPES.ADD_DROPDOWN, addNewDropdownSaga),
    takeEvery(ORGANIZATION_ACTION_TYPES.UPDATE_DROPDOWN, updateDropdownSaga),
    takeEvery(ORGANIZATION_ACTION_TYPES.REMOVE_DROPDOWN, deleteDropdownSaga),
  ]);
}
