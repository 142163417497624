import axios from "axios";

import { UnknownAction } from "@reduxjs/toolkit";
import { store } from "Store";
import { logout, refreshAccessToken } from "Store/auth";
import {
  constructErrorHandler,
  construct_handle403KeepAlive,
  construct_retry401AfterKeepAlive,
  createAuthInterceptor,
  handle502,
  instanceConfig,
  instanceConfigV2,
  requestRejectError,
  responseSuccessSimple,
} from "../Api/axios";

export const apiInstance = axios.create(instanceConfig);
const logoutAction = () => store.dispatch(logout());

// Request interceptor
apiInstance.interceptors.request.use(
  createAuthInterceptor(store),
  requestRejectError,
);

// Response interceptor
apiInstance.interceptors.response.use(
  responseSuccessSimple,
  constructErrorHandler([
    handle502,
    construct_handle403KeepAlive(logoutAction),
    construct_retry401AfterKeepAlive(
      apiInstance,
      store,
      (token) =>
        store.dispatch(refreshAccessToken(token) as unknown as UnknownAction),
      logoutAction,
    ),
  ]),
);

export const apiInstanceV2 = axios.create(instanceConfigV2);

apiInstanceV2.interceptors.request.use(
  createAuthInterceptor(store),
  requestRejectError,
);
