import { SqMeter, UNIT } from "@ero/app-common/util/Units";
import {
  UnitSymbol,
  convertAreaToCustom,
} from "@ero/app-common/util/convertArea";
import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  Typography,
} from "@mui/material";
import { AppState } from "Store";
import { displayDateRestriction } from "Utils/date";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { JobItem } from "./jobItem";

// const defaultTotalJobDurationInMs = 1 * 60 * 60 * 1000;
// const timeBetweenJobsInMs = 5 * 60 * 1000;

interface IOrderItemProps {
  order: OrderResponseBody;
  expanded?: number | false;
  onChange: (
    panel: number,
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}

export const OrderItem: React.FC<IOrderItemProps> = ({
  order,
  expanded,
  onChange,
}) => {
  const [t] = useTranslation();
  const location = useLocation();

  const itemRef = useRef<HTMLDivElement>(null);

  const dateRestrictionsContent = displayDateRestriction(
    order.dateRestrictions,
    t("orders.create_modal.toDo"),
    t("orders.create_modal.until"),
  );

  const unitOfMeasurement = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const sizeConverted: number | undefined = useMemo(
    () =>
      (order.jobDetails?.parcelSize &&
        convertAreaToCustom(
          order.jobDetails?.parcelSize as SqMeter,
          unitOfMeasurement || UNIT.SQUARE_METER,
        )) ||
      undefined,
    [order.jobDetails?.parcelSize, unitOfMeasurement],
  );

  // Can be used again when allowing whole orders to move
  // useEffect(() => {
  //   if (!wrapperRef.current) {
  //     return;
  //   }

  //   if (order.jobDetails) {
  //     const totalJobDurationInMs =
  //       order.jobDetails?.jobs
  //         ?.map((job) => getJobDurationInMs(job.parcel.size, job.difficulty))
  //         .reduce((acc, jobDuration) => acc + jobDuration, 0) ??
  //       defaultTotalJobDurationInMs;

  //     const totalTimeBetweenJobsInMs =
  //       (order.jobDetails?.jobCount - 1) * timeBetweenJobsInMs;

  //     const orderDurationInMs = totalJobDurationInMs + totalTimeBetweenJobsInMs;

  //     const eventDuration = convertToDuration(orderDurationInMs);

  //     const settings: Draggable["settings"] = {
  //       eventData: {
  //         duration: eventDuration,
  //         id: order._id,
  //         title: order.name,
  //         originalItem: order,
  //         type: "order",
  //       },
  //     };
  //     new Draggable(wrapperRef.current, settings);
  //   }
  // }, [order, unitOfMeasurement]);

  const additionalInformation = useMemo(() => {
    let parcelInfo = "";
    if (order.jobDetails?.parcelCount)
      parcelInfo =
        order.jobDetails.parcelCount +
        " " +
        t("general.parcel", { count: order.jobDetails.parcelCount });

    const sizeInfo =
      sizeConverted &&
      `, ${" " + sizeConverted.toFixed(2) + " "}${
        UnitSymbol[unitOfMeasurement || UNIT.SQUARE_METER] + ", "
      }`;

    let servicesInfo = "";
    if (order.jobDetails?.serviceCount)
      servicesInfo =
        order.jobDetails.serviceCount +
        " " +
        t("general.service", { count: order.jobDetails.serviceCount });

    return [parcelInfo, sizeInfo, servicesInfo];
  }, [
    order.jobDetails?.parcelCount,
    order.jobDetails?.serviceCount,
    sizeConverted,
    t,
    unitOfMeasurement,
  ]);

  useEffect(() => {
    if (itemRef.current && location.state?.["orderId"] === order._id)
      itemRef.current.scrollIntoView();
  }, [order._id, location.state]);

  return (
    <Accordion
      ref={itemRef}
      expanded={expanded === order._id}
      onChange={onChange(order._id)}
      variant={"outlined"}
      sx={
        location.state?.["orderId"] === order._id
          ? {
              borderColor: "primary.main",
            }
          : {}
      }
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box flexDirection={"column"}>
          <Typography variant="subtitle2">{order.name}</Typography>
          <Typography variant="subtitle2">
            {order.customer.companyName}
          </Typography>
          <Typography variant="caption" component={"div"}>
            {additionalInformation}
          </Typography>
          <Typography variant="caption" component={"div"}>
            {dateRestrictionsContent}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {order.jobDetails?.jobs?.map((job) => (
            <JobItem key={job._id} job={job} />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
