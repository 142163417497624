import { Box, Container, Typography } from "@mui/material";
import { Loader } from "Components";
import { type AppState } from "Store";
import { getReleaseNotesInitial } from "Store/releaseNotes/actions";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { type HasId } from "../../../../Common/Types";
import { ReleaseNoteCard } from "./releaseNoteCard/releaseNoteCard";

export type singleRevision = HasId & {
  name: string;
  text: string;
  date: string;
};

export const ReleaseNotes: React.FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    initialMeta,
    listMeta,
    list: releaseNotesList,
  } = useSelector((state: AppState) => state.releaseNotes);

  useEffect(() => {
    if (!releaseNotesList.length) {
      dispatch(getReleaseNotesInitial(listMeta));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listMeta, releaseNotesList.length]);

  if (initialMeta.loading) {
    return <Loader />;
  }

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Container maxWidth={"lg"} sx={{ pt: 2 }}>
        <Typography variant="h4">{t("releaseNotes.screen.title")}</Typography>
        {releaseNotesList.map((releaseNoteItem) => (
          <Box key={releaseNoteItem._id} sx={{ py: 2 }}>
            <ReleaseNoteCard
              item={releaseNoteItem.name}
              date={releaseNoteItem.date}
              text={releaseNoteItem.text}
            />
          </Box>
        ))}
      </Container>
    </Box>
  );
};
