import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { Container, Grid } from "@mui/material";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { useFormikContext } from "formik";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValuesType } from "../../formConfig";
import { DateInputContent } from "./components/dateInputContent";
import { DayTimeContent } from "./components/dayTimeContent";

export const SelectDateScreen: React.FC = () => {
  const [t] = useTranslation();
  const { setFieldValue } = useFormikContext<ValuesType>();

  const dateOptions = useMemo(
    () => [
      { value: DateMode.FIXEDDATE, label: t("orders.create_modal.fixedDate") },
      { value: DateMode.DATERANGE, label: t("orders.create_modal.dateRange") },
    ],
    [t],
  );

  const timeOptions = useMemo(
    () => [
      {
        value: DateTimeMode.WITHOUTTIME,
        label: t("orders.create_modal.withoutTime"),
      },
      {
        value: DateTimeMode.WITHTIME,
        label: t("orders.create_modal.withTime"),
      },
      {
        value: DateTimeMode.WITHDAYTIME,
        label: t("orders.create_modal.withDayTime"),
      },
    ],
    [t],
  );

  const resetTimes = useCallback(() => {
    setFieldValue("dateRestrictions.dayTime.startTime", undefined);
    setFieldValue("dateRestrictions.dayTime.endTime", undefined);
  }, [setFieldValue]);

  const resetDates = useCallback(() => {
    setFieldValue("dateRestrictions.date", undefined);
    setFieldValue("dateRestrictions.startDate", undefined);
    setFieldValue("dateRestrictions.endDate", undefined);
    setFieldValue("dateRestrictions.timeMode", undefined);
    resetTimes();
  }, [resetTimes, setFieldValue]);

  const onDateModeChange = useCallback(
    (val) => !val && resetDates(),
    [resetDates],
  );

  const onTimeModeChange = useCallback(
    (val) => !val && resetTimes(),
    [resetTimes],
  );

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <StaticSelectWithAutoComplete
            name="dateRestrictions.dateMode"
            label={t("orders.create_modal.date_mode")}
            options={dateOptions}
            onChange={onDateModeChange}
            nullable
          />
        </Grid>
        <Grid item xs={6}>
          <StaticSelectWithAutoComplete
            name="dateRestrictions.timeMode"
            label={t("orders.create_modal.time_mode")}
            options={timeOptions}
            onChange={onTimeModeChange}
            nullable
          />
        </Grid>
        <Grid item xs={12} container spacing={4}>
          {<DateInputContent />}
        </Grid>
        <Grid item xs={12} container spacing={4}>
          {<DayTimeContent />}
        </Grid>
      </Grid>
    </Container>
  );
};
