import i18n from "i18n";
import React from "react";
import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";
import { Notification } from "Components/notifications";
import { getErrorMessage } from "Utils";

class NotificationsProvider {
  success = (title = "Success", message: string) => {
    toast.success(<Notification title={title} message={message} />);
  };

  info = (title = "How does it work?", message: string) => {
    toast.info(<Notification title={title} message={message} />);
  };

  error = (title = "Error!", message: string) => {
    toast.error(<Notification title={title} message={message} />);
  };
}

function getErrorString(error: any): string {
  if (error instanceof Error) {
    console.error(error);
    return getErrorMessage(error);
  }

  if (typeof error === "string") {
    return error;
  }
  if (error.toString !== undefined) {
    return error.toString();
  }
  return `${error}`;
}
export function errorToast(error: any, title?: string): void {
  Sentry.captureException(error);
  return NotificationService.error(
    i18n.t(title || "notification.error_title"),
    i18n.t(getErrorString(error)),
  );
}

export function successToast(text: string | number, title?: string) {
  NotificationService.success(
    i18n.t(title || "notification.success_title"),
    i18n.t(text.toString()),
  );
}

export function infoToast(text: string | number, title?: string) {
  NotificationService.info(
    i18n.t(title || "notification.info_title"),
    i18n.t(text.toString()),
  );
}

export const NotificationService = Object.freeze(new NotificationsProvider());
