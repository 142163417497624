export const MERGE_BUFFER_DISTANCE = 1.0;

export enum POLYGON_EDIT_MODE {
  NONE = 0,
  MERGE = 1,
  SPLIT = 2,
}

export const lineSymbol = {
  path: "M 0,-1 0,1",
  strokeOpacity: 1,
  scale: 4,
  strokeColor: "#FF0000",
};

export enum SPLITTING_ERROR {
  ENDPOINT_INSIDE = "endPointInside",
  NO_INTERSECTING_POINTS = "noIntersectingPoints",
  OPERATION_FAILED = "operationFailed",
  NOT_TWO_RESULTING_PARCELS = "notTwoResultingParcels",
  SAVING_FAILED = "savingFailed",
}

export enum MERGING_ERROR {
  ONLY_ONE_PARCEL_SELECTED = "onlyOneParcelSelected",
  NOT_CONTIGUOUS = "notContiguous",
}
