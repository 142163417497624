import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type DriversMessage } from "@ero/app-common/util/Websocket";
import { type MachineResponseBody } from "@ero/app-common/v2/routes/models/machine";
import { type ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { type UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { SelectionTypeEnum } from "Constants";
import { Coordinate, type MachinesMessage } from "Types";
import {
  DASHBOARD_ACTION_TYPES,
  IResetParcelFilters,
  ISetParcelFilters,
  type IDashboardCancelUpdate,
  type IDashboardInitialRequest,
  type IDashboardInitialSuccess,
  type IGetDrivers,
  type IRemoveDriversSubscription,
  type ISetCenter,
  type ISetDrivers,
  type ISetError,
  type ISetLoading,
  type ISetMachines,
  type ISetParcels,
  type ISetSelectionType,
  type ISetSelectionTypeSuccess,
  type ISetShowDriversTrack,
  type ISetSuccess,
  type ISetZoom,
  type IUpdateDriverLive,
  type IUpdateMachineLive,
} from "./action-types";
import { ParcelFilters } from "./reducer";

export const setSelectionType = (
  type: SelectionTypeEnum,
): ISetSelectionType => ({
  type: DASHBOARD_ACTION_TYPES.SET_SELECTION_TYPE,
  payload: { type },
});

export const setSelectionTypeSuccess = (
  type: SelectionTypeEnum,
): ISetSelectionTypeSuccess => ({
  type: DASHBOARD_ACTION_TYPES.SET_SELECTION_TYPE_SUCCESS,
  payload: { type },
});

export const dashboardInitialRequest = (): IDashboardInitialRequest => ({
  type: DASHBOARD_ACTION_TYPES.DASHBOARD_INITIAL_REQUEST,
});

export const dashboardInitialSuccess = (): IDashboardInitialSuccess => ({
  type: DASHBOARD_ACTION_TYPES.DASHBOARD_INITIAL_SUCCESS,
});

export const dashboardCancelUpdate = (): IDashboardCancelUpdate => ({
  type: DASHBOARD_ACTION_TYPES.DASHBOARD_CANCEL_UPDATE,
});

export const setLoading = (): ISetLoading => ({
  type: DASHBOARD_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccess => ({
  type: DASHBOARD_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetError => ({
  type: DASHBOARD_ACTION_TYPES.SET_ERROR,
});

export const setMachines = ({
  list,
}: {
  list: MachineResponseBody[];
  search?: SearchSpec;
}): ISetMachines => ({
  type: DASHBOARD_ACTION_TYPES.SET_MACHINES,
  payload: { list },
});

export const updateMachineLive = (
  message: MachinesMessage,
): IUpdateMachineLive => ({
  type: DASHBOARD_ACTION_TYPES.UPDATE_MACHINE_LIVE,
  payload: { message },
});

export const setParcels = ({
  list,
}: {
  list: ParcelResponseBody[];
}): ISetParcels => ({
  type: DASHBOARD_ACTION_TYPES.SET_PARCELS,
  payload: { list },
});

export const getDrivers = (): IGetDrivers => ({
  type: DASHBOARD_ACTION_TYPES.GET_DRIVERS,
});

export const setDrivers = ({
  data,
}: {
  data: UserResponseBody[];
}): ISetDrivers => ({
  type: DASHBOARD_ACTION_TYPES.SET_DRIVERS,
  payload: { data },
});

export const updateDriverLive = (
  message: DriversMessage,
): IUpdateDriverLive => ({
  type: DASHBOARD_ACTION_TYPES.UPDATE_DRIVER_LIVE,
  payload: { message },
});

export const setShowDriversTrack = (
  showDriversTrack: boolean,
): ISetShowDriversTrack => ({
  type: DASHBOARD_ACTION_TYPES.SET_SHOW_DRIVERS_TRACK,
  payload: { showDriversTrack },
});

export const removeDriversSubscription = (): IRemoveDriversSubscription => ({
  type: DASHBOARD_ACTION_TYPES.REMOVE_DRIVERS_SUBSCRIPTION,
});

export const setCenter = (center: Coordinate): ISetCenter => ({
  type: DASHBOARD_ACTION_TYPES.SET_CENTER,
  payload: { center },
});

export const setZoom = (zoom: number): ISetZoom => ({
  type: DASHBOARD_ACTION_TYPES.SET_ZOOM,
  payload: { zoom },
});

export const setParcelFilters = (
  filter?: ParcelFilters,
): ISetParcelFilters => ({
  type: DASHBOARD_ACTION_TYPES.SET_PARCEL_FILTERS,
  payload: filter,
});

export const resetParcelFilters = (): IResetParcelFilters => ({
  type: DASHBOARD_ACTION_TYPES.RESET_PARCEL_FILTERS,
});
