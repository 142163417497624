import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { VALIDATION_ERRORS } from "Constants";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const useDateRestrictionsSchema = () => {
  const [t] = useTranslation();

  const schema = useMemo(
    () =>
      Yup.object().shape(
        {
          dateMode: Yup.mixed<DateMode>()
            .oneOf(Object.values(DateMode))
            .optional()
            .when("timeMode", {
              is: (val) => val && val === DateTimeMode.WITHDAYTIME,
              then: (schema) => schema.required(VALIDATION_ERRORS.REQUIRED),
            }),
          timeMode: Yup.mixed<DateTimeMode>()
            .oneOf(Object.values(DateTimeMode))
            .optional()
            .when("dateMode", {
              is: (val) => val,
              then: (schema) => schema.required(VALIDATION_ERRORS.REQUIRED),
            }),
          date: Yup.number()
            .when(["dateMode", "timeMode"], {
              is: (dateMode, timeMode) =>
                dateMode === DateMode.FIXEDDATE &&
                timeMode === DateTimeMode.WITHDAYTIME,
              then: (schema) => schema.required(VALIDATION_ERRORS.REQUIRED),
            })
            .typeError(VALIDATION_ERRORS.DATE),
          startDate: Yup.number()
            .when("dateMode", {
              is: (val) => val && val === DateMode.DATERANGE,
              then: (schema) => schema.required(VALIDATION_ERRORS.REQUIRED),
            })
            .typeError(VALIDATION_ERRORS.DATE),
          endDate: Yup.number()
            .when("dateMode", {
              is: (val) => val && val === DateMode.DATERANGE,
              then: (schema) => schema.required(VALIDATION_ERRORS.REQUIRED),
            })
            .typeError(VALIDATION_ERRORS.DATE)
            .test(
              "date-constraints",
              t("orders.create_modal.endDateBiggerAsStartDateError"),
              (endDate, context) =>
                !((context.parent.startDate ?? 0) > (endDate ?? 0)),
            ),
          dayTime: Yup.lazy((_, { parent }) =>
            Yup.object().shape({
              startTime: Yup.number()
                .when({
                  is: () => parent.timeMode === DateTimeMode.WITHDAYTIME,
                  then: (schema) => schema.required(VALIDATION_ERRORS.REQUIRED),
                })
                .typeError(VALIDATION_ERRORS.TIME),
              endTime: Yup.number()
                .when("timeMode", {
                  is: () => parent.timeMode === DateTimeMode.WITHDAYTIME,
                  then: (schema) => schema.required(VALIDATION_ERRORS.REQUIRED),
                })
                .typeError(VALIDATION_ERRORS.TIME)
                .test(
                  "time-constraints",
                  t("orders.create_modal.endTimeBiggerAsStartTimeError"),
                  (endTime, context) =>
                    !((context.parent.startTime ?? 0) > (endTime ?? 0)),
                ),
            }),
          ),
        },
        [["dateMode", "timeMode"]],
      ),
    [t],
  );
  return schema;
};
