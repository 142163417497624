import { MachineOutside } from "@ero/app-common/models";
import { type APISpec } from "@ero/app-common/routes/enforcer";
import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/machines";
import { apiInstance, apiInstanceV2 } from "App/axios";
import { FILE_UPLOAD_TIMEOUT, GET_ALL_PARAMS } from "Constants";
import {
  ApiRequestParams,
  type ApiListRequestParams,
  type CREATE_MACHINE_DTO,
  type ImportReviewConfirmType,
  type MachineType,
  type MapColumnRequestType,
} from "Types";

export const api_machines_view = (
  body: APISpec["/machines/view"]["body"],
  query: APISpec["/machines/view"]["query"],
) => {
  return apiInstance.post("/machines/view", body, { params: query });
};

export const getMachine = ({
  id,
  spec,
}: ApiRequestParams<MachineOutside>): Promise<any> =>
  apiInstance.post("/machines/view", { _id: id, spec });

export const getMachines = ({
  params,
  search,
  filters,
  spec,
}: ApiListRequestParams<MachineType>) =>
  apiInstance.post("/machines/all", { search, filters, spec }, { params });

export const getMachinesV2 = () =>
  apiInstanceV2.get(baseUrl + SubUrls.ROOT, {
    params: { limit: 99999999 },
  });

export const getAllMachines = ({
  search,
  filters,
  spec,
}: ApiListRequestParams<MachineType> = {}) =>
  apiInstance.post(
    "/machines/all",
    { search, spec, filters },
    { params: GET_ALL_PARAMS },
  );

export const createMachine = (machine: CREATE_MACHINE_DTO): Promise<any> =>
  apiInstance.post("/machines/create", machine);

export const deleteMachines = (ids: { ids: number[] }): Promise<any> =>
  apiInstance.post("/machines/delete", ids);

export const updateMachine = (machine: Partial<MachineType>, id: number) => {
  const body = {
    ...machine,
    _id: id,
  };
  return apiInstance.post("/machines/update", body);
};

export const uploadMachinesFile = (
  data: any,
  onUploadProgress: (progressEvent) => void,
) =>
  apiInstance.post("/machines/bulk", data, {
    onUploadProgress,
    timeout: FILE_UPLOAD_TIMEOUT,
  });

export const mapNewMachineColumns = (data: MapColumnRequestType) =>
  apiInstance.post(
    "/machines/fulfill",
    {
      identifier: data.identifier,
      relationships: data.relationships,
      preview: false,
    },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );

export const mapExistedMachineColumns = (data: MapColumnRequestType) =>
  apiInstance.post(
    "/machines/overwrite",
    { identifier: data.identifier, relationships: data.relationships },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );

export const importMachinesReviewConfirm = (data: ImportReviewConfirmType) =>
  apiInstance.post(
    "/machines/overwriteCommit",
    { identifier: data.identifier, revert: data.revert },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );
