import axios, {
  InternalAxiosRequestConfig,
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";
import i18n from "i18next";

interface Store {
  getState(): {
    auth: {
      tokens: { accessToken?: string; refreshToken?: string };
    };
  };
  dispatch(action: unknown): unknown;
}

export const instanceConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
};

export const instanceConfigV2: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL_V2,
  timeout: 60000,
};

export const requestRejectError = (err: AxiosError) => Promise.reject(err);
export const createAuthInterceptor = (
  store: Store,
): ((config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig) => {
  return (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const accessToken = store.getState().auth.tokens.accessToken;
    if (accessToken && config.headers && !config.headers["Authorization"]) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  };
};

export const responseSuccessSimple = <T>(res: AxiosResponse<T>) => res;

type WasActive = null | Promise<void>;
type ErrorHandlerMaybe = (error: AxiosError) => WasActive;

export const constructErrorHandler = (
  handlerList: ErrorHandlerMaybe[],
): ((error: AxiosError | undefined | null) => Promise<void>) => {
  return (error: AxiosError | undefined | null): Promise<void> => {
    if (error === undefined || error === null) {
      return Promise.reject();
    }

    for (const handler of handlerList) {
      const result = handler(error);
      if (result === null) {
        continue;
      }

      return result;
    }

    return Promise.reject(error);
  };
};

export const handle502: ErrorHandlerMaybe = (error) => {
  if (error.response?.status !== 502) {
    return null;
  }

  return Promise.reject({
    message: i18n.t("notification.service_unavailable"),
  });
};
export const construct_handle403KeepAlive = (
  logoutAction: () => void,
): ErrorHandlerMaybe => {
  return (err: AxiosError): WasActive => {
    if (!err.config?.url?.endsWith("/persons/keep-alive")) {
      return null;
    }
    if (err.response?.status !== 403) {
      return null;
    }

    logoutAction();
    return Promise.reject(err);
  };
};
export const construct_retry401AfterKeepAlive = (
  apiInstance: AxiosInstance,
  store: Store,
  storeCB: (accessToken: string) => unknown,
  logoutAction: () => void,
): ErrorHandlerMaybe => {
  return (err: AxiosError): WasActive => {
    const req = err.config as AxiosRequestConfig & { _retry: boolean };
    if (err.response?.status !== 401 || req._retry) {
      return null;
    }

    if (err.request.responseURL.indexOf("/persons/keep-alive") != -1) {
      console.error(new Error("logged out because of invalid refresh token!"));
      logoutAction();
    }

    req._retry = true;
    const refreshToken = store.getState().auth.tokens.refreshToken;
    if (refreshToken === undefined) {
      console.error(new Error("logged out because no refreshToken found!"));
      logoutAction();
    }

    return new Promise((resolve, reject) => {
      apiInstance
        .post(`/persons/keep-alive`, {
          refreshToken,
        })
        .then(
          (response) => {
            if (response.status !== 200) {
              return reject(response);
            }

            const { accessToken } = response.data as { accessToken?: string };
            if (accessToken === undefined) {
              return reject("accesstoken from /keep-alive response undefined!");
            }
            storeCB(accessToken);

            if (!req.headers) {
              req.headers = {};
            }
            req.headers.Authorization = `Bearer ${accessToken}`;

            //not sure if the following preserves the exact functionality
            const retry = axios(req);
            resolve(retry as any);
          },
          (error) => {
            reject(error);
          },
        );
    });
  };
};
