import { CloudDownload, Edit, EditOffOutlined } from "@mui/icons-material";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Loader, OneFieldForm } from "Components";
import { AddJobModal } from "ProjectComponents";
import { type AppState } from "Store";
import {
  downloadDeliveryNote,
  getOrderDetailsInitial,
  resetState,
  updateOrder,
} from "Store/orderDetails";
import { getOrderStatusColor, getOrderStatusLabel } from "Utils";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MapCard, OverviewCard } from "./components";
import { ContactCard } from "./components/contactCard/contactCard";
import { JobsCard } from "./components/jobsCard";
import { NotesCard } from "./components/notesCard/notesCard";

export const OrderDetails: React.FC = () => {
  const dispatch = useDispatch();

  const { order, loading } = useSelector(
    (state: AppState) => state.orderDetails,
  );

  const params = useParams<{ id: string }>();
  const orderId = useMemo(() => parseInt(params.id || ""), [params.id]);

  const [isAddJobModalOpen, setIsAddJobModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getOrderDetailsInitial(orderId));

    return () => {
      dispatch(resetState());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const onDeliveryNoteClick = () => {
    dispatch(downloadDeliveryNote());
  };

  const [orderNameEditable, setOrderNameEditable] = useState(false);

  const orderNameField = useMemo(() => {
    const updateOrderName = (value: string) => {
      setOrderNameEditable(false);
      dispatch(updateOrder({ name: value }));
    };

    return (
      <Stack
        direction="row"
        spacing={2}
        height={50}
        marginBottom={4}
        alignItems="center"
      >
        <IconButton
          size="small"
          onClick={() => setOrderNameEditable(!orderNameEditable)}
        >
          {orderNameEditable ? (
            <EditOffOutlined fontSize="small" />
          ) : (
            <Edit fontSize="small" />
          )}
        </IconButton>
        {orderNameEditable ? (
          <OneFieldForm
            name="orderName"
            label="Name"
            success
            onSubmit={(value) => updateOrderName(value.orderName)}
            value={order?.name}
            size="small"
          />
        ) : (
          <Typography
            variant="h5"
            component={"span"}
            sx={{ marginLeft: "0px !important" }}
          >
            {`${order?.name}    | `}
          </Typography>
        )}
        {!orderNameEditable && (
          <Typography
            variant="h5"
            component={"span"}
            color={getOrderStatusColor(order?.status)}
          >
            {getOrderStatusLabel(order?.status)}
          </Typography>
        )}
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.name, order?.status, orderNameEditable]);

  if (loading) {
    return <Loader />;
  }

  if (isEmpty(order)) {
    return <div>No such order</div>;
  }

  return (
    <>
      <Grid container padding={3} height="100%">
        <Grid item xs={12} sm={5} md={4} lg={3} xl={2} height="100%">
          <JobsCard onAddBtnClick={() => setIsAddJobModalOpen(true)} />
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9} xl={10} pl={2}>
          <Stack direction={"column"} height="100%">
            <Grid container justifyContent={"space-between"}>
              <Grid item>{orderNameField}</Grid>
              <Grid item>
                <IconButton onClick={onDeliveryNoteClick}>
                  <CloudDownload />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems={"stretch"}>
              <Grid item xs={12} lg={6} height="100%">
                <OverviewCard />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <ContactCard />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <NotesCard />
              </Grid>
            </Grid>
            <Box height="100%" pt={2}>
              <MapCard />
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <AddJobModal
        open={isAddJobModalOpen}
        onClose={() => {
          setIsAddJobModalOpen(false);
        }}
      />
    </>
  );
};
