import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { errorToast } from "Services";
import { addDropdown, removeDropdown } from "Store/organization";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

interface IListItems {
  type: string;
  data?: { name: string; _id: number }[];
}

export const ListItems: React.FC<IListItems> = ({ type, data }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const newEntryRef = useRef<any>("");

  const handleDelete = (key: string, _id: number) =>
    dispatch(removeDropdown({ key, _id }));

  const addEntryHandler = () => {
    if (newEntryRef.current?.value.length) {
      dispatch(
        addDropdown({
          key: type,
          name: newEntryRef.current?.value,
          meta: {},
        }),
      );
      newEntryRef.current.value = "";
    } else {
      errorToast("Plase provide a name for your new entry");
    }
  };

  return (
    <>
      <Stack
        sx={{
          overflow: "auto",
          maxHeight: 250,
          pr: 4,
        }}
        spacing={2}
        mb={2}
      >
        <List>
          {data?.map((item) => (
            <ListItem
              key={item._id}
              divider
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleDelete(type, item._id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Stack>
      <Divider />
      <Box mt={2} display="flex" flexDirection="column">
        <TextField
          inputRef={newEntryRef}
          size="small"
          placeholder={t("general.buttons.newEntry") + "..."}
        />
        <LoadingButton onClick={addEntryHandler}>
          {t("general.buttons.add")}
        </LoadingButton>
      </Box>
    </>
  );
};
