import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useMachineDropdownOptions } from "Utils/machines";

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const { kindOptions } = useMachineDropdownOptions();
  const [t] = useTranslation();
  return [
    {
      field: "internalId",
      headerName: t("machines.fields.id"),
    },
    {
      field: "name",
      headerName: t("general.fields.name"),
    },
    {
      field: "kind",
      headerName: t("general.fields.type"),
      valueGetter: (params) =>
        kindOptions.find((opt) => opt.value === params.value)?.label,
    },
    {
      field: "manufacturer",
      headerName: t("machines.fields.manufacturer"),
      valueGetter: (params) =>
        params.value._id !== -1 ? params.value.name : "-",
    },
    {
      field: "licensePlate",
      headerName: t("machines.fields.license"),
    },
    {
      field: "intact",
      headerName: t("general.fields.status"),
      valueGetter: (params) =>
        params.value ? t("machines.state.active") : t("machines.state.broken"),
    },
  ];
};
