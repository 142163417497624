import { deDE, enUS } from "@mui/material/locale";
import { PaletteOptions, createTheme } from "@mui/material/styles";
import {
  deDE as deDETables,
  enUS as enUSTables,
} from "@mui/x-data-grid/locales";
import {
  deDE as deDEDatepickers,
  enUS as enUSDatepickers,
} from "@mui/x-date-pickers/locales";
import { ParcelKindColor } from "Enums";


declare module "@mui/material/styles" {
  interface Palette {
    status: {
      open: string;
      inwork: string;
      driving: string;
      pause: string;
      done: string;
      default: string;
    };
    crop: {
      white: string;
      red: string;
    };
  }

  interface PaletteColor {
    status: {
      open: string;
      inwork: string;
      driving: string;
      pause: string;
      done: string;
      default: string;
    };
    crop: {
      white: string;
      red: string;
    };
  }

  interface PaletteOptions {
    status: {
      open: string;
      inwork: string;
      driving: string;
      pause: string;
      done: string;
      default: string;
    };
    crop: {
      white: string;
      red: string;
    };
  }
}

const statusColors = {
  status: {
    open: "#e3001b",
    inwork: "#ff7639",
    driving: "#fce876",
    pause: "#b1b1b1",
    done: "#00CD98",
    default: "#fff",
  },
};

const cropColors = {
  crop: {
    white: ParcelKindColor.WHITE,
    red: ParcelKindColor.RED,
  },
};

const mainColors: Partial<PaletteOptions> = { primary: { main: "#c20e1a" } };

export const useTheme = (mode: "dark" | "light", locale?: string) =>
  createAppTheme(mode, locale);

export const createAppTheme = (
  mode: "dark" | "light" = "light",
  locale: string = "de",
) => {
  const baseLocales = locale === "de" ? deDE : enUS;
  const tablesLocales = locale === "de" ? deDETables : enUSTables;
  const datepickersLocales =
    locale === "de" ? deDEDatepickers : enUSDatepickers;

  const mergedLocales = {
    ...baseLocales,
    components: {
      ...baseLocales.components,
      ...tablesLocales.components,
      ...datepickersLocales.components,
    },
  };

  return createTheme(
    {
      palette: {
        mode,
        ...statusColors,
        ...cropColors,
        ...mainColors,
      },
    },
    mergedLocales,
  );
};
