import { Edit } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AppState } from "Store";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { EditNoteModal } from "./editNoteModal";

export const NotesCard: React.FC = () => {
  const [t] = useTranslation();

  const { order } = useSelector((state: AppState) => state.orderDetails);

  const ref = useRef<HTMLDivElement>(null);

  const [isEditNoteModalOpen, setIsEditNoteModalOpen] =
    useState<boolean>(false);
  const [isHoverEnabled, setIsHoverEnabled] = useState(false);

  useEffect(() => {
    if ((order?.notes?.length ?? 0) > 150) {
      setIsHoverEnabled(true);
    } else {
      setIsHoverEnabled(false);
    }
  }, [order?.notes]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="h6">{t("general.fields.notes")}</Typography>
            <Tooltip
              title={order?.notes}
              disableHoverListener={!isHoverEnabled}
            >
              <Typography
                ref={ref}
                color="text.secondary"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "5",
                  WebkitBoxOrient: "vertical",
                }}
              >
                {order?.notes || "-"}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => setIsEditNoteModalOpen(true)}>
              <Edit fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <EditNoteModal
          open={isEditNoteModalOpen}
          onClose={() => setIsEditNoteModalOpen(false)}
        />
      </CardContent>
    </Card>
  );
};
