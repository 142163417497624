import {
  UnitSymbol,
  convertAreaToCustom,
} from "@ero/app-common/util/convertArea";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Menu, PhotoSizeSelectSmall, Spa } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { AppState } from "Store";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface IOverviewCard {
  job?: JobResponseBody;
}

export const OverviewCard: React.FC<IOverviewCard> = ({ job }) => {
  const [t] = useTranslation();

  const unitOfMeasurement = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{job?.parcel.name}</Typography>
        <List>
          <ListItem disablePadding>
            <ListItemIcon>
              <PhotoSizeSelectSmall />
            </ListItemIcon>
            <ListItemText
              primary={
                (job?.parcel.size
                  ? convertAreaToCustom(job?.parcel.size, unitOfMeasurement)
                  : "-") +
                " " +
                UnitSymbol[unitOfMeasurement]
              }
              secondary={t("general.fields.area")}
            />
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemIcon>
              <Spa />
            </ListItemIcon>
            <ListItemText
              primary={job?.parcel.crop.name ?? "-"}
              secondary={t("general.fields.grape_variety")}
            />
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemIcon>
              <Menu />
            </ListItemIcon>
            <ListItemText
              primary={job?.parcel.rowAmount ?? "-"}
              secondary={t("general.fields.rows_amount")}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};
