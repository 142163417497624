import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";

import React from "react";
import { useTranslation } from "react-i18next";

export const ImportSuccess: React.FC = () => {
  const [t] = useTranslation();

  const theme = useTheme();

  return (
    <Box>
      <Box textAlign={"center"} display="flex" flexDirection={"column"}>
        <>
          <Box>
            <CheckCircleOutlineOutlined
              sx={{
                color: theme.palette.success.main,
                fontSize: "75px",
              }}
            />
          </Box>
          <Box>
            <Typography paddingTop="20px" variant="h4">
              {t("parcels.upload.import_success.title")}
            </Typography>
            <Typography paddingY="20px" variant="body1">
              {t("parcels.upload.import_success.body")}
            </Typography>
          </Box>
        </>
      </Box>
    </Box>
  );
};
