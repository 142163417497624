import { TimeSlotOutside } from "@ero/app-common/models";
import { UsersRequestQuery } from "@ero/app-common/v2/routes/models/user";
import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/users";
import { apiInstance, apiInstanceV2 } from "App/axios";
import { GET_ALL_PARAMS } from "Constants";
import {
  RequestBody,
  type ApiListRequestParams,
  type CalendarDateRange,
} from "Types";

export const getEmployees = ({
  params,
  search,
  filters,
  spec,
  permission,
}: ApiListRequestParams<TimeSlotOutside> & { permission?: string }) =>
  apiInstance.post(
    "/employees/all",
    { search, filters, spec },
    {
      params: { ...params, permission },
    },
  );

export const getEmployeesV2 = async (params: UsersRequestQuery) =>
  (
    await apiInstanceV2.get(baseUrl + SubUrls.ROOT, {
      params,
    })
  ).data;

export const getAllEmployees = ({
  permission,
  search,
  spec,
}: ApiListRequestParams<TimeSlotOutside> & { permission?: string }) =>
  apiInstance.post(
    "/employees/all",
    { search, spec },
    {
      params: { ...GET_ALL_PARAMS, permission },
    },
  );

export const createEmployee = (employee: RequestBody<"/persons/invite">) =>
  apiInstance.post("/persons/invite", employee);

export const deleteEmployees = (ids: { ids: number[] }) =>
  apiInstance.post("/persons/delete", ids);

export const updateEmployee = (employee: Record<string, string>, id: number) =>
  apiInstance.post("/persons/update", employee, { params: { id } });

export const addEmployeeFile = (body) =>
  apiInstance.post("/persons/appendFile", body);

export const removeEmployeeFile = (body) =>
  apiInstance.post("/persons/removeFile", body);

export const changeEmployeePassword = (id: number, password: string) =>
  apiInstance.post(
    "/persons/giveNewPassword",
    { password },
    { params: { id } },
  );

export const getEmployeesTimeslots = (
  body: { job?: number; before?: number; after?: number } = {},
  spec,
) =>
  apiInstance.post(
    "/employees/allTimeSlots",
    { ...body, spec },
    { params: GET_ALL_PARAMS },
  );

export const updateEmployeeTimeslot = (
  id: number,
  dateRange: CalendarDateRange,
) => apiInstance.post("/employees/updateTimeSlot", { _id: id, ...dateRange });

export const printEmployeeCalendar = (
  employee: number,
  start: number,
  end: number,
) =>
  apiInstance.post("/employees/exportAppointments", {
    employee,
    start,
    end,
    timeZoneOffset: new Date().getTimezoneOffset(),
  });
