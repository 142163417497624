import { ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Box } from "@mui/material";
import { MarkerClusterer } from "@react-google-maps/api";
import {
  FullScreenControlV2,
  MapParcel,
  MapV2,
  ZoomControlV2,
} from "ProjectComponents/map";
import {
  BottomControls,
  TopControls,
} from "ProjectComponents/map/components/controls";
import { AppState } from "Store";
import { useField } from "formik";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IContentProps } from "./types";

const PARCEL_CLUSTER_MAXZOOM = 15;

const DEFAULT_ZOOM = 12;

export const ParcelSelectionMap: React.FC<
  IContentProps & { name: string; parcels: ParcelResponseBody[] }
> = ({ name, parcels }) => {
  const map = useRef<google.maps.Map>();

  const [field, _, helpers] = useField<string | number>(name);

  const { companyData } = useSelector((store: AppState) => store.auth);

  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    map.current = mapInstance;
  }, []);

  const onZoomChanged = useCallback(
    (zoom?: number) => {
      let currentZoom = zoom;
      if (!currentZoom) {
        currentZoom = map.current?.getZoom() ?? DEFAULT_ZOOM;
      } else {
        map.current?.setZoom(currentZoom);
      }
      setZoom(currentZoom);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [map],
  );

  const showPolygon = useMemo(() => {
    const showPolygon = zoom >= PARCEL_CLUSTER_MAXZOOM;
    return showPolygon;
  }, [zoom]);

  const parcelOnClick = useCallback(
    (id: number) => {
      helpers.setValue(id);
    },
    [helpers],
  );

  return (
    <Box height={500}>
      <MapV2
        id="add-job-map"
        center={companyData.machinePosition}
        zoom={zoom}
        onLoad={onLoad}
        onZoomChanged={onZoomChanged}
      >
        <MarkerClusterer
          options={{
            maxZoom: PARCEL_CLUSTER_MAXZOOM,
            averageCenter: true,
          }}
        >
          {(clusterer) => (
            <>
              {parcels.map((parcel) => (
                <MapParcel
                  key={parcel._id}
                  parcel={parcel}
                  showPolygon={showPolygon}
                  markerProps={{ clusterer }}
                  fillColor={field.value === parcel._id ? "#8efbcc" : "#fff"}
                  itemOnClick={parcelOnClick}
                />
              ))}
            </>
          )}
        </MarkerClusterer>
        <TopControls>
          <FullScreenControlV2 mapRef={map} />
        </TopControls>
        <BottomControls>
          <ZoomControlV2 zoom={zoom} onZoomChanged={onZoomChanged} />
        </BottomControls>
      </MapV2>
    </Box>
  );
};
