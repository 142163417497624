import Slider from "@mui/material/Slider";
import React, { useMemo } from "react";

const marks = [
  { value: 25, label: "25%" },
  { value: 50, label: "50%" },
  { value: 75, label: "75%" },
  { value: 100, label: "100%" },
];

interface IRowScale {
  showRowScale?: boolean;
  rowScaleValue?: number;
  onRowScaleChange?: (scale: number) => void;
}

export const RowScale: React.FC<IRowScale> = ({
  showRowScale,
  rowScaleValue = 25,
  onRowScaleChange,
}) => {
  // slider is uncontrolled and value is used only to initialize it
  const defaultValue = useMemo(() => rowScaleValue, [rowScaleValue]);

  const onChangeHandler = (_, value: number | number[]) => {
    onRowScaleChange && onRowScaleChange(value as number);
  };

  if (!showRowScale) {
    return null;
  }

  return (
    <Slider
      defaultValue={defaultValue}
      marks={marks}
      valueLabelDisplay="off"
      step={null}
      track={false}
      min={25}
      onChangeCommitted={onChangeHandler}
      size="small"
    />
  );
};
