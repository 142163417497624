import { InfoWindow, type InfoWindowProps } from "@react-google-maps/api";
import React, { forwardRef } from "react";

interface IInfoWindowV2 extends InfoWindowProps {}

const InfoWindowV2 = forwardRef<InfoWindow, IInfoWindowV2>((props, ref) => {
  return (
    <InfoWindow {...props} ref={ref}>
      {props.children}
    </InfoWindow>
  );
});

InfoWindowV2.displayName = "InfoWindowV2";

export { InfoWindowV2 };
