import { AddCircleTwoTone } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface INoOrdersPlaceholder {
  createOrder: () => void;
}

export const NoOrdersPlaceholder: React.FC<INoOrdersPlaceholder> = ({
  createOrder,
}) => {
  const [t] = useTranslation();

  return (
    <Box mt={4} textAlign="center">
      <IconButton color="primary" size="large" onClick={createOrder}>
        <AddCircleTwoTone fontSize="large" color="primary" />
      </IconButton>
      <Typography variant="h6">
        {t("dashboard.sidebar.no_orders_title")}
      </Typography>
      <Typography variant="body1">
        {t("dashboard.sidebar.no_orders_text")}
      </Typography>
    </Box>
  );
};
