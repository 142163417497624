import { ServiceOutside } from "@ero/app-common/models";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import {
  RequestBody,
  type CREATE_SERVICE_DTO,
  type FileToUpload,
  type GetTableDataParams,
  type GetTableEntitiesType,
  type SetTableEntityType,
} from "Types";
import {
  SERVICES_ACTION_TYPES,
  type IChangeColumnsOrder,
  type ICreateServiceAction,
  type IDeleteServicesAction,
  type IGetExampleFileRequestAction,
  type IGetInitialServicesErrorAction,
  type IGetInitialServicesRequestAction,
  type IGetInitialServicesSuccessAction,
  type IGetServicesRequestAction,
  type IGetServicesSuccessAction,
  type IResetMetaAction,
  type IResetSearchAction,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type ISetTableHiddenColumns,
  type IUpdateServiceAction,
} from "./action-types";

export const setLoading = (): ISetLoadingAction => ({
  type: SERVICES_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: SERVICES_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: SERVICES_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: SERVICES_ACTION_TYPES.RESET_META,
});

export const resetSearch = (): IResetSearchAction => ({
  type: SERVICES_ACTION_TYPES.RESET_SEARCH,
});

export const getServicesInitial = (
  params: GetTableDataParams,
): IGetInitialServicesRequestAction => {
  return {
    type: SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_REQUEST,
    payload: {
      params,
    },
  };
};

export const getServicesInitialSuccess = (
  services: ServiceOutside[],
  maxCount: number,
): IGetInitialServicesSuccessAction => ({
  type: SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_SUCCESS,
  payload: {
    services,
    maxCount,
  },
});

export const getServicesInitialError = (): IGetInitialServicesErrorAction => ({
  type: SERVICES_ACTION_TYPES.GET_INITIAL_SERVICES_ERROR,
});

export const createService = (
  data: CREATE_SERVICE_DTO,
): ICreateServiceAction => ({
  type: SERVICES_ACTION_TYPES.CREATE_SERVICE,
  payload: { data },
});

export const updateService = (
  updateData: RequestBody<"/services/update">,
  id: number,
  notificationData?: { text: string; title: string },
  filesData?: {
    removedFiles: string[];
    addedFiles: FileToUpload[];
  },
): IUpdateServiceAction => ({
  type: SERVICES_ACTION_TYPES.UPDATE_SERVICE,
  payload: { updateData, id, notificationData, filesData },
});

export const deleteServices = (ids: number[]): IDeleteServicesAction => ({
  type: SERVICES_ACTION_TYPES.DELETE_SERVICES,
  payload: { ids },
});

export const getServices = ({
  params,
  withSuccess,
  search,
  filters,
}: GetTableEntitiesType): IGetServicesRequestAction => ({
  type: SERVICES_ACTION_TYPES.GET_SERVICES_REQUEST,
  payload: { params, withSuccess, search, filters },
});

export const getServicesSuccess = ({
  list,
  maxCount,
  listMeta,
  search,
  filters,
}: SetTableEntityType<ServiceOutside>): IGetServicesSuccessAction => ({
  type: SERVICES_ACTION_TYPES.GET_SERVICES_SUCCESS,
  payload: { services: list, maxCount, listMeta, search, filters },
});

export const getExampleFile = (): IGetExampleFileRequestAction => ({
  type: SERVICES_ACTION_TYPES.GET_EXAMPLE_FILE_REQUEST,
  payload: {
    table: "services",
  },
});

export const setTableHiddenColumns = (
  tableId: string,
  hiddenColumns: GridColumnVisibilityModel,
): ISetTableHiddenColumns => ({
  type: SERVICES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS,
  payload: { tableId, hiddenColumns },
});

export const changeColumnsOrder = (order: number[]): IChangeColumnsOrder => ({
  type: SERVICES_ACTION_TYPES.CHANGE_COLUMNS_ORDER,
  payload: { order },
});
