import { Polyline, type PolylineProps } from "@react-google-maps/api";
import React, { forwardRef } from "react";

interface ITrackV2 extends PolylineProps {}

const TrackV2 = forwardRef<Polyline, ITrackV2>((props, ref) => {
  return (
    <Polyline
      {...props}
      ref={ref}
      options={{
        ...props.options,
        icons: [
          ...(props.options?.icons ?? []),
          {
            icon: {
              path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            },
            repeat: "100px",
          },
        ],
      }}
    />
  );
});

TrackV2.displayName = "TrackV2";

export { TrackV2 };
