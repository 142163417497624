import {
  AddJobRequestBody,
  JobResponseBody,
  type JobTelemetryResponseBody,
  type JobTelemetryUpdateRequest,
} from "@ero/app-common/v2/routes/models/job";
import {
  type OrderResponseBody,
  type UpdateOrderRequestBody,
} from "@ero/app-common/v2/routes/models/order";
import {
  IDeleteJob,
  ISetJobTelemetry,
  IUpdateJobNotesSuccess,
  ORDER_DETAILS_ACTION_TYPES,
  type IAddJob,
  type IDownloadDeliveryNoteAction,
  type IGetInitialOrderDetailsErrorAction,
  type IGetInitialOrderDetailsRequestAction,
  type IGetInitialOrderDetailsSuccessAction,
  type IGetJobTelemetry,
  type IResetMetaAction,
  type IResetStateAction,
  type ISetErrorAction,
  type ISetJobTelemetryError,
  type ISetJobTelemetryLoading,
  type ISetJobTelemetrySuccess,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type IUpdateJobNotes,
  type IUpdateJobNotesError,
  type IUpdateJobTelemetry,
  type IUpdateOrderRequestAction,
  type IUpdateOrderSuccessAction,
} from "./action-types";

export const setLoading = (): ISetLoadingAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.RESET_META,
});

export const resetState = (): IResetStateAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.RESET_STATE,
});

export const getOrderDetailsInitial = (
  id: number,
  jobsSearchData?: any,
): IGetInitialOrderDetailsRequestAction => {
  return {
    type: ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_REQUEST,
    payload: {
      id,
      jobsSearchData,
    },
  };
};

export const getOrderDetailsInitialSuccess = (
  order: OrderResponseBody,
): IGetInitialOrderDetailsSuccessAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_SUCCESS,
  payload: { order },
});

export const getOrderDetailsInitialError =
  (): IGetInitialOrderDetailsErrorAction => ({
    type: ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_ERROR,
  });

export const updateOrder = (
  payload: UpdateOrderRequestBody,
): IUpdateOrderRequestAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_ORDER_REQUEST,
  payload,
});

export const updateOrderSuccess = (
  order: OrderResponseBody,
): IUpdateOrderSuccessAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_ORDER_SUCCESS,
  payload: { order },
});

export const downloadDeliveryNote = (): IDownloadDeliveryNoteAction => ({
  type: ORDER_DETAILS_ACTION_TYPES.DOWNLOAD_DELIVERY_NOTE,
});

export const createJob = (data: AddJobRequestBody): IAddJob => ({
  type: ORDER_DETAILS_ACTION_TYPES.ADD_JOB,
  payload: data,
});

export const deleteJob = (id: number): IDeleteJob => ({
  type: ORDER_DETAILS_ACTION_TYPES.DELETE_JOB,
  payload: { id },
});

export const updateJobNotes = (data: {
  notes: string;
  _id: number;
}): IUpdateJobNotes => ({
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_NOTES,
  payload: data,
});

export const updateJobNotesSuccess = (
  data: JobResponseBody,
): IUpdateJobNotesSuccess => ({
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_NOTES_SUCCESS,
  payload: data,
});

export const updateJobNotesError = (
): IUpdateJobNotesError => ({
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_NOTES_ERROR
});

export const getJobTelemtry = (id: number): IGetJobTelemetry => ({
  type: ORDER_DETAILS_ACTION_TYPES.GET_JOB_TELEMETRY,
  payload: { id },
});

export const setJobTelemtry = (
  data: JobTelemetryResponseBody,
): ISetJobTelemetry => ({
  type: ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY,
  payload: { data },
});

export const updateJobTelemetry = (
  id: number,
  telemetry: JobTelemetryUpdateRequest,
): IUpdateJobTelemetry => ({
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_TELEMETRY,
  payload: { id, telemetry },
});

export const setJobTelemtryLoading = (): ISetJobTelemetryLoading => ({
  type: ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_LOADING,
});

export const setJobTelemtrySuccess = (): ISetJobTelemetrySuccess => ({
  type: ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_SUCCESS,
});

export const setJobTelemtryError = (): ISetJobTelemetryError => ({
  type: ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_ERROR,
});
