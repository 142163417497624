import { JOBSTATUS } from "@ero/app-common/enums";

export const jobStatusFillColor: Record<JOBSTATUS | -1, string> = {
  [JOBSTATUS.DONE]: "#8efbcc",
  [JOBSTATUS.IN_WORK]: "#ea7b48",
  [JOBSTATUS.DRIVING_TO_LOCATION]: "#fce876",
  [JOBSTATUS.PAUSE]: "#b1b1b1",
  [JOBSTATUS.OPEN]: "#d12d2a",
  [-1]: "#fff",
};
