import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { StaticMultiSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticMultiSelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { AppState } from "Store";
import { Form, FormikProps } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFilterOptions } from "./filterOptions";
import { ValuesType } from "./formConfig";

export const FilterForm: React.FC<FormikProps<ValuesType>> = ({
  setFieldValue,
  values,
  dirty,
  isValid,
  setValues,
}) => {
  const [t] = useTranslation();

  const { loading, filters, orders } = useSelector(
    (state: AppState) => state.planning,
  );

  const options = useFilterOptions(orders);

  useEffect(() => {
    setValues({
      customer: filters.customer || undefined,
      service: filters.service || undefined,
      crop: filters.crop || undefined,
      cropColor: filters.cropColor || "",
      regionette: filters.regionette || undefined,
      mark: filters.mark || undefined,
      useOrderDateRange: filters.ordersDateRange ? true : false,
    });
  }, [
    filters.crop,
    filters.cropColor,
    filters.customer,
    filters.regionette,
    filters.mark,
    filters.ordersDateRange,
    filters.service,
    setValues,
  ]);

  return (
    <Form>
      <Grid
        container
        justifyContent={"center"}
        spacing={1.5}
        paddingX={1}
        paddingY={1.5}
      >
        <>
          <Grid item xs={12}>
            <StaticMultiSelectWithAutoComplete
              name="customer"
              options={options.customers}
              label={t("general.fields.customer")}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <StaticMultiSelectWithAutoComplete
              name="service"
              options={options.services}
              label={t("general.labels.service")}
              valueSource="label"
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <StaticMultiSelectWithAutoComplete
              name="crop"
              options={options.crops}
              label={t("orders.fields.crop")}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <StaticSelectWithAutoComplete
              name="cropColor"
              options={options.cropColors}
              label={t("general.labels.grapeColor")}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <StaticMultiSelectWithAutoComplete
              name="regionette"
              options={options.regionettes}
              label={t("orders.fields.regionette")}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <StaticMultiSelectWithAutoComplete
              name="mark"
              options={options.marks}
              label={t("orders.fields.mark")}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Tooltip title={t("orders.labels.timeFrameTooltip") as string}>
                  <Switch
                    checked={values.useOrderDateRange}
                    onChange={(e) => {
                      setFieldValue("useOrderDateRange", e.target.checked);
                    }}
                    color="primary"
                  />
                </Tooltip>
              }
              label={
                <Typography variant="subtitle2">
                  {t("orders.labels.orderExecutionTimeFrame")}
                </Typography>
              }
            />
          </Grid>
        </>
        <Grid item>
          <Button
            type="reset"
            variant="text"
            size="small"
            disabled={(loading || !dirty) && Object.keys(filters).length === 0}
          >
            {t("general.buttons.reset")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="small"
            disabled={loading || !isValid}
          >
            {t("general.buttons.apply")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
