import { type ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { getParcelColor } from "@ero/app-common/v2/utils/ParcelColor";
import { MarkerClusterer } from "@react-google-maps/api";
import { MapParcel } from "ProjectComponents/map/components";
import React, { useMemo } from "react";

const PARCEL_CLUSTER_MAXZOOM = 16;

interface IParcels {
  zoom?: number;
  parcels: ParcelResponseBody[];
  itemOnClick?: (id: number) => void;
}

export const Parcels: React.FC<IParcels> = ({ zoom, parcels, itemOnClick }) => {
  const showPolygon = useMemo(
    () => (zoom ? zoom > PARCEL_CLUSTER_MAXZOOM : false),
    [zoom],
  );

  const parcelFigures = useMemo(
    () => (
      <MarkerClusterer
        options={{
          maxZoom: PARCEL_CLUSTER_MAXZOOM,
          averageCenter: true,
        }}
      >
        {(clusterer) => (
          <>
            {parcels.map((parcel) => (
              <MapParcel
                key={parcel._id}
                showPolygon={showPolygon}
                parcel={parcel}
                itemOnClick={itemOnClick}
                markerProps={{ clusterer }}
                fillColor={getParcelColor(parcel.status)}
              />
            ))}
          </>
        )}
      </MarkerClusterer>
    ),
    [parcels, showPolygon, itemOnClick],
  );

  return <>{parcelFigures}</>;
};
