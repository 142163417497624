export const mapStyles = [
  { featureType: "poi", stylers: [{ visibility: "off" }] },
  { featureType: "transit", stylers: [{ visibility: "off" }] },
];

export const defaultCenterCoordinates = {
  lat: 51.163375,
  lng: 10.447683,
};

export const getMachineIconOptions = (
  icon: string,
  size = { width: 120, height: 120 },
): google.maps.Icon => ({
  url: icon,
  scaledSize: new google.maps.Size(size.width, size.height),
  anchor: new google.maps.Point(size.width / 2, size.height / 2),
});

export const fieldPolygonOptions = {
  fillColor: "#72FF83",
  fillOpacity: 0.5,
  strokeColor: "#72FF83",
  strokeWeight: 1,
};

export const parcelPolygonOptions = {
  fillColor: "#ffa500",
  fillOpacity: 0.5,
  strokeColor: "#ffa500",
  strokeWeight: 1,
};

export const filterShapeOptions = {
  editable: true,
  draggable: false,
  strokeColor: "#202020",
  strokeWeight: 4,
  fillColor: "#404040",
  fillOpacity: 0.2,
};
