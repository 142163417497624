import { ParcelOutside } from "@ero/app-common/models";
import { MachineResponseBody } from "@ero/app-common/v2/routes/models/machine";
import { ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { SelectionTypeEnum } from "Constants";
import useDidMountEffect from "Hooks/useDidMountEffect";
import {
  getDrivers,
  removeDriversSubscription,
  setSelectionType as setSelectionTypeState,
  setShowDriversTrack,
} from "Store/dashboard";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

export const useSelection = (
  selectionTypeState: SelectionTypeEnum,
  machinesList: MachineResponseBody[],
  parcelsList: ParcelResponseBody[],
  setShowInfoWindow: (showInfoWindow: boolean) => void,
) => {
  const dispatch = useDispatch();

  const [selectedId, setSelectedId] = useState<number | undefined>();
  const [selectionType, setSelectionType] =
    useState<SelectionTypeEnum>(selectionTypeState);

  const machinesListRef = useRef<HTMLDivElement[]>([]);
  const parcelsListRef = useRef<HTMLDivElement[]>([]);

  const filteredList = useMemo(() => {
    return parcelsList.filter((item) => item.shape?.length || item.position);
  }, [parcelsList]);

  useDidMountEffect(() => {
    if (
      selectionType === SelectionTypeEnum.Parcels &&
      selectedId &&
      !filteredList.find((item) => item._id === selectedId)
    ) {
      selectParcel(undefined);
    }

    return () => {
      selectParcel(undefined);
    };
  }, [filteredList]);

  useDidMountEffect(() => {
    if (
      selectionType === SelectionTypeEnum.Machines &&
      selectedId &&
      !machinesList.find((item) => item._id === selectedId)
    ) {
      selectMachine(undefined);
    }

    return () => {
      selectMachine(undefined);
    };
  }, [machinesList]);

  const selectMachine = useCallback(
    (id?: number) => {
      setSelectionType(SelectionTypeEnum.Machines);
      dispatch(setSelectionTypeState(SelectionTypeEnum.Machines));
      setSelectedId(id);
      if (id) {
        machinesListRef.current[id]?.scrollIntoView();
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const machineItemOnClick = useCallback(
    (id: number): void => {
      selectMachine(id === selectedId ? undefined : id);
      setShowInfoWindow(true);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedId, selectMachine, setShowInfoWindow],
  );

  const selectParcel = useCallback(
    (id?: number) => {
      setSelectionType(SelectionTypeEnum.Parcels);
      dispatch(setSelectionTypeState(SelectionTypeEnum.Parcels));
      setSelectedId(id);
      if (id) {
        parcelsListRef.current[id]?.scrollIntoView();
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const parcelItemOnClick = useCallback(
    (id: number) => {
      selectParcel(id === selectedId ? undefined : id);
      setShowInfoWindow(true);
    },
    [selectParcel, selectedId, setShowInfoWindow],
  );

  const handleOnParcelSelect = useCallback(
    (parcel: Partial<ParcelOutside>) => {
      selectParcel(parcel._id);
    },
    [selectParcel],
  );

  const driversTrackBtnOnClick = useCallback((show: boolean) => {
    dispatch(setShowDriversTrack(show));
    if (show === true) {
      dispatch(getDrivers());
    } else {
      dispatch(removeDriversSubscription());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedId,
    setSelectedId,
    selectionType,
    selectMachine,
    selectParcel,
    machineItemOnClick,
    parcelItemOnClick,
    handleOnParcelSelect,
    machinesListRef,
    parcelsListRef,
    driversTrackBtnOnClick,
  };
};
