import { LAYOUTS } from "Constants";
import { PrivateLayout, PublicLayout } from "Layouts";
import { ErrorElement } from "ProjectComponents";
import { type AppState } from "Store";
import i18next from "i18next";
import React, { PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import routesConfig from "./routesConfig";

const Layout: React.FC<
  PropsWithChildren<{
    layout: LAYOUTS | undefined;
  }>
> = ({ layout, children }) => {
  switch (layout) {
    case LAYOUTS.PRIVATE:
      return <PrivateLayout>{children}</PrivateLayout>;

    case LAYOUTS.PUBLIC:
      return <PublicLayout>{children}</PublicLayout>;

    default:
      return <>{children}</>;
  }
};

export const Routes: React.FC = () => {
  const { selectedLang } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (selectedLang) {
      i18next.changeLanguage(selectedLang);
    }
  }, [selectedLang]);

  const router = createBrowserRouter(
    routesConfig.map((route): RouteObject => {
      return {
        path: route.path,
        element: (
          <Layout layout={route.layout}>
            <route.component key={route.path} />
          </Layout>
        ),
        errorElement: <ErrorElement />,
      };
    }),
  );

  return <RouterProvider router={router} />;
};
