import { Reducer } from "@reduxjs/toolkit";
import { getTablePersistConfig } from "Utils";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AuthStateType, authReducer } from "./auth";
import { customerEmployeesReducer } from "./customerPersons";
import { customersReducer } from "./customers";
import { DashboardStateType, dashboardReducer } from "./dashboard";
import { employeesReducer } from "./employees";
import { externalAccessReducer } from "./externalAccess";
import { helpReducer } from "./help";
import { machinesReducer } from "./machines";
import { OrderDetailsStateType, orderDetailsReducer } from "./orderDetails";
import { OrdersStateType, ordersReducer } from "./orders";
import { organizationReducer } from "./organization/reducer";
import { ParcelsStateType, parcelsReducer } from "./parcels";
import { PlanningStateType, planningReducer } from "./planning";
import { plotsReducer } from "./plots";
import { releaseNotesReducer } from "./releaseNotes";
import { ServicesStateType, servicesReducer } from "./services";

export const reducer = {
  auth: persistReducer(
    {
      key: "auth",
      storage,
      whitelist: [
        "userData",
        "companyData",
        "tokens",
        "selectedLang",
        "colorMode",
        "sidebarOpen",
      ],
    },
    authReducer as Reducer<AuthStateType>,
  ),
  machines: persistReducer(
    getTablePersistConfig("machines"),
    machinesReducer as Reducer,
  ),
  employees: persistReducer(
    getTablePersistConfig("employees", ["isHiddenColumnsSet"]),
    employeesReducer as Reducer,
  ),
  organization: persistReducer(
    getTablePersistConfig("fieldParcels"),
    organizationReducer as Reducer,
  ),
  parcels: persistReducer(
    getTablePersistConfig("parcels", ["isHiddenColumnsSet"]),
    parcelsReducer as Reducer<ParcelsStateType>,
  ),
  orders: persistReducer(
    getTablePersistConfig("orders", [
      "selectCustomer",
      "selectParcel",
      "selectService",
    ]),
    ordersReducer as Reducer<OrdersStateType>,
  ),
  orderDetails: orderDetailsReducer as Reducer<OrderDetailsStateType>,
  customers: persistReducer(
    getTablePersistConfig("customers", ["isHiddenColumnsSet"]),
    customersReducer as Reducer,
  ),
  customerEmployees: persistReducer(
    getTablePersistConfig("customerEmployees"),
    customerEmployeesReducer as Reducer,
  ),
  services: persistReducer(
    getTablePersistConfig("services"),
    servicesReducer as Reducer<ServicesStateType>,
  ),
  dashboard: persistReducer(
    {
      key: "dashboard",
      storage,
      whitelist: ["selectionType", "showDriversTrack"],
    },
    dashboardReducer as Reducer<DashboardStateType>,
  ),
  planning: persistReducer(
    {
      key: "planning",
      storage,
      whitelist: [
        "gridResourceConfig",
        "lineResourceConfig",
        "calendarAlign",
        "eventColor",
        "showMoreEventInfo",
        "rowScale",
        "calendarCurrentView",
        "selectedEmployees",
      ],
    },
    planningReducer as Reducer<PlanningStateType>,
  ),
  help: helpReducer as Reducer,
  plots: persistReducer(
    getTablePersistConfig("plots"),
    plotsReducer as Reducer,
  ),
  releaseNotes: persistReducer(
    getTablePersistConfig("releaseNotes"),
    releaseNotesReducer as Reducer,
  ),
  externalAccess: externalAccessReducer as Reducer,
};
