import { IMPORT_SOURCE_RLP } from "@ero/app-common/enums/parcelImport";
import { AttachFile, Help } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { FileInput } from "Components/controls/fileInput";
import { t } from "i18next";
import { YouTubeModal } from "ProjectComponents/youtubeModal/YouTubeModal";
import React, { useCallback, useState } from "react";

export const RLPInput: React.FC<{
  importSource: IMPORT_SOURCE_RLP;
  trimFilenames: boolean;
  disabled?: boolean;
}> = ({ importSource, trimFilenames, disabled }) => {
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);

  const openDownloadDialog = useCallback(() => setShowDownloadDialog(true), []);
  const closeDownloadDialog = useCallback(
    () => setShowDownloadDialog(false),
    [],
  );

  return (
    <>
      {importSource === IMPORT_SOURCE_RLP.LEA && (
        <FileInput
          name="schlagDaten"
          disabled={disabled}
          label={t("parcels.upload.RLP.shape")}
          buttonlabel={t("parcels.upload.chooseFile")}
          accept={[".zip"]}
          starticon={<AttachFile />}
          trimfilenames={trimFilenames}
        />
      )}
      {importSource === IMPORT_SOURCE_RLP.FLORLP && (
        <>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ mb: 2 }}
            justifyContent="start"
          >
            <Typography variant="caption" component="a">
              {t("parcels.upload.RLP.videoHelpText")}
            </Typography>
            <Tooltip title={t("parcels.upload.RLP.videoButton")}>
              <IconButton
                color="info"
                onClick={openDownloadDialog}
                sx={{ mb: 1 }}
              >
                <Help />
              </IconButton>
            </Tooltip>
            <YouTubeModal
              open={showDownloadDialog}
              onClose={closeDownloadDialog}
              videoUrl="https://www.youtube-nocookie.com/embed/03g7UTH60bw?si=CrktBdGv_Wahxj7C"
            />
          </Stack>
          <FileInput
            name="flurstuecksDaten"
            disabled={disabled}
            label={t("parcels.upload.RLP.flurstueck")}
            buttonlabel={t("parcels.upload.chooseFile")}
            accept={[".csv", ".zip"]}
            starticon={<AttachFile />}
            trimfilenames={trimFilenames}
          />
          <FileInput
            name="schlagDaten"
            disabled={disabled}
            label={t("parcels.upload.RLP.schlag")}
            buttonlabel={t("parcels.upload.chooseFile")}
            accept={[".zip"]}
            starticon={<AttachFile />}
            trimfilenames={trimFilenames}
          />
        </>
      )}
    </>
  );
};
