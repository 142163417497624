import { type CalendarProps } from "../types";

export const useDragStart = (
  props: CalendarProps,
  {
    setIsDragging,
  }: {
    setIsDragging: (value: boolean) => void;
  },
) => {
  const { onEventDragStart } = props;

  const onDragStartHandler = () => {
    setIsDragging(true);
    onEventDragStart && onEventDragStart();
  };

  return onDragStartHandler;
};
