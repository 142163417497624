import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { OptionType } from "Types";
import React, { useCallback, useMemo } from "react";

type Props = GridRenderEditCellParams & {
  cropOptions: OptionType[];
};

export default function EditAutocomplete(props: Props) {
  const { id, value, field, api, cropOptions } = props;

  const renderOption = useCallback(
    (props, option: OptionType) => (
      <li {...props} key={option.value}>
        {option.label}
      </li>
    ),
    [],
  );

  const handleChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, newValue: OptionType | null) => {
      if (newValue) {
        api.setEditCellValue({
          id,
          field,
          value: newValue.value,
        });
      } else {
        api.setEditCellValue({ id, field, value: -1 });
      }
    },
    [api, field, id],
  );

  const autocompleteValue: OptionType | undefined = useMemo(() => {
    const selectedOption = cropOptions.find((option) => option.value === value);
    return selectedOption ?? undefined;
  }, [value, cropOptions]);

  return (
    <Autocomplete
      value={autocompleteValue}
      onChange={handleChange}
      options={cropOptions}
      renderInput={(params) => <TextField {...params} />}
      renderOption={renderOption}
      componentsProps={{ popper: { style: { width: "fit-content" } } }}
      autoHighlight
      fullWidth
      disableClearable
    />
  );
}
