import { Directions, DirectionsOff } from "@mui/icons-material";
import { Fab, Tooltip } from "@mui/material";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface ITrackControlV2 {
  showTrack: boolean;
  onClick?: (show: boolean) => void;
}

export const TrackControlV2: React.FC<ITrackControlV2> = ({
  showTrack: showTrackInitial,
  onClick,
}) => {
  const [t] = useTranslation();

  const [showTrack, setShowTrack] = useState<boolean>(showTrackInitial);

  const onClickHandler = useCallback(() => {
    if (onClick) onClick(!showTrack);
    setShowTrack(!showTrack);
  }, [onClick, showTrack]);

  return (
    <Tooltip
      title={t(
        "map.control_buttons." +
          (showTrack ? "hideDriverTrack" : "showDriverTrack"),
      )}
    >
      <Fab onClick={onClickHandler}>
        {showTrack ? <Directions /> : <DirectionsOff />}
      </Fab>
    </Tooltip>
  );
};
