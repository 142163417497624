import { Coordinate } from "@ero/app-common/util/Coordinate";
import { ParcelsResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import {
  CenterControlV2,
  FullScreenControlV2,
  MapV2,
  ZoomControlV2,
} from "ProjectComponents";
import { BottomControls } from "ProjectComponents/map/components/controls/bottomControls";
import { TopControls } from "ProjectComponents/map/components/controls/topControls";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { ParcelFigures } from "./parcelFigures";

interface ISelectParcelMap {
  parcels: ParcelsResponseBody["data"];
  selectedParcels: number[];
  setSelectedParcels: Dispatch<SetStateAction<number[]>>;
}

const isCoordinate = (
  coordinate: Coordinate | undefined | null,
): coordinate is Coordinate => {
  return coordinate !== undefined && coordinate !== null;
};

export const SelectParcelMap: React.FC<ISelectParcelMap> = ({
  parcels,
  selectedParcels,
  setSelectedParcels,
}) => {
  const map = useRef<google.maps.Map>();

  const [zoom, setZoom] = useState<number>(16);

  const parcelsBounds = useMemo(() => {
    const parcelsBounds = new google.maps.LatLngBounds();
    parcels
      .map((parcel) =>
        parcel.shape?.length ?? 0 > 0 ? parcel.shape : parcel.position,
      )
      .flat()
      .filter(isCoordinate)
      .forEach((coordinate) => {
        const latLng = new google.maps.LatLng(coordinate.lat, coordinate.lng);
        parcelsBounds.extend(latLng);
      });
    return parcelsBounds;
  }, [parcels]);

  const mapCenter = useMemo(() => parcelsBounds.getCenter(), [parcelsBounds]);

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      map.current = mapInstance;
      map.current.fitBounds(parcelsBounds);
    },
    [parcelsBounds],
  );

  const onZoomChanged = useCallback(
    () => {
      const currentZoom = map.current?.getZoom();
      if (currentZoom) setZoom(currentZoom);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [map],
  );

  const resetCenter = useCallback(() => {
    if (mapCenter) {
      map.current?.setCenter(mapCenter);
    }
  }, [mapCenter]);

  const handleSelect = useCallback(
    (id: number) => {
      if (selectedParcels.filter((parcel) => parcel === id).length > 0) {
        setSelectedParcels([
          ...selectedParcels.filter((parcel) => parcel !== id),
        ]);
      } else {
        setSelectedParcels([...selectedParcels, id]);
      }
    },
    [selectedParcels, setSelectedParcels],
  );

  return (
    <MapV2
      id="parcel-selection-map"
      center={mapCenter}
      zoom={zoom}
      onLoad={onLoad}
      onZoomChanged={onZoomChanged}
    >
      <ParcelFigures
        zoom={zoom}
        parcels={parcels}
        itemOnClick={handleSelect}
        selectedParcels={selectedParcels}
      />
      <TopControls>
        <FullScreenControlV2 mapRef={map} />
      </TopControls>
      <BottomControls>
        <CenterControlV2 onCenter={resetCenter}></CenterControlV2>
        <ZoomControlV2 zoom={zoom} onZoomChanged={setZoom} />
      </BottomControls>
    </MapV2>
  );
};
