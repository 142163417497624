import { Box, Card, CardContent } from "@mui/material";
import { Loader } from "Components";
import { TelemetryType } from "Enums";
import { AppState } from "Store";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { EditTelemetryModal } from "../editTelemetryModal";
import { TelemetryEntry, telemetryEntries } from "../telemetryConfig";
import { EntryRow } from "./entryRow";

interface ITelemetryCard {
  jobId: number;
}

export const TelemetryCard: React.FC<ITelemetryCard> = ({ jobId }) => {
  const { loading } = useSelector(
    (state: AppState) => state.orderDetails.jobTelemetryStatus,
  );
  const [showEditTelemetry, setShowEditTelemetry] = useState(false);
  const [selectedTelemetryType, setSelectedTelemetryType] =
    useState<TelemetryType>();

  const openEditTelemetryModal = useCallback((config: TelemetryEntry) => {
    setSelectedTelemetryType(config.telemetryType);
    setShowEditTelemetry(true);
  }, []);

  const closeEditTelemetryModal = useCallback(
    () => setShowEditTelemetry(false),
    [],
  );

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <CardContent>
          {telemetryEntries.map((item) => (
            <EntryRow
              key={item.name}
              item={item}
              openEditTelemetryModal={openEditTelemetryModal}
            />
          ))}
        </CardContent>
        {loading && (
          <Box position={"absolute"} top={0} width={"100%"} height={"100%"}>
            <Loader />
          </Box>
        )}
      </Card>

      <EditTelemetryModal
        open={showEditTelemetry}
        onClose={closeEditTelemetryModal}
        jobId={jobId}
        telemetryType={selectedTelemetryType}
      />
    </>
  );
};
