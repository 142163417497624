import { Add } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { t } from "i18next";
import React from "react";

export const AddParcelButton: React.FC<{
  onCreateClick: () => void;
  onImportClick: () => void;
  onSendImportLinkClick: () => void;
}> = ({ onCreateClick, onImportClick, onSendImportLinkClick }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  return (
    <>
      <Button
        ref={anchorRef}
        variant="contained"
        startIcon={<Add />}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleToggle}
      >
        {t("general.buttons.add")}
      </Button>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={onCreateClick}>
                    {t("parcels.create")}
                  </MenuItem>
                  <MenuItem onClick={onImportClick}>
                    {t("parcels.create_multiple")}
                  </MenuItem>
                  <MenuItem onClick={onSendImportLinkClick}>
                    {t("parcels.send_importlink")}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
