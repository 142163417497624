import { OverlayView } from "@react-google-maps/api";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { type MachineSelector } from "../../types";
import { useCompass } from "../hooks";

interface IProps {
  currentMachineId: number;
  machineSelector: MachineSelector;
}

const SECTOR_ANGLE = (Math.PI * 2) / 3;

const pathD = (() => {
  const { PI, cos, sin } = Math;
  const a = (PI - SECTOR_ANGLE) / 2;
  const x = cos(a);
  const y = sin(a);
  return `
    M 0 0
    L ${-x} ${-y}
    A 1 1 0 0 1 ${x} ${-y}
    Z
  `;
})();

export const MachineCompass: React.FC<IProps> = ({
  currentMachineId,
  machineSelector,
}) => {
  const rotation = useCompass();
  const style = useMemo(
    () => ({ transform: `rotate(${rotation}deg)` }),
    [rotation],
  );

  const machine = useSelector(machineSelector(currentMachineId));

  if (!machine) {
    return null;
  }

  return (
    <OverlayView
      position={machine.position}
      mapPaneName={OverlayView.OVERLAY_LAYER}
    >
      <svg viewBox="-1 -1 2 2">
        <path d={pathD} style={style} fill="url(#grad)" />
        <defs>
          <radialGradient id="grad" cx="0.5" cy="1" r="1">
            <stop offset="0%" stopColor="red" />
            <stop offset="100%" stopColor="red" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </OverlayView>
  );
};
