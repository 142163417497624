import { CustomerOutside } from "@ero/app-common/models";
import { SendImportLinkRequestBody } from "@ero/app-common/v2/routes/models/import";
import { apiInstance, apiInstanceV2 } from "App/axios";
import { FILE_UPLOAD_TIMEOUT } from "Constants";
import {
  RequestBody,
  Response,
  type ApiListRequestParams,
  type ImportReviewConfirmType,
  type MapColumnRequestType,
} from "Types";

export const getCustomers = ({
  params,
  search,
  filters,
  spec,
}: ApiListRequestParams<CustomerOutside>) =>
  apiInstance.post<Response<"/customers/all">>(
    "/customers/all",
    { search, filters, spec },
    { params },
  );

export const getCustomer = (id: number) =>
  apiInstance.post("/customers/view", { _id: id });

export const createCustomer = (data: RequestBody<"/customers/create">) => {
  // FIXME this shouldn't have to be done here - it's a hotfix
  const keys = Object.keys(data) as (keyof RequestBody<"/customers/create">)[];
  for (const key of keys) {
    if (data[key] !== "") {
      continue;
    }
    delete data[key];
  }

  return apiInstance.post("/customers/create", data);
};

export const deleteCustomers = (ids: number[]) =>
  apiInstance.post("/customers/delete", { ids });

export const updateCustomer = (
  updateData: Partial<CustomerOutside>,
  id: number,
) => {
  const body = {
    ...updateData,
    _id: id,
  };
  return apiInstance.post("/customers/update", body);
};

export const uploadCustomersFile = (
  data: any,
  onUploadProgress: (progressEvent) => void,
) =>
  apiInstance.post("/customers/bulk", data, {
    onUploadProgress,
    timeout: FILE_UPLOAD_TIMEOUT,
  });

export const mapNewCustomerColumns = (data: MapColumnRequestType) =>
  apiInstance.post(
    "/customers/fulfill",
    {
      identifier: data.identifier,
      relationships: data.relationships,
      preview: false,
    },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );

export const mapExistedCustomerColumns = (data: MapColumnRequestType) =>
  apiInstance.post(
    "/customers/overwrite",
    { identifier: data.identifier, relationships: data.relationships },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );

export const importCustomersReviewConfirm = (data: ImportReviewConfirmType) =>
  apiInstance.post(
    "/customers/overwriteCommit",
    { identifier: data.identifier, revert: data.revert },
    { timeout: FILE_UPLOAD_TIMEOUT },
  );

export const sendImportLink = (data: SendImportLinkRequestBody) =>
  apiInstanceV2.post("/import/send-import-link", data);
