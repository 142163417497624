import { Marker, type MarkerProps } from "@react-google-maps/api";
import markerIcon from "Assets/icons/map-marker-google.svg";
import React, { forwardRef, useMemo } from "react";

interface IMarkerV2 extends MarkerProps {}

const MarkerV2 = forwardRef<Marker, IMarkerV2>((props, ref) => {
  const options: google.maps.MarkerOptions = useMemo(() => {
    return {
      icon: {
        url: markerIcon,
        scaledSize: new google.maps.Size(30, 30),
      },
      ...props.options,
    };
  }, [props.options]);

  return <Marker options={options} {...props} ref={ref} />;
});

MarkerV2.displayName = "MarkerV2";

export { MarkerV2 };
