import { ImportResponseBody } from "@ero/app-common/v2/routes/models/import";
import { Box, Dialog, DialogTitle } from "@mui/material";
import { addParcels } from "Api";
import logo from "Assets/Logo_EROplan_RGB.png";
import { Loader } from "Components";
import { useQueryParams } from "Hooks/queryParams";
import { ParcelImportForm } from "ProjectComponents/parcelImport/parcelImportForm";
import ParcelsOverview from "Screens/parcels/components/importOverview/parcelsOverviewModal";
import { ImportParcelTableRow } from "Screens/parcels/types";
import { NotificationService } from "Services";
import { type AppState } from "Store";
import { getExternalToken } from "Store/externalAccess";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AuthError, ImportSuccess } from "./components";

export const ExternalAccess: React.FC = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const {
    accessToken: externalAccessToken,
    loading,
    error: authenticationFailed,
  } = useSelector((state: AppState) => state.externalAccess);

  const [showOverview, setShowOverview] = useState(false);
  const [showImportSuccess, setShowImportSuccess] = useState(false);
  const [parcelData, setParcelData] = useState<ImportParcelTableRow[]>([]);
  const [importID, setImportID] = useState<number>();

  const queryParams = useQueryParams("t");

  const showImportError = useCallback(() => {
    NotificationService.error(
      t("notification.error_title"),
      t("notification.error.parcelimport"),
    );
  }, [t]);

  useEffect(() => {
    dispatch(getExternalToken(queryParams.t as string));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.t]);

  if (loading) {
    return <Loader />;
  }

  const handleFormSubmit = (responseData?: ImportResponseBody) => {
    setImportID(responseData?._id);

    const importedParcels =
      responseData?.data?.map((parcel, index) => ({
        ...parcel,
        size: parcel.size as number,
        crop: -1,
        _id: index,
      })) ?? [];
    setParcelData(importedParcels);

    setShowOverview(true);
  };

  const handleOverviewClose = () => {
    setShowOverview(false);
  };

  const handleAddSelectedParcels = async (parcels: ImportParcelTableRow[]) => {
    try {
      if (importID) {
        addParcels(parcels, importID, externalAccessToken);
        handleOverviewClose();
      } else {
        throw new Error("import data not found");
      }
    } catch (ex) {
      showImportError();
    }
    setShowImportSuccess(true);
  };

  return (
    <>
      <Dialog open>
        <Box component="img" src={logo} sx={{ m: 2 }} />
        <DialogTitle>{t("parcels.create_multiple")}</DialogTitle>
        {!showImportSuccess && !authenticationFailed && (
          <>
            <ParcelImportForm
              onSuccess={handleFormSubmit}
              externalAccessToken={externalAccessToken}
              hideCustomerAutocomplete={true}
              trimfilenames
            />
          </>
        )}
        {authenticationFailed && <AuthError />}
        {showImportSuccess && <ImportSuccess />}
      </Dialog>
      <ParcelsOverview
        isOpen={showOverview}
        accessToken={externalAccessToken}
        onClose={handleOverviewClose}
        onAddSelectedParcels={handleAddSelectedParcels}
        parcelData={parcelData}
        setParcelData={setParcelData}
      />
    </>
  );
};
