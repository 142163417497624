import {
  JobResponseBody,
  type AddJobRequestBody,
  type JobTelemetryResponseBody,
  type JobTelemetryUpdateRequest,
} from "@ero/app-common/v2/routes/models/job";
import {
  type OrderResponseBody,
  type UpdateOrderRequestBody,
} from "@ero/app-common/v2/routes/models/order";
import { type Action } from "redux";

export enum ORDER_DETAILS_ACTION_TYPES {
  GET_INITIAL_ORDER_DETAILS_REQUEST = "order-details/get-initial-order-details-request",
  GET_INITIAL_ORDER_DETAILS_SUCCESS = "order-details/get-initial-order-details-success",
  GET_INITIAL_ORDER_DETAILS_ERROR = "order-details/get-initial-order-details-error",
  UPDATE_ORDER_REQUEST = "order-details/update-order-request",
  UPDATE_ORDER_SUCCESS = "order-details/update-order-success",
  SET_LOADING = "order-details/set-loading",
  SET_SUCCESS = "order-details/set-success",
  SET_ERROR = "order-details/set-error",
  RESET_META = "order-details/reset-meta",
  RESET_STATE = "order-details/reset-state",
  DOWNLOAD_DELIVERY_NOTE = "order-details/download-delivery-note",
  ADD_JOB = "order-details/add-job",
  DELETE_JOB = "order-details/delete-job",
  UPDATE_JOB_NOTES = "order-details/job-details/update-job-notes",
  UPDATE_JOB_NOTES_SUCCESS = "order-details/job-details/update-job-notes-success",
  UPDATE_JOB_NOTES_ERROR = "order-details/job-details/update-job-notes-error",
  GET_JOB_TELEMETRY = "order-details/get-job-telemetry",
  SET_JOB_TELEMETRY = "order-details/set-job-telemetry",
  UPDATE_JOB_TELEMETRY = "order-details/update-job-telemetry",
  SET_JOB_TELEMETRY_LOADING = "order-details/set-job-telemetry-loading",
  SET_JOB_TELEMETRY_SUCCESS = "order-details/set-job-telemetry-success",
  SET_JOB_TELEMETRY_ERROR = "order-details/set-job-telemetry-error",
}

export interface ISetLoadingAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.RESET_META;
}

export interface IResetStateAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.RESET_STATE;
}

export interface IGetInitialOrderDetailsRequestAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_REQUEST;
  payload: {
    id: number;
    jobsSearchData?: any;
  };
}

export interface IGetInitialOrderDetailsSuccessAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_SUCCESS;
  payload: {
    order: OrderResponseBody;
  };
}

export interface IGetInitialOrderDetailsErrorAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.GET_INITIAL_ORDER_DETAILS_ERROR;
}

export interface IUpdateOrderRequestAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_ORDER_REQUEST;
  payload: UpdateOrderRequestBody;
}

export interface IUpdateOrderSuccessAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_ORDER_SUCCESS;
  payload: { order: OrderResponseBody };
}

export interface IDownloadDeliveryNoteAction extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.DOWNLOAD_DELIVERY_NOTE;
}

export interface IAddJob extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.ADD_JOB;
  payload: AddJobRequestBody;
}

export interface IDeleteJob extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.DELETE_JOB;
  payload: { id: number };
}

export interface IUpdateJobNotes extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_NOTES;
  payload: { notes: string; _id: number };
}

export interface IUpdateJobNotesSuccess extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_NOTES_SUCCESS;
  payload: JobResponseBody;
}

export interface IUpdateJobNotesError extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_NOTES_ERROR;
}

export interface IGetJobTelemetry extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.GET_JOB_TELEMETRY;
  payload: { id: number };
}

export interface ISetJobTelemetry extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY;
  payload: { data: JobTelemetryResponseBody };
}

export interface IUpdateJobTelemetry extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.UPDATE_JOB_TELEMETRY;
  payload: {
    id: number;
    telemetry: JobTelemetryUpdateRequest;
  };
}

export interface ISetJobTelemetryLoading extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_LOADING;
}

export interface ISetJobTelemetrySuccess extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_SUCCESS;
}

export interface ISetJobTelemetryError extends Action {
  type: ORDER_DETAILS_ACTION_TYPES.SET_JOB_TELEMETRY_ERROR;
}

export type OrderDetailsAction =
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | IResetStateAction
  | IGetInitialOrderDetailsRequestAction
  | IGetInitialOrderDetailsSuccessAction
  | IGetInitialOrderDetailsErrorAction
  | IUpdateOrderRequestAction
  | IUpdateOrderSuccessAction
  | IDownloadDeliveryNoteAction
  | IAddJob
  | IDeleteJob
  | IUpdateJobNotes
  | IUpdateJobNotesSuccess
  | IUpdateJobNotesError
  | IGetJobTelemetry
  | ISetJobTelemetry
  | IUpdateJobTelemetry
  | ISetJobTelemetryLoading
  | ISetJobTelemetrySuccess
  | ISetJobTelemetryError;
