import i18n from "i18next";

export const VALIDATION_ERRORS = {
  REQUIRED: "Field required",
  NOT_MATCHED_PASSWORD: "Passwords do not match",
  NO_SAFE_PASSWORD:
    "Password must contain at least 8 characters, one number and one special character",
  AVATAR_REQUIRED: "Avatar required",
  VALID_EMAIL: "Please enter valid email",
  NUMBER: "Field should be a valid number",
  DATE: "Field should be a valid date",
  TIME: "Field should be a valid timestamp",
  POSTAL: "Please enter valid postal code",
  PHONE: "Please enter valid phone number",
  USERNAME: "Username must not contain spaces and other whitespace characters",
  MAX_LENGTH: ({ max }) => `Field length should be less than ${max}`,
  MAX_VALUE: ({ max }) => `Field should be less than ${max}`,
  MIN_VALUE: ({ min }) => `Field should be greater than ${min}`,
  VALID_TIME: `Please enter valid time: '00:00`,
  VALID_TIME_WITH_SECONDS: `Please enter valid time: '00:00:00`,
  INTEGER: "Please input integer",
  POSITIVE_NUMBER: "Please use only positive numbers",
};

i18n.on("initialized", () => {
  i18n.on("languageChanged", () => {
    VALIDATION_ERRORS.REQUIRED = i18n.t("validation_errors.field_required");
    VALIDATION_ERRORS.NOT_MATCHED_PASSWORD = i18n.t(
      "validation_errors.passwords_do_not_match",
    );
    VALIDATION_ERRORS.NO_SAFE_PASSWORD = i18n.t(
      "validation_errors.no_safe_password",
    );
    VALIDATION_ERRORS.MAX_LENGTH = ({ max }) =>
      `${i18n.t("validation_errors.max_length")} ${max}`;
    VALIDATION_ERRORS.MAX_VALUE = ({ max }) =>
      `${i18n.t("validation_errors.max_value")} ${max}`;
    VALIDATION_ERRORS.MIN_VALUE = ({ min }) =>
      `${i18n.t("validation_errors.min_value")} ${min}`;
    VALIDATION_ERRORS.AVATAR_REQUIRED = i18n.t(
      "validation_errors.avatar_required",
    );
    VALIDATION_ERRORS.VALID_EMAIL = i18n.t("validation_errors.valid_email");
    VALIDATION_ERRORS.NUMBER = i18n.t("validation_errors.number");
    VALIDATION_ERRORS.DATE = i18n.t("validation_errors.date");
    VALIDATION_ERRORS.TIME = i18n.t("validation_errors.time");
    VALIDATION_ERRORS.POSTAL = i18n.t("validation_errors.postal");
    VALIDATION_ERRORS.PHONE = i18n.t("validation_errors.phone");
    VALIDATION_ERRORS.USERNAME = i18n.t("validation_errors.username");

    VALIDATION_ERRORS.VALID_TIME = i18n.t("validation_errors.valid_time");
    VALIDATION_ERRORS.VALID_TIME_WITH_SECONDS = i18n.t(
      "validation_errors.valid_time_seconds",
    );
    VALIDATION_ERRORS.INTEGER = i18n.t("validation_errors.integer");
    VALIDATION_ERRORS.POSITIVE_NUMBER = i18n.t(
      "validation_errors.positive_number",
    );
  });
});

export const VALIDATION_REGEX = {
  userName: /^\S+$/,
  zipCode: /^\d{5}$/,
  phone: /^(\+?(\d[\d\s-()]*?){6,})?$/,
  duration: /^\d{1,2}:\d{1,2}$/,
  durationWithSeconds: /^\d{1,2}:\d{1,2}:\d{1,2}$/,
  password: /(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/,
};

export const numberValidationTransform = (_: any, originalValue: string) =>
  +originalValue.replace(/,/, ".");

export const MAX_LENGTH = {
  DEFAULT: 100,
  NUMBER: 1000000,
  LONG_STRING: 255,
  PERCENT: 100,
  MAX_MULTIPLE_ROWS_CREATION: 1000,
};
