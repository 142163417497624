import { OverlayView, type OverlayViewProps } from "@react-google-maps/api";
import React, { forwardRef } from "react";

interface IOverlay extends OverlayViewProps {}

const OverlayV2 = forwardRef<OverlayView, IOverlay>((props, ref) => {
  return (
    <OverlayView {...props} ref={ref}>
      {props.children}
    </OverlayView>
  );
});

OverlayV2.displayName = "OverlayV2";

export { OverlayV2 };
