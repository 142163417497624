import * as Yup from 'yup';

import { VALIDATION_ERRORS } from 'Constants';

export type ValuesType = {
  start: number | string;
  end: number | string;
};

export const initialValues: ValuesType = {
  start: '',
  end: '',
};

export const getValidationSchema = () =>
  Yup.object().shape({
    start: Yup.number().required(VALIDATION_ERRORS.REQUIRED),
    end: Yup.number().required(VALIDATION_ERRORS.REQUIRED),
  });
