import { type AxiosError } from "axios";
import i18n from "i18next";
import { ApiError } from "Enums";

export const getErrorMessage = (error: unknown): string => {
  if ((error as AxiosError).isAxiosError && (error as AxiosError).response) {
    const err = error as AxiosError;

    if (typeof err.response?.data === "string") {
      if (err.response.data.split(" ")[1] === "ZIP_ARCHIVE_NO_SHP\n") {
        return i18n.t("notification.upload_error");
      }

      return err.response.data;
    }
    if (err.response?.data && typeof err.response?.data === "object") {
      return (
        getErrorLabelByCode(err.response!.data["code"]) ||
        err.response!.data["message"]
      );
    }
  }

  if (error instanceof Error) {
    return error.message;
  }
  return i18n.t("notification.general_error");
};

function getErrorLabelByCode(code: ApiError) {
  let translationId = "errors.";

  switch (code) {
    case ApiError.NO_TOKEN_PROVIDED:
      translationId += "no_token_provided";
      break;
    case ApiError.TOKEN_EXPIRED:
      translationId += "token_expired";
      break;
    case ApiError.INSUFFICIENT_PERMISSIONS:
      translationId += "insufficient_permissions";
      break;
    case ApiError.TOKEN_NOT_RECOGNIZED:
      translationId += "token_not_recognized";
      break;
    case ApiError.USER_NOT_VERIFIED:
      translationId += "user_not_verified";
      break;
    case ApiError.PERMISSION_NOT_RECOGNIZED:
      translationId += "permission_not_recognized";
      break;
    case ApiError.SHOULD_BE_NUMBER:
      translationId += "should_be_number";
      break;
    case ApiError.SHOULD_BE_BOOLEAN:
      translationId += "should_be_boolean";
      break;
    case ApiError.MISSING_KEY:
      translationId += "missing_key";
      break;
    case ApiError.KEY_NOT_RECOGNIZED:
      translationId += "key_not_recognized";
      break;
    case ApiError.BODY_NOT_PARSEABLE:
      translationId += "body_not_parseable";
      break;
    case ApiError.WRONG_TYPE:
      translationId += "wrong_type";
      break;
    case ApiError.NOT_FOUND_COMPANY:
      translationId += "not_found_company";
      break;
    case ApiError.NOT_FOUND_CUSTOMER:
      translationId += "not_found_customer";
      break;
    case ApiError.NOT_FOUND_BILLING:
      translationId += "not_found_billing";
      break;
    case ApiError.NOT_FOUND_POSITION:
      translationId += "not_found_position";
      break;
    case ApiError.NOT_FOUND_ORDER:
      translationId += "not_found_order";
      break;
    case ApiError.NOT_FOUND_USER:
      translationId += "not_found_user";
      break;
    case ApiError.NOT_FOUND_MACHINE:
      translationId += "not_found_machine";
      break;
    case ApiError.NOT_FOUND_JOB:
      translationId += "not_found_job";
      break;
    case ApiError.NOT_FOUND_SERVICE:
      translationId += "not_found_service";
      break;
    case ApiError.NOT_FOUND_ROW:
      translationId += "not_found_row";
      break;
    case ApiError.NOT_FOUND_PARCEL:
      translationId += "not_found_parcel";
      break;
    case ApiError.NOT_FOUND_FIELD:
      translationId += "not_found_field";
      break;
    case ApiError.NOT_FOUND_PLOT:
      translationId += "not_found_plot";
      break;
    case ApiError.NOT_FOUND_FILE:
      translationId += "not_found_file";
      break;
    case ApiError.NOT_FOUND_FAQ:
      translationId += "not_found_faq";
      break;
    case ApiError.NOT_FOUND_PERSON:
      translationId += "not_found_person";
      break;
    case ApiError.NOT_FOUND_TIMESLOT:
      translationId += "not_found_timeslot";
      break;
    case ApiError.ALLEGIANCE_CUSTOMER:
      translationId += "allegiance_customer";
      break;
    case ApiError.ALLEGIANCE_BILLING:
      translationId += "allegiance_billing";
      break;
    case ApiError.ALLEGIANCE_MACHINE:
      translationId += "allegiance_machine";
      break;
    case ApiError.ALLEGIANCE_JOB:
      translationId += "allegiance_job";
      break;
    case ApiError.ALLEGIANCE_SERVICE:
      translationId += "allegiance_service";
      break;
    case ApiError.ALLEGIANCE_ORDER:
      translationId += "allegiance_order";
      break;
    case ApiError.ALLEGIANCE_PARCEL:
      translationId += "allegiance_parcel";
      break;
    case ApiError.ALLEGIANCE_FIELD:
      translationId += "allegiance_field";
      break;
    case ApiError.ALLEGIANCE_PLOT:
      translationId += "allegiance_plot";
      break;
    case ApiError.ALLEGIANCE_USER:
      translationId += "allegiance_user";
      break;
    case ApiError.ALLEGIANCE_TIMESLOT:
      translationId += "allegiance_timeslot";
      break;
    case ApiError.PARCEL_NOT_ASSIGNED:
      translationId += "parcel_not_assigned";
      break;
    case ApiError.MACHINE_NOT_ASSIGNED:
      translationId += "machine_is_not_assigned";
      break;
    case ApiError.USER_NOT_ASSIGNED:
      translationId += "user_is_not_assigned";
      break;
    case ApiError.ALREADY_ASSIGNED_PARCEL:
      translationId += "already_assigned_parcel";
      break;
    case ApiError.ALREADY_ASSIGNED_MACHINE:
      translationId += "already_assigned_machine";
      break;
    case ApiError.ALREADY_ASSIGNED_USER:
      translationId += "already_assigned_user";
      break;
    case ApiError.JOB_MACHINES_SCHEDULE_CONFLICT:
      translationId += "job_machines_schedule_conflict";
      break;
    case ApiError.CONFLICTING_TIME_SLOTS:
      translationId += "conflicting_time_slots";
      break;
    case ApiError.ZIP_ARCHIVE_NO_SHP:
      translationId += "zip_archive_no_shp";
      break;
    case ApiError.ZIP_ARCHIVE_NO_DBF:
      translationId += "zip_archive_no_dbf";
      break;
    case ApiError.ZIP_ARCHIVE_NO_PRJ:
      translationId += "zip_archive_no_prj";
      break;
    case ApiError.NO_CSV_FOUND:
      translationId += "no_csv_found";
      break;
    case ApiError.SHAPEFILE_NOT_STANDARD_COMPLIANT:
      translationId += "shapefile_not_standard_compliant";
      break;
    case ApiError.CSV_PARSE_ERROR:
      translationId += "csv_parse_error";
      break;
    case ApiError.RELATIONSHIP_MAPPING_ERROR:
      translationId += "relationship_mapping_error";
      break;
    case ApiError.NO_EXISTING_COUNTERPART:
      translationId += "no_existing_counterpart";
      break;
    case ApiError.ORDER_ALREADY_ADDED:
      translationId += "order_already_added";
      break;
    case ApiError.USERNAME_ALREADY_TAKEN:
      translationId += "username_already_taken";
      break;
    case ApiError.SLNR_NO_MATCH:
      translationId += "slnr_no_match";
      break;
    case ApiError.IDENTIFIER_NOT_RECOGNIZED:
      translationId += "identifier_not_recognized";
      break;
    case ApiError.MISSING_REQUIRED_COLUMNS:
      translationId += "missing_required_column";
      break;
    case ApiError.USER_ALREADY_ACTIVATED:
      translationId += "user_already_activated";
      break;
    case ApiError.CANNOT_RECEIVE_EMAILS:
      translationId += "cannot_receive_emails";
      break;
    case ApiError.INVITE_EMAIL_XOR_PASSWORD:
      translationId += "invite_email_xor_password";
      break;
    case ApiError.CANNOT_OVERWRITE_EMAIL_ACCOUNT:
      translationId += "cannot_overwrite_email_account";
      break;
    case ApiError.CANNOT_SELF_DELETE:
      translationId += "cannot_self_delete";
      break;
    case ApiError.TIME_CONSTRAINTS_INVALID:
      translationId += "time_constraints_invalid";
      break;
    case ApiError.BROKEN_FILEPATH:
      translationId += "broken_filepath";
      break;
    case ApiError.COLLECTION_REFERENCE_DEAD:
      translationId += "collection_reference_dead";
      break;
    case ApiError.DATAURI_EXTRACTION_FAILED:
      translationId += "datauri_extraction_failed";
      break;
    case ApiError.CSV_EMPTY:
      translationId += "csv_empty";
      break;
    case ApiError.MODELNAME_UNRECOGNIZED:
      translationId += "modelname_unrecongnized";
      break;
    case ApiError.PIPELINE_LOGIC_BROKEN:
      translationId += "pipeline_logic_broken";
      break;
    default:
      translationId = "";
  }

  return i18n.t(translationId);
}
