import { all, fork } from "redux-saga/effects";
import authSagas from "./auth/sagas";
import customerEmployeesSaga from "./customerPersons/sagas";
import customersSaga from "./customers/sagas";
import dashboardSaga from "./dashboard/sagas";
import employeesSaga from "./employees/sagas";
import externalAccessSaga from "./externalAccess/sagas";
import generalSaga from "./general/sagas";
import helpSaga from "./help/sagas";
import machinesSaga from "./machines/sagas";
import orderDetailsSagas from "./orderDetails/sagas";
import ordersSagas from "./orders/sagas";
import organizationSagas from "./organization/sagas";
import parcelsSaga from "./parcels/sagas";
import planningSaga from "./planning/sagas";
import plotSaga from "./plots/sagas";
import releaseNoteSagas from "./releaseNotes/sagas";
import servicesSagas from "./services/sagas";

export default function* rootSaga() {
  yield all([
    fork(generalSaga),
    fork(authSagas),
    fork(machinesSaga),
    fork(employeesSaga),
    fork(organizationSagas),
    fork(parcelsSaga),
    fork(ordersSagas),
    fork(orderDetailsSagas),
    fork(customersSaga),
    fork(customerEmployeesSaga),
    fork(servicesSagas),
    fork(dashboardSaga),
    fork(planningSaga),
    fork(helpSaga),
    fork(plotSaga),
    fork(releaseNoteSagas),
    fork(externalAccessSaga),
  ]);
}
