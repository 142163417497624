import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { CalendarEventColor } from "Enums";
import { type CalendarDateRange, type UpdateCalendarJobType } from "Types";
import { type Action } from "redux";
import { type IUpdateJobLive } from "../general/action-types";
import { type LocalJobType } from "./specs";
import { FilterType } from "./types";

export enum PLANNING_ACTION_TYPES {
  SET_LOADING = "planning/set-loading",
  SET_SUCCESS = "planning/set-success",
  SET_ERROR = "planning/set-error",
  RESET_META = "planning/reset-meta",
  GET_INITIAL_CALENDAR_REQUEST = "planning/get-initial-calendar-request",
  GET_INITIAL_CALENDAR_SUCCESS = "planning/get-initial-calendar-success",
  GET_INITIAL_CALENDAR_ERROR = "planning/get-initial-calendar-error",
  GET_ORDERS = "planning/get-orders",
  SET_ORDERS = "planning/set-orders",
  GET_JOBS = "planning/get-jobs",
  SET_JOBS = "planning/set-jobs",
  UPDATE_JOB = "planning/update-job",
  UPDATE_JOB_SUCCESS = "planning/update-job-success",
  RESCHEDULE_JOBS = "planning/reschedule-jobs",
  RESCHEDULE_JOBS_SUCCESS = "planning/reschedule-jobs-success",
  UPDATE_JOB_LOCK = "planning/update-job-lock",
  FILTER_ORDERS = "planning/filter-orders",
  FILTER_ORDERS_SUCCESS = "planning/filter-orders-success",
  SET_FILTERS = "planning/set-filters",
  ADD_CALENDAR_JOB = "planning/add-calendar-job",
  REMOVE_CALENDAR_JOB = "planning/remove-calendar-job",
  SET_CALENDAR_ALIGN = "planning/set-calendar-align",
  SET_SHOW_STATUS_COLOR = "planning/set-show-status-color",
  SET_ROW_SCALE = "planning/set-row-scale",
  UPDATE_MULTIPLE_JOBS = "planning/update-multiple-jobs",
  UPDATE_MULTIPLE_JOBS_SUCCESS = "planning/update-multiple-jobs-success",
  SET_CALENDAR_CURRENT_VIEW = "planning/set-calendar-current-view",
  ASSIGN_MACHINE = "planning/assign-machine",
  ASSIGN_MACHINE_STATUS = "planning/assign-machine-status",
  UPDATE_JOB_LOADING = "planning/update-job-loading",
  SET_CALENDAR_CURRENT_VIEW_RANGE = "planning/set-calendar-current-view-range",
  PRINT_EMPLOYEE_CALENDAR = "planning/print-employee-calendar",
  SET_SELECTED_EMPLOYEES = "planning/set-selected-employees",
}

export interface ISetLoadingAction extends Action {
  type: PLANNING_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: PLANNING_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: PLANNING_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: PLANNING_ACTION_TYPES.RESET_META;
}

export interface IGetInitialCalendarRequestAction extends Action {
  type: PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_REQUEST;
  payload: {
    dateRange: CalendarDateRange;
  };
}

export interface IGetInitialCalendarSuccessAction extends Action {
  type: PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_SUCCESS;
  payload: {
    orders: OrderResponseBody[];
    jobs: JobResponseBody[];
    employees: UserResponseBody[];
    dateRange: CalendarDateRange;
  };
}

export interface IGetInitialCalendarErrorAction extends Action {
  type: PLANNING_ACTION_TYPES.GET_INITIAL_CALENDAR_ERROR;
  payload: { dateRange: CalendarDateRange };
}

export interface ISetOrders extends Action {
  type: PLANNING_ACTION_TYPES.SET_ORDERS;
  payload: OrderResponseBody[];
}

export interface IGetOrders extends Action {
  type: PLANNING_ACTION_TYPES.GET_ORDERS;
}

export interface IGetJobs extends Action {
  type: PLANNING_ACTION_TYPES.GET_JOBS;
  payload: {
    requestDates: CalendarDateRange;
    dateRange: CalendarDateRange;
    replace?: boolean;
  };
}

export interface ISetJobs extends Action {
  type: PLANNING_ACTION_TYPES.SET_JOBS;
  payload: {
    jobs: JobResponseBody[];
    dateRange: CalendarDateRange;
  };
}

export interface IUpdateJob extends Action {
  type: PLANNING_ACTION_TYPES.UPDATE_JOB;
  payload: {
    data: UpdateCalendarJobType;
    revertEvent?: () => void;
  };
}

export interface IUpdateJobSuccess extends Action {
  type: PLANNING_ACTION_TYPES.UPDATE_JOB_SUCCESS;
  payload: { job: JobResponseBody };
}

export interface IRescheduleJobs extends Action {
  type: PLANNING_ACTION_TYPES.RESCHEDULE_JOBS;
  payload: { data };
}

export interface IRescheduleJobsSuccess extends Action {
  type: PLANNING_ACTION_TYPES.RESCHEDULE_JOBS_SUCCESS;
  payload: { jobs: JobResponseBody[] };
}

export interface IUpdateJobLock extends Action {
  type: PLANNING_ACTION_TYPES.UPDATE_JOB_LOCK;
  payload: {
    data: {
      id: number;
      locked?: boolean;
    };
  };
}

export interface IAddCalendarJob extends Action {
  type: PLANNING_ACTION_TYPES.ADD_CALENDAR_JOB;
  payload: { orderId: number; job: JobResponseBody };
}

export interface IRemoveCalendarJob extends Action {
  type: PLANNING_ACTION_TYPES.REMOVE_CALENDAR_JOB;
  payload: { job: LocalJobType };
}

export interface IFilterOrders extends Action {
  type: PLANNING_ACTION_TYPES.FILTER_ORDERS;
  payload: {
    filters: FilterType;
  };
}

export interface IFilterOrdersSuccess extends Action {
  type: PLANNING_ACTION_TYPES.FILTER_ORDERS_SUCCESS;
  payload: {
    orders: OrderResponseBody[];
    filters: FilterType;
  };
}

export interface ISetFilters extends Action {
  type: PLANNING_ACTION_TYPES.SET_FILTERS;
  payload: {
    filters: FilterType;
  };
}

export interface ISetStatusColor extends Action {
  type: PLANNING_ACTION_TYPES.SET_SHOW_STATUS_COLOR;
  payload: { eventColor: CalendarEventColor };
}

export interface ISetRowScale extends Action {
  type: PLANNING_ACTION_TYPES.SET_ROW_SCALE;
  payload: { value: number };
}

export interface IUpdateMultipleJobs extends Action {
  type: PLANNING_ACTION_TYPES.UPDATE_MULTIPLE_JOBS;
  payload: {
    jobsForChange: UpdateCalendarJobType[];
  };
}

export interface IUpdateMultipleJobsSuccess extends Action {
  type: PLANNING_ACTION_TYPES.UPDATE_MULTIPLE_JOBS_SUCCESS;
  payload: {
    newJobs: JobResponseBody[];
  };
}

export interface ISetCalendarCurrentView extends Action {
  type: PLANNING_ACTION_TYPES.SET_CALENDAR_CURRENT_VIEW;
  payload: { view: string };
}

export interface ISetCalendarCurrentViewRange extends Action {
  type: PLANNING_ACTION_TYPES.SET_CALENDAR_CURRENT_VIEW_RANGE;
  payload: { dateRange: CalendarDateRange };
}

export interface IAssignMachine extends Action {
  type: PLANNING_ACTION_TYPES.ASSIGN_MACHINE;
  payload: { id: number; machine: number };
}

export interface IUpdateJobLoading extends Action {
  type: PLANNING_ACTION_TYPES.UPDATE_JOB_LOADING;
  payload: { loading: boolean };
}

export interface IAssignMachineStatus extends Action {
  type: PLANNING_ACTION_TYPES.ASSIGN_MACHINE_STATUS;
  payload: { error: boolean; success: boolean };
}

export interface IPrintEmployeeCalendar extends Action {
  type: PLANNING_ACTION_TYPES.PRINT_EMPLOYEE_CALENDAR;
  payload: {
    employee: number;
    start: number;
    end: number;
  };
}

export interface ISetSelectedEmployees extends Action {
  type: PLANNING_ACTION_TYPES.SET_SELECTED_EMPLOYEES;
  payload: number[];
}

export type OrderCalendarAction =
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | IGetInitialCalendarRequestAction
  | IGetInitialCalendarSuccessAction
  | IGetInitialCalendarErrorAction
  | IGetOrders
  | ISetOrders
  | IGetJobs
  | ISetJobs
  | IUpdateJob
  | IUpdateJobLock
  | IUpdateJobSuccess
  | IAddCalendarJob
  | IRemoveCalendarJob
  | IFilterOrders
  | IFilterOrdersSuccess
  | ISetFilters
  | ISetStatusColor
  | ISetRowScale
  | IUpdateJobLive
  | IUpdateMultipleJobs
  | IUpdateMultipleJobsSuccess
  | IRescheduleJobs
  | IRescheduleJobsSuccess
  | ISetCalendarCurrentView
  | ISetCalendarCurrentViewRange
  | IAssignMachine
  | IUpdateJobLoading
  | IAssignMachineStatus
  | IPrintEmployeeCalendar
  | ISetSelectedEmployees;
