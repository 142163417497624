import { apiInstance } from "App/axios";
import { TELEMETRY_API_TIMEOUT } from "Constants";
import {
  type MachineTrackSelection,
  type TelemetryAccounting,
  type TelemetryInfo,
  type TelemetryLocation,
  type TelemetryTick,
  type TrackExport,
} from "Types";
import axios from "axios";

let baseURL: string;
if (process.env.NODE_ENV === "development") {
  baseURL = "/telemetryapi";
} else {
  baseURL = process.env.REACT_APP_LOCAL_TELEMETRY_URL!;
}

export const tick = (data: TelemetryTick) =>
  apiInstance.post("/telemetry/tick", data);

export const getMachineTrack = (
  data: MachineTrackSelection,
  onlyJobs = false,
) => apiInstance.post("/telemetry/machineTrack", { ...data, onlyJobs });

export const telemetryApi = axios.create({
  timeout: TELEMETRY_API_TIMEOUT,
  baseURL,
});

export const getTelemetryAccounting = () =>
  telemetryApi.get<TelemetryAccounting>("/Accounting");
export const getTelemetryLocation = () =>
  telemetryApi.get<TelemetryLocation>("/Location");
export const getTelemetryInfo = () => telemetryApi.get<TelemetryInfo>("/Info");
export const getTelemetryReset = () =>
  telemetryApi.get<TelemetryInfo>("/Reset");

// TODO: to be implemented...
// export const telemetryFeedback = (data) => telemetryApi.post('/Feedback', data);

export const getTelemetryTrackExport = (data: TrackExport) =>
  apiInstance.post("/telemetry/exportTrack", data);
