import { type DropdownRow } from "Types";
import {
  ORGANIZATION_ACTION_TYPES,
  type OrganizationAction,
} from "./action-types";

type OrganizationStateType = {
  category: DropdownRow[];
  crop: DropdownRow[];
  manufacturer: DropdownRow[];
  trainingType: DropdownRow[];
  serviceKind: DropdownRow[];
  soil: DropdownRow[];
  driversLicense: DropdownRow[];
  maxCount: number;
  listUpdateLoading: boolean;
  success: boolean;
  loading: boolean;
  error: boolean;
};

const initialState: OrganizationStateType = {
  category: [] as DropdownRow[],
  crop: [] as DropdownRow[],
  manufacturer: [] as DropdownRow[],
  trainingType: [] as DropdownRow[],
  serviceKind: [] as DropdownRow[],
  soil: [] as DropdownRow[],
  driversLicense: [] as DropdownRow[],
  maxCount: 0,
  listUpdateLoading: false,
  success: false,
  loading: false,
  error: false,
};

export const organizationReducer = (
  state = initialState,
  action: OrganizationAction,
): OrganizationStateType => {
  switch (action.type) {
    case ORGANIZATION_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };
    case ORGANIZATION_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };
    case ORGANIZATION_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
        listUpdateLoading: false,
      };
    case ORGANIZATION_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        listUpdateLoading: false,
      };
    case ORGANIZATION_ACTION_TYPES.GET_ALL_DROPDOWNS_SUCCESS:
      return {
        ...state,
        [action.payload.name]: action.payload.dropDown,
        success: true,
        loading: false,
      };
    default:
      return state;
  }
};
