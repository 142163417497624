import { DEFAULT_COLOR_MODE, DEFAULT_LANG } from "Constants";
import { type CompanyType, type TokenType, type UserDataType } from "Types";
import Cookies from "js-cookie";
import { AUTH_ACTION_TYPES, type AuthAction } from "./action-types";

export type AuthStateType = {
  userData: UserDataType;
  companyData: CompanyType;
  selectedLang: string;
  colorMode: string;
  tokens: TokenType;
  success: boolean;
  loading: boolean;
  error: boolean;
  sidebarOpen: boolean;
  sidebarSubMenuOpen: boolean;
  initialLoading: boolean;
};

const initialState: AuthStateType = {
  userData: {} as UserDataType,
  companyData: {} as CompanyType,
  selectedLang: DEFAULT_LANG,
  colorMode: Cookies.get("colorMode")
    ? (Cookies.get("colorMode") as string)
    : DEFAULT_COLOR_MODE,
  tokens: {} as TokenType,
  success: false,
  loading: false,
  error: false,
  sidebarOpen: true,
  sidebarSubMenuOpen: false,
  initialLoading: true,
};

export const authReducer = (
  state = initialState,
  action: AuthAction,
): AuthStateType => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        tokens: action.payload.tokens,
        userData: action.payload.userData,
        companyData: action.payload.companyData,
        selectedLang: action.payload.userData.language || state.selectedLang,
        colorMode: Cookies.get("colorMode")
          ? (Cookies.get("colorMode") as string)
          : DEFAULT_COLOR_MODE,
        initialLoading: false,
      };

    case AUTH_ACTION_TYPES.SET_COMPANY_DETAILS:
      return {
        ...state,
        companyData: action.payload.companyData,
      };

    case AUTH_ACTION_TYPES.SET_USERDATA:
      return {
        ...state,
        userData: action.payload.userData,
      };

    case AUTH_ACTION_TYPES.SET_RESET_TOKEN: {
      return {
        ...state,
        tokens: {
          ...state.tokens,
          resetToken: action.payload.resetToken,
        },
      };
    }

    case AUTH_ACTION_TYPES.LOGOUT_SUCCESS:
      return {
        ...initialState,
        selectedLang: state.selectedLang,
        sidebarOpen: state.sidebarOpen,
        colorMode: Cookies.get("colorMode")
          ? (Cookies.get("colorMode") as string)
          : DEFAULT_COLOR_MODE,
      };

    case AUTH_ACTION_TYPES.SET_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
      };

    case AUTH_ACTION_TYPES.SET_LOADING:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
      };

    case AUTH_ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: true,
      };

    case AUTH_ACTION_TYPES.RESET_META:
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
      };

    case AUTH_ACTION_TYPES.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          accessToken: action.payload.accessToken,
        },
      };

    case AUTH_ACTION_TYPES.SET_LANGUAGE:
      return {
        ...state,
        userData: {
          ...state.userData,
          language: action.payload.lang,
        },
        selectedLang: action.payload.lang,
      };

    case AUTH_ACTION_TYPES.CHANGE_COLORMODE:
      return {
        ...state,
        colorMode: action.payload.mode,
      };

    case AUTH_ACTION_TYPES.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarOpen: action.payload.value,
      };

    case AUTH_ACTION_TYPES.TOGGLE_SIDEBAR_SUBMENU:
      return {
        ...state,
        sidebarSubMenuOpen: action.payload.value,
      };

    default:
      return state;
  }
};
