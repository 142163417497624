import { DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { TimeInput } from "ProjectComponents/FormikDateInput/TimeInput";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ValuesType } from "../../../formConfig";

export const DayTimeContent: React.FC = () => {
  const [t] = useTranslation();
  const { values } = useFormikContext<ValuesType>();

  const timeMode = values.dateRestrictions.timeMode;

  const content = useMemo(
    () =>
      timeMode == DateTimeMode.WITHDAYTIME && (
        <>
          <Grid item xs={6}>
            <TimeInput
              name="dateRestrictions.dayTime.startTime"
              required
              label={t("orders.create_modal.startTime")}
            />
          </Grid>
          <Grid item xs={6}>
            <TimeInput
              name="dateRestrictions.dayTime.endTime"
              required
              label={t("orders.create_modal.endTime")}
            />
          </Grid>
        </>
      ),
    [t, timeMode],
  );

  return <>{content}</>;
};
