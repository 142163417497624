import { LANGUAGE } from "@ero/app-common/enums/language";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ErrorDialog } from "Components";
import { AppLoader } from "ProjectComponents/AppLoader";
import { GoogleMapsScriptLoader } from "ProjectComponents/map/mapContext";
import { Routes } from "Routes";
import { AppState, persistor, store } from "Store";
import { createAppTheme, useTheme } from "Theme";
import "dayjs/locale/de";
import "dayjs/locale/en";
import i18n from "i18n";
import React, { Component, PropsWithChildren, Suspense } from "react";
import { isMobile } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { I18nextProvider } from "react-i18next";
import { Provider as ReduxProvider, useSelector } from "react-redux";
import { Theme, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-virtualized/styles.css";
import { PersistGate } from "redux-persist/integration/react";

const dndBackend = isMobile ? TouchBackend : HTML5Backend;

export class ErrorBoundary extends Component<
  PropsWithChildren,
  { error: string }
> {
  state = {
    error: "",
  };

  static getDerivedStateFromError(error: Error) {
    return {
      error: error.message,
    };
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <ThemeProvider theme={createAppTheme()}>
          <ErrorDialog error={error} />
        </ThemeProvider>
      );
    }

    return this.props.children;
  }
}

const ThemedApp: React.FC = () => {
  const { selectedLang, colorMode } = useSelector(
    (state: AppState) => state.auth,
  );
  const theme = useTheme(colorMode as "dark" | "light", selectedLang);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<AppLoader />}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={selectedLang === LANGUAGE.DE ? "de" : "en"}
        >
          <Routes />
        </LocalizationProvider>
        <ToastContainer
          position={toast.POSITION.TOP_RIGHT}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={colorMode as Theme}
        />
      </Suspense>
    </ThemeProvider>
  );
};

export const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <GoogleMapsScriptLoader>
        <ReduxProvider store={store}>
          <PersistGate persistor={persistor}>
            <I18nextProvider i18n={i18n}>
              <DndProvider backend={dndBackend}>
                <ThemedApp />
              </DndProvider>
            </I18nextProvider>
          </PersistGate>
        </ReduxProvider>
      </GoogleMapsScriptLoader>
    </ErrorBoundary>
  );
};
