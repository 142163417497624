import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const Policy: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Container maxWidth={"lg"} sx={{ pb: 2 }}>
        <Container>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>
            {t("static.policy.title")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block1.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block2.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block2.text1")}
            <br />
            {t("static.policy.block2.text2")}
            <br />
            {t("static.policy.block2.text3")}
            <br />
            {t("static.policy.block2.text4")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block2.text5")}
            <br />
            {t("static.policy.block2.text6")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block3.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block3.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block3.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block3.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block3.text4")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block3.text5")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block4.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block4.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block5.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block5.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block5.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block5.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block5.text4")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block6.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block6.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block6.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block6.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block6.text4")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block6.text5")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block6.text6")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block7.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block7.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block8.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block8.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block8.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block9.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block9.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block9.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block10.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block10.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block11.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block11.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block11.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block11.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block11.text4")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block11.text5")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block12.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block12.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block13.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block13.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block14.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block14.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block14.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block14.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block14.text4")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block15.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block15.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block15.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block15.text3")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block16.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block16.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block16.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block16.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block16.text4")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block16.text5")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block16.text6")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block17.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block17.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block17.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block17.text3")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block18.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block18.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block18.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block18.text3")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block19.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block19.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block19.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block19.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block19.text4")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block19.text5")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block19.text6")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block19.text7")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block20.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block20.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block20.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block21.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block21.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block21.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block22.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block22.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block22.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block23.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block23.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block23.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block24.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block24.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block24.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block25.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block25.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.policy.block25.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block26.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block26.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4" gutterBottom sx={{ pt: 2 }}>{`${t(
            "static.policy.block27.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.policy.block27.text1")}
          </Typography>
        </Container>
      </Container>
    </Box>
  );
};
