import { Button, List, ListItem } from "@mui/material";
import { Loader } from "Components";
import { PasswordInput } from "Components/controls/PasswordInput";
import { type AppState } from "Store";
import { Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const FormContent: React.FC = () => {
  const [t] = useTranslation();

  const { loading } = useSelector((state: AppState) => state.auth);

  return (
    <Form>
      <List>
        <ListItem>
          <PasswordInput
            name="password"
            label={t("auth.reset_password.field_labels.pass")}
          />
        </ListItem>
        <ListItem>
          <PasswordInput
            name="repeat"
            label={t("auth.reset_password.field_labels.repeat")}
          />
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            startIcon={loading && <Loader size={30} />}
          >
            {t("general.buttons.send")}
          </Button>
        </ListItem>
      </List>
    </Form>
  );
};
