import { Error } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { type InputProps } from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { useField, type FieldConfig } from "formik";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-gb";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { IS_CHROME, IS_SAFARI } from "../../Constants";
import { type LanguageType } from "../../Types";
import { date2inputValue } from "../../Utils";
import { type IInput } from "./";
import "./styles.css";

interface IProps extends FieldConfig, IInput {
  useMUIPicker?: boolean;
  locale?: LanguageType;
  format?: string;
  onDateChange?: (date: Date | number) => void;
  setFieldValue?: (a: any, b: any) => void;
}

type PickerChangeEvent =
  | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | any;

const DateTimeInputComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IProps
> = (props, ref) => {
  const {
    format = "DD.MM.YYYY HH:mm",
    StartIcon,
    EndIcon,
    disabled,
    label,
    placeholder,
    useMUIPicker = false,
    locale = "us",
    onDateChange = () => null,
    setFieldValue,
    mandatory,
    ...rest
  } = props;
  const [isPickerError, setIsPickerError] = useState(false);
  const [field, meta, helpers] = useField<Date | number | string>(rest.name);
  const isError = Boolean((meta.error && meta.touched) || isPickerError);
  const [momentValue, setMomentValue] = useState(
    field.value ? moment(field.value) : null,
  );

  const theme = useTheme();
  const mode = theme.palette.mode;

  const datePickerLocale = useMemo(
    () => (locale === "us" ? "en" : locale),
    [locale],
  );

  useEffect(() => {
    moment.locale(datePickerLocale, { week: { dow: 1 } });
  }, [datePickerLocale]);

  useEffect(() => {
    if (field.value) {
      setMomentValue(moment(field.value));
    }

    if (field.value === 0 || field.value === "") {
      setMomentValue(null);
    }
  }, [field.value]);

  const inputProps: InputProps = {
    onBlur: () => {
      if (!momentValue?.isValid() && field.value) {
        setMomentValue(moment(field.value));
      }
    },
  };

  const inputLabelProps = {
    shrink: true,
  };

  if (StartIcon) {
    inputProps.startAdornment = (
      <InputAdornment position="start">{StartIcon}</InputAdornment>
    );
  }

  if (EndIcon) {
    inputProps.endAdornment = (
      <InputAdornment position="end">{EndIcon}</InputAdornment>
    );
  }

  const changeHandler = (event: PickerChangeEvent) => {
    const endDate = new Date(event?.target?.value);
    if (event instanceof Date) {
      helpers.setValue(event);
      return;
    }
    const value = event?.target?.value;
    if (!value) {
      return;
    }
    helpers.setValue(new Date(value));
    setFieldValue && setFieldValue(rest.name, endDate.getTime());
  };

  const changeMuiHandler = (date: any) => {
    setMomentValue(date);

    if (!date) {
      helpers.setValue("");
      onDateChange(0);
    }

    if (date?.isValid()) {
      const timestamp = +moment(date).format("x");
      helpers.setValue(timestamp);
      onDateChange(timestamp);
    }
  };

  const pickerProps = {
    onChange: useMUIPicker ? changeMuiHandler : changeHandler,
    error: isError,
    disabled,
    label,
    placeholder,
    inputProps: { step: 1800 },
    fullWidth: true,
    InputProps: inputProps,
    InputLabelProps: inputLabelProps,
    inputRef: ref,
  };

  const renderError = (text?: string) => (
    <span>
      <Error />
      {text}
    </span>
  );

  const onError = (error) => {
    if (error && !isPickerError) {
      setIsPickerError(true);
      return;
    }

    if (!error && isPickerError) {
      setIsPickerError(false);
    }
  };

  let picker: React.ReactElement;
  if (useMUIPicker || (!IS_CHROME && !IS_SAFARI)) {
    picker = (
      <DesktopDateTimePicker
        value={momentValue}
        onError={onError}
        format={format}
        ampm={false}
        {...pickerProps}
      />
    );
  } else {
    picker = (
      <TextField
        {...pickerProps}
        label={label}
        required={mandatory}
        value={date2inputValue(new Date(field.value))}
        variant="outlined"
        type="datetime-local"
        className={`${mode == "dark" ? "calendar-dark" : ""}`}
      />
    );
  }

  return (
    <div>
      {picker}
      {isError && !isPickerError && renderError(meta.error)}
    </div>
  );
};

export const DateTimeInput = forwardRef(DateTimeInputComponent);
