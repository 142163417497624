import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { convertToDuration } from "@ero/app-common/util/convertDifficulty";
import { numberAsCurrency } from "@ero/app-common/util/numberFormatter";
import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { UNIT } from "@ero/app-common/util/Units";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import i18n from "i18next";
import { getBillingUnitNameByValue } from "Utils";

export const getColumns = (
  unitOfMeasurement: UNIT,
): GridColDef<GridValidRowModel>[] => [
  {
    field: "internalId",
    headerName: i18n.t("articles.fields.id"),
  },
  {
    field: "name",
    headerName: i18n.t("general.fields.name"),
  },
  {
    field: "description",
    headerName: i18n.t("general.fields.description"),
  },
  {
    field: "billingUnit",
    headerName: i18n.t("articles.fields.billing_unit"),
    valueGetter: (params) =>
      getBillingUnitNameByValue(params.row.billingUnit, unitOfMeasurement),
  },
  {
    field: "cost",
    headerName: i18n.t("articles.fields.cost"),
    valueGetter: (params) => numberAsCurrency(params.row.cost),
  },
  {
    field: "difficulty",
    headerName: i18n.t("services.fields.areaPerformance", {
      unit: UnitSymbol[unitOfMeasurement],
    }),
    valueGetter: (params) =>
      convertToDuration(params.row.difficulty * 1000, unitOfMeasurement, true),
  },
  {
    field: "notes",
    headerName: i18n.t("general.fields.notes"),
  },
  {
    field: "additionalName",
    headerName: i18n.t("services.fields.additional_name"),
  },
  {
    field: "serviceKind",
    headerName: i18n.t("services.fields.serviceKind"),
    valueGetter: (params) => {
      if (params.value._id === -1) {
        return "";
      }
      return params.value.name;
    },
  },
];

export const defaultHiddenColumns = {
  internalId: false,
};

export const defaultHiddenColumnsCreateOrder = {
  internalId: false,
  description: false,
  cost: false,
  notes: false,
  additionalName: false,
  serviceKind: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "number", access: ["internalId"], default: "-" },
  { type: "string", access: ["name"] },
  { type: "string", access: ["description"] },
  { type: "string", access: ["notes"] },
  { type: "string", access: ["additionalName"] },
];
