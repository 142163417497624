import { type Coordinate } from "@ero/app-common/util/Coordinate";
import { type MachineResponseBody } from "@ero/app-common/v2/routes/models/machine";
import { type ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { SelectionTypeEnum } from "Constants";
import { InfoWindowV2 } from "ProjectComponents";
import { getParcelPosition } from "Utils";
import React, { useEffect, useState } from "react";
import { MachineInfoWindowV2 } from "./machineInfoWindow";
import { ParcelInfoWindowV2 } from "./parcelInfoWindow";

interface IInfoWindows {
  parcels: (Partial<ParcelResponseBody> & { _id: number })[];
  machines: MachineResponseBody[];
  showInfoWindow: boolean;
  setShowInfoWindow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedId: number | undefined;
  selectionType: SelectionTypeEnum;
  handleEditMapObject: (
    type: "machine" | "parcel" | "job",
    entry: number,
    orderId?: number | undefined,
  ) => void;
}

export const InfoWindows: React.FC<IInfoWindows> = ({
  parcels,
  machines,
  showInfoWindow,
  setShowInfoWindow,
  selectedId,
  selectionType,
  handleEditMapObject,
}) => {
  const [position, setPosition] = useState<Coordinate | google.maps.LatLng>();
  const [options, setOptions] = useState<google.maps.InfoWindowOptions>();
  const [infoParcel, setInfoParcel] = useState<
    Partial<ParcelResponseBody> & { _id: number }
  >();
  const [infoMachine, setInfoMachine] = useState<MachineResponseBody>();

  useEffect(() => {
    if (selectedId) {
      if (selectionType === SelectionTypeEnum.Parcels) {
        const parcel = parcels.find((parcel) => parcel._id === selectedId);
        if (parcel) {
          const position = getParcelPosition(parcel);
          setInfoParcel(parcel);
          setPosition(position);
          if (!parcel.shape) {
            setOptions({ pixelOffset: new google.maps.Size(0, -30) });
          } else {
            setOptions({});
          }
        }
      }
      if (selectionType === SelectionTypeEnum.Machines) {
        const machine = machines.find((machine) => machine._id === selectedId);
        if (machine) {
          setInfoMachine(machine);
          setPosition(machine?.position);
          setOptions({
            pixelOffset: new google.maps.Size(0, -10),
          });
        }
      }
    }
  }, [selectedId, selectionType, machines, parcels]);

  return (
    <>
      {showInfoWindow && selectedId && position && (
        <InfoWindowV2
          position={position}
          options={options}
          onCloseClick={() => setShowInfoWindow(false)}
        >
          <>
            {selectionType === SelectionTypeEnum.Parcels && infoParcel && (
              <ParcelInfoWindowV2
                parcel={infoParcel}
                handleEdit={handleEditMapObject}
              />
            )}
            {selectionType === SelectionTypeEnum.Machines && infoMachine && (
              <MachineInfoWindowV2
                machine={infoMachine}
                handleEdit={handleEditMapObject}
              />
            )}
          </>
        </InfoWindowV2>
      )}
    </>
  );
};
