import { Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const Imprint: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Container maxWidth={"lg"} sx={{ pt: 2 }}>
      <Typography variant="h4" gutterBottom>
        {t("static.imprint.title")}
      </Typography>
      <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
        {t("static.imprint.text")}
      </Typography>
    </Container>
  );
};
