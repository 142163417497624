import CallSplitIcon from "@mui/icons-material/CallSplit";
import ClearIcon from "@mui/icons-material/Clear";
import MergeIcon from "@mui/icons-material/Merge";
import SaveIcon from "@mui/icons-material/Save";
import { Fab, Tooltip } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { POLYGON_EDIT_MODE } from "./utils";

type Props = {
  editMode: POLYGON_EDIT_MODE;
  enableMergeMode: () => void;
  enableSplitMode: () => void;
  cancelOperation: () => void;
  mergeParcels: () => void;
  splitParcel: () => void;
};

export const SplitMergeControls: React.FC<Props> = ({
  editMode,
  enableMergeMode,
  enableSplitMode,
  cancelOperation,
  mergeParcels,
  splitParcel,
}) => {
  return (
    <>
      {editMode === POLYGON_EDIT_MODE.NONE && (
        <>
          <Tooltip title={t("parcels.upload.buttons.mergeParcel")}>
            <Fab onClick={enableMergeMode}>
              <MergeIcon />
            </Fab>
          </Tooltip>
          <Tooltip title={t("parcels.upload.buttons.splitParcel")}>
            <Fab onClick={enableSplitMode}>
              <CallSplitIcon />
            </Fab>
          </Tooltip>
        </>
      )}
      {editMode !== POLYGON_EDIT_MODE.NONE && (
        <>
          <Tooltip title={t("general.buttons.cancel")}>
            <Fab onClick={cancelOperation}>
              <ClearIcon />
            </Fab>
          </Tooltip>
          <Tooltip title={t("general.buttons.save")}>
            <Fab
              onClick={
                editMode === POLYGON_EDIT_MODE.MERGE
                  ? mergeParcels
                  : splitParcel
              }
            >
              <SaveIcon />
            </Fab>
          </Tooltip>
        </>
      )}
    </>
  );
};
