import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { App } from "App";
import React from "react";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.RELEASE,
    environment: process.env.REACT_APP_LOGIN_DOMAIN,
  });
}

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
