import { Box, Stack, Typography } from "@mui/material";
import Logo from "Assets/Logo_EROplan_RGB.png";
import LogoNegativ from "Assets/Logo_EROplan_negativ_RGB.png";
import { ROUTES } from "Constants";
import { useQueryParams } from "Hooks/queryParams";
import { DarkModeSwitch, LangChooseSwitch } from "ProjectComponents";
import { type AppState } from "Store";
import { resetMeta, resetPassword } from "Store/auth";
import { type RESET_PASS_DTO } from "Types";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getValidationSchema, initialValues } from "./formConfig";
import { FormContent } from "./formContent";

export const ResetPassword: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    colorMode,
    success,
    tokens: { resetToken: resetTokenAppState },
  } = useSelector((state: AppState) => state.auth);

  const { token: resetTokenURL } = useQueryParams("token");

  useEffect(() => {
    return (): void => {
      dispatch(resetMeta());
    };
  }, [dispatch]);

  const handleFormSubmit = (values: RESET_PASS_DTO) => {
    const resetTokenToBeUsed = !resetTokenAppState
      ? resetTokenURL
      : resetTokenAppState;
    const data = {
      newPassword: values.password,
      resetToken: resetTokenToBeUsed as string,
    };
    dispatch(resetPassword(data));
  };

  if (success) {
    return <Navigate to={ROUTES.AUTH.LOGIN} replace />;
  }

  if (!resetTokenURL && !resetTokenAppState) {
    return <Navigate to={ROUTES.AUTH.LOGIN} />;
  }

  return (
    <>
      <img
        src={colorMode === "light" ? Logo : LogoNegativ}
        alt="logo"
        style={{ maxWidth: "100%", padding: 32 }}
      />
      <Box>
        <Stack direction={"row"} justifyContent={"center"} spacing={2} pb={2}>
          <LangChooseSwitch />
          <DarkModeSwitch />
        </Stack>
        <Typography variant="h4" textAlign="center" pb={2}>
          {t("auth.reset_password.title")}
        </Typography>
        <Typography variant="body1" textAlign="center" pb={2}>
          {t("auth.reset_password.subtitle")}
        </Typography>
      </Box>
      <Formik
        validationSchema={getValidationSchema()}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        component={FormContent}
      />
    </>
  );
};
