import { RegularButton } from "Components";
import React from "react";
import { useTranslation } from "react-i18next";

interface BackButton {
  goBackToMainContent: () => void;
}

export const BackButton: React.FC<BackButton> = ({ goBackToMainContent }) => {
  const [t] = useTranslation();

  return (
    <RegularButton onClick={goBackToMainContent}>
      {t("general.buttons.back")}
    </RegularButton>
  );
};
