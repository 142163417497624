import background1 from 'Assets/auth-background/auth-background1.jpg';
import background2 from 'Assets/auth-background/auth-background2.jpg';
import background3 from 'Assets/auth-background/auth-background3.jpg';
import background4 from 'Assets/auth-background/auth-background4.jpg';

const backgrounds = {
  background1,
  background2,
  background3,
  background4,
};



const keys = Object.keys(backgrounds) as (keyof typeof backgrounds)[];
const amount = keys.length;

const getRandomKey = (): keyof typeof backgrounds => {
  const index = Math.floor(Math.random() * amount);
  return keys[index];
};

const randomKey = getRandomKey();

export const useAuthBackground = () => backgrounds[randomKey];
