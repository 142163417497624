import { getAllDropdowns } from "Store/organization";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Box, Grid } from "@mui/material";
import { AccordionWrapper } from "./components/accordion/accordion";
import {
  ORGANIZATION_TYPE,
  customerCatgeories,
  drivingLicenseTypes,
  machineManufacturers,
  parcels,
  serviceTypes,
} from "./config";

export const Organization: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    Object.values(ORGANIZATION_TYPE).forEach((value) => {
      dispatch(getAllDropdowns(value));
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container height="100%">
      <Grid item xs={12}>
        <Box padding="16px" height="100%">
          <Box component="h3">{t("general.navigation.parcels") as string}</Box>
          {parcels().map((category) => (
            <AccordionWrapper
              key={`p${category.id}`}
              expanded={expanded}
              category={category}
              onChange={handleChange}
            />
          ))}
          <Box component="h3">{t("general.navigation.workers") as string}</Box>
          {drivingLicenseTypes().map((category) => (
            <AccordionWrapper
              key={`dl${category.id}`}
              expanded={expanded}
              category={category}
              onChange={handleChange}
            />
          ))}
          <Box component="h3">{t("general.navigation.machines") as string}</Box>
          {machineManufacturers().map((category) => (
            <AccordionWrapper
              key={`mm${category.id}`}
              expanded={expanded}
              category={category}
              onChange={handleChange}
            />
          ))}
          <Box component="h3">
            {t("general.navigation.customers") as string}
          </Box>
          {customerCatgeories().map((category) => (
            <AccordionWrapper
              key={`c${category.id}`}
              expanded={expanded}
              category={category}
              onChange={handleChange}
            />
          ))}
          <Box component="h3">
            {t("general.navigation.positions") as string}
          </Box>
          {serviceTypes().map((category) => (
            <AccordionWrapper
              key={`st${category.id}`}
              expanded={expanded}
              category={category}
              onChange={handleChange}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
