import { Box, Button, Container } from "@mui/material";
import error404Image from "Assets/error404.svg";
import { type AppState } from "Store";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const NotFound: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state: AppState) => state.auth.tokens);

  const buttonText = accessToken
    ? t("error404.button_to_dashboard")
    : t("error404.button_to_login");

  const buttonOnClick = () => {
    navigate("/dashboard");
  };

  return (
    <Container fixed sx={{ height: "calc(100vh - 16px)", textAlign: "center" }}>
      <Box
        height="80%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          component="img"
          width="80%"
          src={error404Image}
          alt="error404"
          margin="auto"
        />
        <Box component="h1">{t("error404.label")}</Box>
      </Box>
      <Box height="20%">
        <Box>
          <Button size="large" variant="contained" onClick={buttonOnClick}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
