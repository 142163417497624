import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

interface IQuestion {
  question: {
    _id: number;
    videoLink: string;
    question: string;
    answer: string;
  };
  isExpanded: string | false;
  setIsExpanded: Dispatch<SetStateAction<string | false>>;
}

export const Question: React.FC<IQuestion> = ({
  question,
  isExpanded,
  setIsExpanded,
}) => {
  const splittedVideoLink = question.videoLink.split("/watch?v=");

  const embedVideoLink =
    splittedVideoLink[0] + "/embed/" + splittedVideoLink[1];

  return (
    <Grid item xs={12}>
      <Accordion
        expanded={isExpanded === question._id.toString()}
        onChange={() => {
          setIsExpanded(
            isExpanded === question._id.toString()
              ? false
              : question._id.toString(),
          );
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined color="primary" />}
          id={question._id.toString()}
        >
          <Typography variant="body1">{question.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <iframe
            width="750"
            height="350"
            frameBorder={0}
            src={embedVideoLink}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
