import { Radio } from "@mui/material";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridApi,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowClassNameParams,
  GridRowId,
  GridRowIdGetter,
  GridRowModesModel,
  GridRowParams,
  GridValidRowModel,
  MuiEvent,
} from "@mui/x-data-grid";
import { Loader } from "Components";
import React, { useCallback, useMemo, useState } from "react";
import { StripedDataGrid } from "../components/StripedDataGrid";
import {
  SelectionTableToolbar,
  SelectionTableToolbarProps,
} from "./components/SelectionTableToolbar";

const columnDefaults: Omit<GridColDef, "field"> = {
  flex: 1,
  minWidth: 70,
  sortable: false,
};

export type SelectionTableProps = {
  loading: boolean;
  columns: GridColDef<GridValidRowModel>[];
  rows: GridValidRowModel[];
  apiRef?: React.MutableRefObject<GridApi>;
  maxCount?: number;
  handlePagination?: (page: number, pageSize: number) => void;
  toolbarProps?: Omit<SelectionTableToolbarProps, "setColumnsButtonEl">;
  handleQuickSearch?: (query: string) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (newMode: GridColumnVisibilityModel) => void;
  rowSelectionModel: GridRowId[];
  onRowSelectionModelChange: (ids: GridRowId[]) => void;
  singleSelect?: boolean;
  clientSidePagination?: boolean;
  clientSideFiltering?: boolean;
  processRowUpdate?: (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel,
  ) => GridValidRowModel;
  onProcessRowUpdateError?: (error: any) => void;
  disableRowSelectionOnClick?: boolean;
  rowModesModel?: GridRowModesModel;
  onRowClick?: (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails,
  ) => void;
  onRowEditStop?: (e) => void;
};

const getRowClassName = (params: GridRowClassNameParams<GridValidRowModel>) =>
  (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd") +
  (params.row.disabled ? " disabled-row" : "");

const getRowId: GridRowIdGetter<GridValidRowModel> = (row) => row._id;

const initialState = {
  pagination: {
    paginationModel: {
      pageSize: 100,
    },
  },
  filter: {
    filterModel: {
      items: [],
      quickFilterExcludeHiddenColumns: true,
    },
  },
};

export const SelectionTable: React.FC<SelectionTableProps> = ({
  loading,
  columns,
  rows,
  apiRef,
  maxCount,
  handlePagination,
  toolbarProps,
  handleQuickSearch,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  rowSelectionModel,
  onRowSelectionModelChange,
  singleSelect = false,
  clientSidePagination = false,
  clientSideFiltering = false,
  processRowUpdate,
  onProcessRowUpdateError,
  disableRowSelectionOnClick = false,
  rowModesModel = undefined,
  onRowClick,
  onRowEditStop,
}) => {
  const [columnsButtonEl, setColumnsButtonEl] =
    useState<HTMLButtonElement | null>(null);

  const columnsWithDefaults: GridColDef<GridValidRowModel>[] = useMemo(() => {
    const columnsWithDefaults = columns.map((column) => ({
      ...columnDefaults,
      ...column,
    }));
    const selectBoxColumn: GridColDef<GridValidRowModel> = {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      ...(singleSelect ? { renderHeader: () => <></> } : {}),
    };
    return [selectBoxColumn, ...columnsWithDefaults];
  }, [columns, singleSelect]);

  const handleRowSelect = useCallback(
    (ids: GridRowId[]) => {
      if (singleSelect) {
        onRowSelectionModelChange(ids.slice(-1));
      } else {
        onRowSelectionModelChange(ids);
      }
    },
    [singleSelect, onRowSelectionModelChange],
  );

  const slots = useMemo(
    () => ({
      toolbar: SelectionTableToolbar,
      loadingOverlay: Loader,
      ...(singleSelect ? { baseCheckbox: Radio } : {}),
    }),
    [singleSelect],
  );

  const slotProps = useMemo(
    () => ({
      panel: {
        anchorEl: columnsButtonEl,
      },
      toolbar: { ...toolbarProps, setColumnsButtonEl },
      columnsPanel: {
        disableHideAllButton: true,
        disableShowAllButton: true,
      },
    }),
    [columnsButtonEl, toolbarProps, setColumnsButtonEl],
  );

  const onPaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      handlePagination && handlePagination(model.page, model.pageSize);
    },
    [handlePagination],
  );

  const onFilterModelChange = useCallback(
    (model) => {
      if (model.quickFilterValues) {
        handleQuickSearch &&
          handleQuickSearch(model.quickFilterValues.join(" "));
      }
    },
    [handleQuickSearch],
  );

  const onColumnVisibilityModelChangeInternal = useCallback(
    (model: GridColumnVisibilityModel) => {
      const newHiddenColumns = { ...columnVisibilityModel, ...model };
      onColumnVisibilityModelChange &&
        onColumnVisibilityModelChange(newHiddenColumns);
    },
    [columnVisibilityModel, onColumnVisibilityModelChange],
  );

  return (
    <StripedDataGrid
      initialState={initialState}
      loading={loading}
      editMode="row"
      rowModesModel={rowModesModel}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={onProcessRowUpdateError}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      pagination={true}
      paginationMode={clientSidePagination ? "client" : "server"}
      filterMode={clientSideFiltering ? "client" : "server"}
      rowCount={maxCount}
      onPaginationModelChange={onPaginationModelChange}
      columns={columnsWithDefaults}
      apiRef={apiRef}
      rows={rows}
      getRowId={getRowId}
      getRowClassName={getRowClassName}
      checkboxSelection
      disableColumnMenu
      onRowSelectionModelChange={handleRowSelect}
      keepNonExistentRowsSelected
      rowSelectionModel={rowSelectionModel}
      slots={slots}
      slotProps={slotProps}
      onFilterModelChange={onFilterModelChange}
      onColumnVisibilityModelChange={onColumnVisibilityModelChangeInternal}
      columnVisibilityModel={columnVisibilityModel}
      onRowClick={onRowClick}
      onRowEditStop={onRowEditStop}
    />
  );
};
