import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { HelpText } from "ProjectComponents/helpText";
import React from "react";
import { useTranslation } from "react-i18next";

interface ICreateHelpModal {
  translations: string;
  isOpen: boolean;
  onClose: () => void;
}

export const CreateHelpModal: React.FC<ICreateHelpModal> = ({
  translations,
  onClose,
  isOpen,
}) => {
  const [t] = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t(`${translations}.title`)}</DialogTitle>
      <DialogContent dividers>
        <HelpText translations={translations}></HelpText>
      </DialogContent>
    </Dialog>
  );
};
