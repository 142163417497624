import { useFormikContext } from "formik";
import { CenterControlV2, MapV2, ZoomControlV2 } from "ProjectComponents/map";
import { BottomControls } from "ProjectComponents/map/components/controls";
import { useParcelCenter } from "ProjectComponents/map/hooks/centerOfMass";
import { FormikValues } from "ProjectComponents/parcelInformationModal/validationConfig";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { DrawingModeToggle } from "./drawingModeToggle";
import { GeoElements } from "./geoElements";
import { InfoAlert } from "./infoAlert";
import { useGeoHelpers } from "./useGeoHelpers";
import { DRAWINGMODE } from "./utils";

export const ParcelMap: React.FC = () => {
  const { values } = useFormikContext<FormikValues>();

  const parcelCenter = useParcelCenter({
    shape: values.shape,
    position: values.position,
  });

  const map = useRef<google.maps.Map>();

  const [drawingMode, setDrawingMode] = useState<DRAWINGMODE>(
    values.position ? DRAWINGMODE.MARKER : DRAWINGMODE.POLYGON,
  );

  const {
    handleMapClick,
    handleMarkerDrag,
    handlePolyVertexDrag,
    handlePolyVertexDragEnd,
  } = useGeoHelpers(drawingMode);

  const { companyData } = useSelector((store: AppState) => store.auth);

  const initialCenter = useMemo(
    () => parcelCenter ?? companyData.machinePosition,
    // do not add dependencies so the initial center is not recomputed each and every time lastCente changes
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const resetCenter = useCallback(() => {
    if (initialCenter) {
      map.current?.setCenter(initialCenter);
    }
  }, [initialCenter]);

  const bounds = useMemo(() => {
    if (!values.position && !values.shape?.length) return undefined;
    const bounds = new google.maps.LatLngBounds();

    if (values.position) {
      bounds.extend(values.position);
    }
    values.shape?.forEach((coord) => {
      bounds.extend(coord);
    });
    return bounds;
  }, [values.position, values.shape]);

  const onLoad = useCallback(
    (mapInstance: google.maps.Map) => {
      map.current = mapInstance;
      bounds && map.current.fitBounds(bounds);
    },
    [bounds],
  );

  const onZoomChanged = useCallback((zoom?: number) => {
    if (zoom) {
      map.current?.setZoom(zoom);
    }
  }, []);

  const cursor = useMemo(
    () =>
      drawingMode === DRAWINGMODE.MARKER ||
      (drawingMode === DRAWINGMODE.POLYGON && !values.isClosedPolygon)
        ? "crosshair"
        : "",
    [drawingMode, values.isClosedPolygon],
  );

  return (
    <MapV2
      id="parcel-modal-map"
      options={{ draggableCursor: cursor }}
      center={initialCenter}
      zoom={16}
      onLoad={onLoad}
      onZoomChanged={onZoomChanged}
      onClick={handleMapClick}
    >
      <InfoAlert
        drawingMode={drawingMode}
        polyCoordsCount={values.shape?.length}
      />
      <DrawingModeToggle
        drawingMode={drawingMode}
        setDrawingMode={setDrawingMode}
      />
      <GeoElements
        drawingMode={drawingMode}
        handleMarkerDrag={handleMarkerDrag}
        handlePolyVertexDrag={handlePolyVertexDrag}
        handleMapClick={handleMapClick}
        handlePolyVertexDragEnd={handlePolyVertexDragEnd}
      />

      <BottomControls>
        <CenterControlV2 onCenter={resetCenter}></CenterControlV2>
        <ZoomControlV2
          zoom={map.current?.getZoom() ?? 16}
          onZoomChanged={onZoomChanged}
        />
      </BottomControls>
    </MapV2>
  );
};
