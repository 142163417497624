import { Coordinate } from "@ero/app-common/util/Coordinate";
import { area, polygon as createPolygon, lineString } from "@turf/turf";
import { SPLITTING_ERROR } from "../utils";
import { cutPolygon } from "./turf/polygonCut";
import { PolygonWithSize } from "./types";

export const split = (
  parcelPolygon: Coordinate[],
  splitLineCoords: google.maps.LatLng[],
): PolygonWithSize[] => {
  const geoJSONCoordsParcel = parcelPolygon.map((coord) => [
    coord.lng,
    coord.lat,
  ]);

  const lineGeoJSON = lineString(
    splitLineCoords.map((coord) => [coord.lng(), coord.lat()]),
  );

  const newPolygons = cutPolygon(
    createPolygon([geoJSONCoordsParcel]),
    lineGeoJSON,
  );

  if (newPolygons) {
    const parcelsWithShapeAndArea: PolygonWithSize[] = [];

    newPolygons.features.forEach((poly) => {
      const shape = poly.geometry.coordinates.map((coordinates) =>
        coordinates.map((coord) => {
          return {
            lng: coord[0],
            lat: coord[1],
          } as Coordinate;
        }),
      )[0];

      const size = area(poly.geometry);

      parcelsWithShapeAndArea.push({ shape, size });
    });

    return parcelsWithShapeAndArea;
  }

  throw new Error("Unable to split polygon", {
    cause: SPLITTING_ERROR.SAVING_FAILED,
  });
};
