import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowId,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { ConfirmDialog, Loader } from "Components";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StripedDataGrid } from "../components/StripedDataGrid";
import {
  BaseDataTableToolbar,
  BaseDataTableToolbarProps,
} from "./components/BaseDataToolbar";

const columnDefaults: Omit<GridColDef, "field"> = {
  flex: 1,
  minWidth: 70,
  sortable: false,
};

export type BaseTableProps = {
  loading: boolean;
  columns: GridColDef<GridValidRowModel>[];
  rows: GridValidRowModel[];
  maxCount?: number;
  handlePagination: (page: number, pageSize: number) => void;
  onRowClick: (id: number) => void;
  toolbarProps: Omit<
    BaseDataTableToolbarProps,
    "showDelete" | "onDeleteClick" | "setColumnsButtonEl"
  > & { onDeleteClick?: (ids: GridRowId[]) => void };
  handleQuickSearch: (query: string) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (newMode: GridColumnVisibilityModel) => void;
  paginationModel?: GridPaginationModel;
  searchQuery?: string;
};

export const BaseDataTable: React.FC<BaseTableProps> = ({
  loading,
  columns,
  rows,
  maxCount,
  handlePagination,
  onRowClick,
  toolbarProps,
  handleQuickSearch,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  paginationModel,
  searchQuery,
}) => {
  const [t] = useTranslation();

  const [columnsButtonEl, setColumnsButtonEl] =
    useState<HTMLButtonElement | null>(null);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const columnsWithDefaults: GridColDef<GridValidRowModel>[] = useMemo(() => {
    const columnsWithDefaults = columns.map((column) => ({
      ...columnDefaults,
      ...column,
    }));
    const selectBoxColumn = {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
    };
    return [selectBoxColumn, ...columnsWithDefaults];
  }, [columns]);

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

  const showDelete = useMemo(
    () => toolbarProps.onDeleteClick && selectedRows.length > 0,
    [selectedRows.length, toolbarProps.onDeleteClick],
  );

  const onDeleteClick = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, []);

  const handleDeleteApprove = useCallback(() => {
    setIsConfirmModalOpen(false);
    toolbarProps.onDeleteClick && toolbarProps.onDeleteClick(selectedRows);
  }, [toolbarProps, selectedRows]);

  return (
    <>
      <StripedDataGrid
        initialState={{
          pagination: {
            paginationModel: {
              ...{
                pageSize: 25,
              },
              ...paginationModel,
            },
          },
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
        }}
        loading={loading}
        paginationMode="server"
        rowCount={maxCount}
        onPaginationModelChange={(model) =>
          handlePagination(model.page, model.pageSize)
        }
        paginationModel={paginationModel}
        columns={columnsWithDefaults}
        rows={rows}
        getRowId={(row) => row._id}
        onRowClick={(row) => onRowClick(row.row._id)}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnMenu
        onRowSelectionModelChange={setSelectedRows}
        slots={{ toolbar: BaseDataTableToolbar, loadingOverlay: Loader }}
        slotProps={{
          panel: {
            anchorEl: columnsButtonEl,
          },
          toolbar: {
            ...toolbarProps,
            showDelete,
            onDeleteClick,
            setColumnsButtonEl,
          },
          columnsPanel: {
            disableHideAllButton: true,
            disableShowAllButton: true,
          },
        }}
        onFilterModelChange={(model) => {
          if (model.quickFilterValues) {
            handleQuickSearch(model.quickFilterValues.join(" "));
          }
        }}
        filterMode={"server"}
        onColumnVisibilityModelChange={(model) => {
          const newHiddenColumns = { ...columnVisibilityModel, ...model };
          onColumnVisibilityModelChange &&
            onColumnVisibilityModelChange(newHiddenColumns);
        }}
        columnVisibilityModel={columnVisibilityModel}
        sx={{ overflow: "hidden" }}
        {...(searchQuery && {
          filterModel: {
            items: [],
            quickFilterValues: [searchQuery],
          },
        })}
      />
      <ConfirmDialog
        isOpen={isConfirmModalOpen}
        onApprove={handleDeleteApprove}
        onClose={() => setIsConfirmModalOpen(false)}
        title={t("delete_dialog.title")}
        text={t(`delete_dialog.textFirstPart`, {
          count: selectedRows.length,
        })}
        text_2={t("delete_dialog.textSecondPart")}
        positiveButtonText={t("delete_dialog.deleteButton")}
        negativeButtonText={t("delete_dialog.cancelButton")}
      />
    </>
  );
};
