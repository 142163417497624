import { ButtonBase, Container, Grid, Stack, Typography } from "@mui/material";
import { ROUTES } from "Constants/routes";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HistoryItem } from "./historyItem";

interface IProps {
  history: any;
  info: any;
}

export const HistoryList: React.FC<IProps> = ({
  history: historyItems,
  info,
}: IProps) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const historyItemsList = useMemo(() => {
    const handleGoToJob = (item: any, index: number): void => {
      const route = [
        ROUTES.MAIN.ORDERS,
        "/",
        info[index].order,
        ROUTES.MAIN.ORDER_DETAILS.JOBS,
        "/",
        item.jobId,
      ].join("");
      navigate(route);
    };

    const renderHistoryItem = (item: any, index: number) => {
      return (
        <ButtonBase
          onClick={() => handleGoToJob(item, index)}
          key={`bb${item._id}`}
        >
          <HistoryItem
            item={item}
            index={index}
            itemList={historyItems}
            key={`hi${item._id}`}
          />
        </ButtonBase>
      );
    };

    return (
      historyItems?.length > 0 && (
        <Stack>
          {historyItems.map((item: any, index: number) =>
            renderHistoryItem(item, index),
          )}
        </Stack>
      )
    );
  }, [historyItems, info, navigate]);

  return (
    <Container sx={{ minHeight: "40vh" }}>
      {historyItemsList}
      {historyItems?.length == 0 && (
        <Grid item sx={{ textAlign: "center", marginTop: "40px" }} xs={12}>
          <Typography variant={"body1"}>
            {t("parcels.jobHistory.no_jobs_available")}
          </Typography>
        </Grid>
      )}
    </Container>
  );
};
