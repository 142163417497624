import { type MachineResponseBody } from "@ero/app-common/v2/routes/models/machine";
import { MachineV2 } from "ProjectComponents/map/components";
import React from "react";

interface IMachines {
  machines: MachineResponseBody[];
  itemOnClick?: (id: number) => void;
}

export const Machines: React.FC<IMachines> = ({ machines, itemOnClick }) => (
  <>
    {machines.map((machine) => {
      return (
        <MachineV2
          key={machine._id}
          machine={machine}
          position={machine.position}
          itemOnClick={itemOnClick}
        ></MachineV2>
      );
    })}
  </>
);
