import { AccountCircleRounded } from "@mui/icons-material";
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  TextField,
  useTheme,
} from "@mui/material";
import { Loader } from "Components";
import { PasswordInput } from "Components/controls/PasswordInput";
import { ROUTES } from "Constants/routes";
import { type AppState } from "Store";
import { Field, Form } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const FormContent: React.FC = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const { loading } = useSelector((state: AppState) => state.auth);

  return (
    <Form>
      <List>
        <ListItem>
          <Field name="userName">
            {({ field, meta }) => (
              <TextField
                {...field}
                sx={{
                  backgroundColor: theme.palette.background.default,
                }}
                type="text"
                variant="outlined"
                label={t("general.fields.userName")}
                fullWidth
                color="primary"
                error={!!meta.error}
                autoComplete="off"
                placeholder="Username"
                helperText={meta.error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircleRounded
                        sx={{
                          color:
                            theme.palette.mode === "dark" ? "white" : "grey",
                          margin: 1,
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Field>
        </ListItem>
        <ListItem>
          <PasswordInput name="password" label={t("general.fields.password")} />
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            startIcon={loading && <Loader size={30} />}
          >
            {t("auth.login.button")}
          </Button>
        </ListItem>
        <ListItem>
          <Button
            onClick={() => {
              navigate(ROUTES.AUTH.FORGOT_PASSWORD);
            }}
            color="primary"
            variant="text"
            fullWidth
          >
            {t("auth.forgot_password.link")}
          </Button>
        </ListItem>
      </List>
    </Form>
  );
};
