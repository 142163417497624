import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Edit, Person } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { MachineSvg } from "Assets/icons";
import { ROUTES } from "Constants";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IDriverMachineCard {
  job?: JobResponseBody;
}

export const DriverMachineCard: React.FC<IDriverMachineCard> = ({ job }) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const navigateToPlanning = useCallback(() => {
    const state: {
      initialDate?: number;
      scrollTime?: number;
      orderId?: number;
    } = {};
    if (job?.start !== -1 && job?.end !== -1) {
      state.initialDate = job?.start;
      state.scrollTime = job?.start;
    } else {
      state.orderId = job.order;
    }
    navigate(`${ROUTES.MAIN.PLANNING}`, {
      state,
    });
  }, [job, navigate]);

  const [driverName, driverAvatar] = useMemo(() => {
    if (!job?.employees.length) {
      return [
        t("orders.create_modal.employees_empty"),
        <Person key="no-driver-avatar" />,
      ];
    }
    return [
      job?.employees[0].firstName
        ? job?.employees[0].firstName + " " + job?.employees[0].lastName
        : job?.employees[0].userName,
      <Avatar
        key={`driver-avatar-${job.employees[0]._id}`}
        alt={job?.employees[0].userName.toUpperCase()}
        src={job?.employees[0].avatar}
      />,
    ];
  }, [job?.employees, t]);

  const [machineName, machineAvatar] = useMemo(() => {
    if (!job?.machine.length) {
      return [
        t("orders.create_modal.machines_empty"),
        <MachineSvg key="no-machine-avatar" />,
      ];
    }
    return [
      job?.machine[0].name,
      <Avatar
        key={`machine-avatar-${job.machine[0]._id}`}
        alt={job?.machine[0].name.toUpperCase()}
        src={job?.machine[0].avatar}
      />,
    ];
  }, [job?.machine, t]);

  return (
    <Card>
      <CardContent
        sx={{
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      >
        <Grid container>
          <Grid item xs={11}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemAvatar>{driverAvatar}</ListItemAvatar>
                <ListItemText
                  primary={driverName}
                  secondary={t("employees.permission.driver")}
                />
              </ListItem>
              <Divider />
              <ListItem disablePadding>
                <ListItemAvatar>{machineAvatar}</ListItemAvatar>
                <ListItemText
                  primary={machineName}
                  secondary={t("general.labels.machine")}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={navigateToPlanning}>
              <Edit fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
