import { Coordinate } from "@ero/app-common/util/Coordinate";
import { Feature, Polygon, Properties, area, polygon } from "@turf/turf";
import { MERGING_ERROR } from "../utils";
import { mergePolygons } from "./turf/polygonMerge";
import { PolygonWithSize } from "./types";

export const merge = (parcelPolygons: Coordinate[][]): PolygonWithSize => {
  if (parcelPolygons.length < 2) {
    throw new Error("Unable to merge parcels", {
      cause: MERGING_ERROR.ONLY_ONE_PARCEL_SELECTED,
    });
  }

  const polygons: Feature<Polygon, Properties>[] = parcelPolygons.map((shape) =>
    polygon([shape.map((coord) => [coord.lng, coord.lat])]),
  );

  const merged = mergePolygons(polygons);

  const shape: Coordinate[] = merged.geometry.coordinates[0].map(
    (coords) =>
      ({
        lng: coords[0] as number,
        lat: coords[1] as number,
      } as Coordinate),
  );

  const size = area(merged);

  return { shape, size };
};
