import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { ChevronRight } from "@mui/icons-material";
import { Fab, Grid } from "@mui/material";
import { Loader } from "Components";
import { type AppState } from "Store";
import {
  filterOrders,
  getCalendarInitial,
  resetMeta,
  setFilters,
} from "Store/planning";
import { FilterType } from "Store/planning/types";
import { getInitialDateRange } from "Utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CalendarWrapper, SelectMachineModal, Sidebar } from "./components";

export const Planning: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    calendarCurrentView,
    initialMeta,
    loading,
    filters: filtersState,
  } = useSelector((state: AppState) => state.planning);
  const { selectedLang } = useSelector((state: AppState) => state.auth);
  const { displayedDateRange } = useSelector(
    (state: AppState) => state.planning,
  );

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [plannedJob, setPlannedJob] = useState<JobResponseBody>();
  const [isSelectMachineModalOpen, setIsSelectMachineModalOpen] =
    useState(false);
  const [droppableContainerEl, setDroppableContainerEl] =
    useState<HTMLDivElement>();

  const initalizeComponent = useCallback(() => {
    const initialDates = getInitialDateRange(selectedLang, calendarCurrentView);

    dispatch(
      getCalendarInitial({
        start: initialDates.start,
        end: initialDates.end,
      }),
    );

    return (): void => {
      dispatch(resetMeta());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarCurrentView, selectedLang]);

  useEffect(() => initalizeComponent(), [initalizeComponent]);

  useEffect(() => {
    const filters: FilterType = { ...filtersState };
    if (filtersState.ordersDateRange) {
      filters.ordersDateRange = displayedDateRange;
    }
    dispatch(setFilters({ filters }));
    dispatch(filterOrders(filters));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedDateRange]);

  const toggleIsSidebarCollapsed = useCallback(() => {
    setIsSidebarCollapsed((curr) => !curr);
  }, []);

  const openSelectMachineModal = useCallback(
    () => setIsSelectMachineModalOpen(true),
    [],
  );

  const closeSelectMachineModal = useCallback(
    () => setIsSelectMachineModalOpen(false),
    [],
  );

  if (initialMeta.loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container height="100%">
        <Grid
          item
          xs={isSidebarCollapsed ? 0 : 12}
          sm={5}
          md={4}
          lg={3}
          xl={2}
          sx={
            isSidebarCollapsed
              ? {
                  display: "none",
                }
              : {}
          }
          height="100%"
        >
          <Sidebar
            toggleSidebar={toggleIsSidebarCollapsed}
            setDroppableContainerEl={setDroppableContainerEl}
          />
        </Grid>
        <Grid
          item
          xs={isSidebarCollapsed ? 12 : 0}
          sm={isSidebarCollapsed ? 12 : 7}
          md={isSidebarCollapsed ? 12 : 8}
          lg={isSidebarCollapsed ? 12 : 9}
          xl={isSidebarCollapsed ? 12 : 10}
          height="100%"
          position="relative"
        >
          {isSidebarCollapsed && (
            <Fab
              sx={{
                position: "absolute",
                top: 23,
                left: 23,
                zIndex: 1000,
              }}
              size="small"
              onClick={toggleIsSidebarCollapsed}
            >
              <ChevronRight fontSize="small" />
            </Fab>
          )}
          <CalendarWrapper
            isSidebarCollapsed={isSidebarCollapsed}
            droppableContainerEl={droppableContainerEl}
            openSelectMachineModal={openSelectMachineModal}
            setPlannedJob={setPlannedJob}
            inititalDate={location.state?.initialDate}
            scrollTime={location.state?.scrollTime}
          />
        </Grid>
      </Grid>
      {loading && <Loader />}
      <SelectMachineModal
        isOpen={isSelectMachineModalOpen}
        onClose={closeSelectMachineModal}
        plannedJob={plannedJob}
      />
    </>
  );
};
