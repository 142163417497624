import { ChevronRight, ErrorOutlineOutlined } from "@mui/icons-material";
import {
  AutocompleteRenderInputParams,
  Autocomplete as MuiAutocomplete,
  SxProps,
  Theme,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { type InputProps } from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { Loader } from "Components/loader";
import { type FetchOptionsFunc, type OptionType, type SizeType } from "Types";
import { useField, type FieldConfig } from "formik";
import React, { useEffect, useMemo } from "react";
import { useHalfClick, useServerSearch } from "./autocomplete-hooks";

interface Select extends FieldConfig {
  label?: string;
  placeholder?: string;
  StartIcon?: JSX.Element;
  EndIcon?: JSX.Element;
  size?: SizeType;
  options?: OptionType[];
  fetchOptions?: FetchOptionsFunc;
  getOptionDisabled?:
    | ((option: OptionType<string | number>) => boolean)
    | undefined;
  freeSolo?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
  limitTags?: number;
  /**
   * If set to true, then
   * clicking on left input side focuses input but prevents popup to open;
   * clicking on right side disables keyboard input, but opens options popup
   */
  extendedKeyboardBehavior?: boolean;
  preventClearAutocomplete?: boolean;
  multipleReturnOptionType?: boolean;
  sx?: SxProps<Theme>;
}

interface Single extends Select {
  onChange?: (value: string | number | undefined) => void;
  multiple?: false;
}

interface Multiple extends Select {
  onChange?: (value: (string | OptionType)[] | undefined) => void;
  multiple: true;
}

export type ISelect = Single | Multiple;

type AutocompleteType = React.ForwardRefRenderFunction<HTMLDivElement, ISelect>;

const chevronIcon = <ChevronRight />;

const AutocompleteComponent: AutocompleteType = (props, ref) => {
  const {
    label,
    placeholder,
    StartIcon,
    EndIcon,
    fetchOptions,
    getOptionDisabled,
    freeSolo,
    disabled,
    mandatory,
    onChange: userOnChange = () => {},
    preventClearAutocomplete = false,
    multiple,
    limitTags = 1,
    multipleReturnOptionType = true,
    sx,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(rest.name);

  // TODO hack(onChange fired twice)
  const isError = Boolean(meta.error && meta.touched) && !field.value;

  //Server Search Hook (Custom Hook)
  const {
    updateOptions,
    updateOptionsDebounced,
    isLoading,
    onListScroll,
    options: finalOptions,
  } = useServerSearch(props);

  const { isRightSideClick, onPointerDown, onBlur } = useHalfClick(props);

  useEffect((): void => {
    if (field.value) {
      userOnChange(field.value);
    }
    if (typeof field.value !== "number" || field.value < 0) {
      return;
    }
    updateOptions(field.value);
  }, [field.value, updateOptions, userOnChange]);

  useEffect((): void => {
    updateOptions();
  }, [updateOptions]);

  const inputProps = useMemo(() => {
    const props: InputProps = {};
    if (!multiple && StartIcon !== undefined) {
      props.startAdornment = (
        <InputAdornment position="start">{StartIcon}</InputAdornment>
      );
    }

    if (EndIcon !== undefined || isLoading) {
      props.endAdornment = (
        <>
          {isLoading && <Loader size={30} />}
          {EndIcon && <InputAdornment position="end">{EndIcon}</InputAdornment>}
        </>
      );
    }

    if (isRightSideClick) {
      props.readOnly = true;
    }

    return props;
  }, [isLoading, StartIcon, EndIcon, isRightSideClick, multiple]);

  const inputLabelProps = { shrink: true };

  const onChange = (
    _event: React.ChangeEvent<EventTarget>,
    newValue: string | OptionType | (string | OptionType)[] | null,
  ): void => {
    if (newValue === null) {
      return;
    }
    if (typeof newValue === "string") {
      return;
    }

    if (Array.isArray(newValue)) {
      // TODO this is a really bad hack to work around the current multivalue logic in calendar sidebar filters. Fix with VIAS-875
      if (multipleReturnOptionType) {
        helpers.setValue(newValue);
      } else {
        helpers.setValue(newValue.map((v) => (v as OptionType).value));
      }
      if (multiple) {
        const { onChange = () => {} } = props;
        onChange(newValue);
      }
      return;
    }

    helpers.setValue(newValue.value);
    if (!multiple) {
      const { onChange = () => {} } = props;
      onChange(newValue.value);
    }
  };

  const onInputChange = (
    event: React.ChangeEvent<EventTarget>,
    value: string,
  ): void => {
    void event;

    const defer = (value: string | undefined): void => {
      helpers.setValue(value);
      if (!multiple) {
        const { onChange = () => {} } = props;
        onChange(value);
      }
    };

    updateOptionsDebounced(value);
    if (value === "") {
      return preventClearAutocomplete ? undefined : defer(undefined);
    }

    return freeSolo ? defer(value) : undefined;
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      error={isError}
      label={label}
      required={mandatory}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      InputProps={{ ...params.InputProps, ...inputProps }}
      InputLabelProps={inputLabelProps}
    />
  );

  const value =
    finalOptions.find((option) => option.value === field.value) ?? null;

  const multiValue: OptionType[] | [] = useMemo(
    () =>
      (multiple &&
        (field.value || [])
          .map((fieldValue) => {
            if (fieldValue.value) {
              return fieldValue;
            } else {
              return finalOptions.find((option) => option.value === fieldValue);
            }
          })
          .filter((v) => v !== undefined)) ||
      [],
    [multiple, field.value, finalOptions],
  );

  // Memoized to prevent unwanted MuiAutocomplete re-rendering
  const memoValue = useMemo(() => value, [value]);
  const multiMemoValue = useMemo(() => multiValue, [multiValue]);

  const extendKbdBehavior = props.extendedKeyboardBehavior;
  const blurOnSelect = extendKbdBehavior;
  const clearOnBlur =
    !extendKbdBehavior && (Boolean(fetchOptions) || !freeSolo);

  // const getOptionSelected = (option, value) =>
  //   option?.label === value?.label || option?.value === value?.value;

  const renderTags = (list: OptionType[], getTagProps) => {
    return list.map((option: OptionType, index: number) => (
      <Chip
        key={index}
        {...getTagProps({ index })}
        label={option.label}
        title={option.label}
      />
    ));
  };

  return (
    <div>
      <MuiAutocomplete
        ref={ref}
        defaultValue={placeholder}
        value={!multiple ? memoValue : multiMemoValue}
        options={finalOptions}
        onChange={onChange}
        onInputChange={onInputChange}
        onPointerDown={onPointerDown}
        onBlur={onBlur}
        // getOptionLabel={(option) => option.label}
        popupIcon={chevronIcon}
        clearOnBlur={clearOnBlur}
        disabled={disabled}
        renderInput={renderInput}
        freeSolo={freeSolo}
        loading={isLoading}
        ListboxProps={{ onScroll: onListScroll }}
        blurOnSelect={blurOnSelect}
        multiple={multiple}
        limitTags={limitTags}
        // getOptionSelected={getOptionSelected}
        renderTags={renderTags}
        filterSelectedOptions={multiple}
        getOptionDisabled={getOptionDisabled}
        sx={sx}
      />
      {isError && (
        <div>
          <ErrorOutlineOutlined />
          {meta.error}
        </div>
      )}
    </div>
  );
};

export const Autocomplete = React.forwardRef(AutocompleteComponent);
