import { AttachFile, Error } from "@mui/icons-material";
import { Chip, Grid, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FieldConfig, useField } from "formik";
import React, { useRef } from "react";

interface FileInputConfig extends FieldConfig {
  label: string;
  buttonlabel: string;
  accept: string[];
  disabled?: boolean;
  starticon?: JSX.Element;
  endicon?: JSX.Element;
  trimfilenames?: boolean;
}

export const FileInput: React.FC<FileInputConfig> = (props) => {
  const {
    label,
    buttonlabel,
    accept,
    disabled,
    starticon,
    endicon,
    trimfilenames,
    ...rest
  } = props;

  const [field, meta, helpers] = useField(rest.name);

  const ref = useRef<HTMLInputElement>(null);

  const clearField = () => {
    helpers.setValue(undefined);
    if (ref.current) {
      ref.current.value = "";
    }
  };

  const trim = (fileName: string) => {
    if (fileName.length < 25) {
      return fileName;
    }

    const lastIndex = fileName.lastIndexOf(".");

    const split = fileName.substring(0, lastIndex);
    const name =
      split.substring(0, 10) + "..." + split.substring(split.length - 5);
    const extension = fileName.substring(lastIndex + 1);

    return name + "." + extension;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5} alignItems="center">
        <Typography variant="subtitle1">{label}</Typography>
      </Grid>
      <Grid item xs={7} alignItems="center" justifyContent="center">
        {!field.value && (
          <label
            htmlFor={field.name}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={disabled}
              startIcon={starticon}
              endIcon={endicon}
            >
              {buttonlabel}
            </Button>
          </label>
        )}
        {field.value && (
          <Chip
            label={trimfilenames ? trim(field.value.name) : field.value.name}
            onDelete={() => clearField()}
            icon={<AttachFile />}
          />
        )}
        <input
          {...field}
          id={field.name}
          type="file"
          accept={accept.join(",")}
          disabled={disabled}
          ref={ref}
          value={undefined}
          onChange={(event) => {
            event.currentTarget &&
              event.currentTarget.files &&
              event.currentTarget.files[0] &&
              helpers.setValue(event.currentTarget.files[0]);
          }}
          style={{ visibility: "hidden" }}
        />
        {meta.error && meta.touched && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Error color="error" />
            <Typography variant="caption" color="error">
              {meta.error}
            </Typography>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};
