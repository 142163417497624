import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";
import { Card, CardContent } from "@mui/material";
import React from "react";
import { StatusesList } from "./statusesList";

interface IStasusesCard {
  statusOperations: JobResponseBody["statusOperations"];
}

export const StatusesCard: React.FC<IStasusesCard> = ({ statusOperations }) => {
  return (
    <Card sx={{ height: "100%", overflow: "auto" }}>
      <CardContent>
        <StatusesList statusOperations={statusOperations} />
      </CardContent>
    </Card>
  );
};
