import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const Terms: React.FC = () => {
  const [t] = useTranslation();

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Container maxWidth={"lg"} sx={{ pb: 2 }}>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h4">{t("static.terms.title")}</Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block1.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block1.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block2.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block2.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block2.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block2.text3")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block3.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block3.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block3.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block4.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block4.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block4.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block4.text3")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block5.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block5.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block6.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block6.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block6.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block6.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block6.text4")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block7.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block7.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block7.text2")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block8.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block8.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block8.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block8.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block8.text4")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block8.text5")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block8.text6")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block8.text7")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block9.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block9.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block10.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block10.text1")}
          </Typography>
        </Container>
        <Container sx={{ pt: 2 }}>
          <Typography variant="h5">{`${t(
            "static.terms.block11.title",
          )}`}</Typography>
          <Typography variant="body1">
            {t("static.terms.block11.text1")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block11.text2")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block11.text3")}
          </Typography>
          <Typography variant="body1">
            {t("static.terms.block11.text4")}
          </Typography>
        </Container>
      </Container>
    </Box>
  );
};
