import { JOBSTATUS } from "@ero/app-common/enums";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { AccessTime, FmdGood } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { jobStatusFillColor } from "Constants";
import { useTotalArea } from "Hooks/totalArea";
import { useTotalWorkTime } from "Hooks/totalWorkTime";
import { AppState } from "Store";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Cell, Pie, PieChart } from "recharts";
import { Deadline } from "./deadline";

const COLORS = [
  jobStatusFillColor[JOBSTATUS.OPEN],
  jobStatusFillColor[JOBSTATUS.DRIVING_TO_LOCATION],
  jobStatusFillColor[JOBSTATUS.IN_WORK],
  jobStatusFillColor[JOBSTATUS.PAUSE],
  jobStatusFillColor[JOBSTATUS.DONE],
];

export const OverviewCard: React.FC = () => {
  const [t] = useTranslation();

  const theme = useTheme();

  const { order } = useSelector((state: AppState) => state.orderDetails);
  const selectedUnit = useSelector(
    (store: AppState) => store.auth.companyData.unitOfMeasurement,
  );

  const totalArea = useTotalArea(order?.jobDetails?.jobs || []);
  const totalWorkTime = useTotalWorkTime(order?.jobDetails?.jobs || []);

  const isFullHDorLess = useMediaQuery("@media (max-width:1920px)");
  const isFullHD125orLess = useMediaQuery("@media (max-width: 1536px)"); // Full HD at 125% Zoom = 80% resolution

  const chartData = useMemo(
    () => [
      {
        name: t("general.statuses.open"),
        value: order?.jobDetails?.jobCountOpen,
      },
      {
        name: t("general.statuses.driving_to_location"),
        value: order?.jobDetails?.jobCountDrivingToLocation,
      },
      {
        name: t("general.statuses.in_work"),
        value: order?.jobDetails?.jobCountInWork,
      },
      {
        name: t("general.statuses.pause"),
        value: order?.jobDetails?.jobCountPause,
      },
      {
        name: t("general.statuses.done"),
        value: order?.jobDetails?.jobCountDone,
      },
    ],
    [
      order?.jobDetails?.jobCountOpen,
      order?.jobDetails?.jobCountDrivingToLocation,
      order?.jobDetails?.jobCountInWork,
      order?.jobDetails?.jobCountPause,
      order?.jobDetails?.jobCountDone,
      t,
    ],
  );

  const pieChartSize = useMemo(() => {
    const baseSize = 10 + +!isFullHD125orLess * 5 + +!isFullHDorLess * 5;
    const size = parseInt(theme.spacing(baseSize).replace("px", ""));
    return size;
  }, [isFullHDorLess, isFullHD125orLess, theme]);

  return (
    <Card>
      <CardContent>
        <Grid container alignItems={"center"} justifyContent={"space-around"}>
          <Grid item xs={12}>
            <Typography variant="h6">{t("order_overview.overview")}</Typography>
          </Grid>
          <Grid item>
            {order?.jobDetails && (
              <PieChart
                width={pieChartSize}
                height={pieChartSize}
                style={{ margin: "0 auto" }}
              >
                <Pie
                  data={chartData}
                  innerRadius="60%"
                  outerRadius="100%"
                  dataKey="value"
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            )}
          </Grid>
          <Grid item xs={12} xl={9}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <AccessTime />
                </ListItemIcon>
                <ListItemText
                  primary={totalWorkTime}
                  secondary={t("order_overview.totalTime")}
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemIcon>
                  <FmdGood />
                </ListItemIcon>
                <ListItemText
                  primary={
                    totalArea
                      ? `${totalArea.toFixed(2)} ${UnitSymbol[selectedUnit]}`
                      : `- ${UnitSymbol[selectedUnit]}`
                  }
                  secondary={t("dashboard.sidebar.totalArea")}
                />
              </ListItem>{" "}
              <Divider />
              <Deadline />
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
