import { buffer, Feature, MultiPolygon, Polygon, union } from "@turf/turf";
import { MERGE_BUFFER_DISTANCE, MERGING_ERROR } from "../../utils";

const isPolygonFeature = (
  polygon: Feature<Polygon | MultiPolygon> | null,
): polygon is Feature<Polygon> =>
  polygon !== null && polygon.geometry.type === "Polygon";

export const mergePolygons = (
  polygons: Feature<Polygon>[],
): Feature<Polygon> => {
  const merged: Feature<Polygon> = polygons
    // extend polygon with buffer to be able to merge polygons with minimal distance but no actual overlap
    .map((polygon) =>
      buffer(polygon, MERGE_BUFFER_DISTANCE, {
        units: "meters",
      }),
    )
    // merge one after another
    .reduce((acc, polygon) => {
      const result = union(acc, polygon);
      if (!isPolygonFeature(result)) {
        throw new Error("Unable to merge parcels", {
          cause: MERGING_ERROR.NOT_CONTIGUOUS,
        });
      }
      return result;
    }, polygons[0]);

  return buffer(merged, -MERGE_BUFFER_DISTANCE, { units: "meters" });
};
