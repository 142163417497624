import { CustomerOutside } from "@ero/app-common/models";
import {
  RequestBody,
  type GetTableDataParams,
  type GetTableEntitiesType,
  type SetTableEntityType,
} from "Types";
import {
  CUSTOMERS_ACTION_TYPES,
  IResetSearchAction,
  type IChangeColumnsOrder,
  type ICreateCustomerAction,
  type ICreateCustomerSuccessAction,
  type IDeleteCustomersAction,
  type IGetCustomersRequestAction,
  type IGetCustomersSuccessAction,
  type IGetExampleFileRequestAction,
  type IGetInitialCustomersErrorAction,
  type IGetInitialCustomersRequestAction,
  type IGetInitialCustomersSuccessAction,
  type IResetMetaAction,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type ISetTableHiddenColumns,
  type IUpdateCustomerAction,
} from "./action-types";
import { type LocalCustomerType } from "./specs";

export const setLoading = (): ISetLoadingAction => ({
  type: CUSTOMERS_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: CUSTOMERS_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: CUSTOMERS_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: CUSTOMERS_ACTION_TYPES.RESET_META,
});
export const resetSearch = (): IResetSearchAction => ({
  type: CUSTOMERS_ACTION_TYPES.RESET_SEARCH,
});

export const getCustomersInitial = (
  params: GetTableDataParams,
): IGetInitialCustomersRequestAction => {
  return {
    type: CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_REQUEST,
    payload: {
      params,
    },
  };
};

export const getCustomersInitialSuccess = (
  customers: LocalCustomerType[],
  maxCount: number,
): IGetInitialCustomersSuccessAction => ({
  type: CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_SUCCESS,
  payload: {
    customers,
    maxCount,
  },
});

export const getCustomersInitialError =
  (): IGetInitialCustomersErrorAction => ({
    type: CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_ERROR,
  });

export const createCustomer = (
  data: RequestBody<"/customers/create">,
): ICreateCustomerAction => ({
  type: CUSTOMERS_ACTION_TYPES.CREATE_CUSTOMER,
  payload: { data },
});

export const createCustomerSuccess = (
  data: CustomerOutside,
): ICreateCustomerSuccessAction => ({
  type: CUSTOMERS_ACTION_TYPES.CREATE_CUSTOMER_SUCCESS,
  payload: { data },
});

export const updateCustomer = (
  updateData: RequestBody<"/customers/update">,
  id: number,
): IUpdateCustomerAction => ({
  type: CUSTOMERS_ACTION_TYPES.UPDATE_CUSTOMER,
  payload: { updateData, id },
});

export const deleteCustomers = (ids: number[]): IDeleteCustomersAction => ({
  type: CUSTOMERS_ACTION_TYPES.DELETE_CUSTOMERS,
  payload: { ids },
});

export const getCustomers = ({
  params,
  withSuccess,
  search,
}: GetTableEntitiesType): IGetCustomersRequestAction => ({
  type: CUSTOMERS_ACTION_TYPES.GET_CUSTOMERS_REQUEST,
  payload: { params, withSuccess, search },
});

export const getCustomersSuccess = ({
  list,
  maxCount,
  listMeta,
  search,
}: SetTableEntityType<LocalCustomerType>): IGetCustomersSuccessAction => ({
  type: CUSTOMERS_ACTION_TYPES.GET_CUSTOMERS_SUCCESS,
  payload: { customers: list, maxCount, listMeta, search },
});

export const getExampleFile = (): IGetExampleFileRequestAction => ({
  type: CUSTOMERS_ACTION_TYPES.GET_EXAMPLE_FILE_REQUEST,
  payload: {
    table: "customers",
  },
});

export const setTableHiddenColumns = (
  hiddenColumns: Record<string, boolean>,
): ISetTableHiddenColumns => ({
  type: CUSTOMERS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS,
  payload: { hiddenColumns },
});

export const changeColumnsOrder = (order: number[]): IChangeColumnsOrder => ({
  type: CUSTOMERS_ACTION_TYPES.CHANGE_COLUMNS_ORDER,
  payload: { order },
});
