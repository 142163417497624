import { Cancel, Edit, Save } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CircularProgress,
  Fab,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGeoposition } from "Hooks/useGeoposition";
import { CenterControlV2, FullScreenControlV2, MapV2 } from "ProjectComponents";
import {
  BottomControls,
  TopControls,
} from "ProjectComponents/map/components/controls";
import { MarkerV2 } from "ProjectComponents/map/components/marker";
import { type AppState } from "Store";
import { updateCompanyData } from "Store/auth";
import { Coordinate } from "Types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

export const OrganisationMapForm: React.FC = () => {
  const map = useRef<google.maps.Map>();

  const { geoposition, watching, addWatcher, removeWatcher } = useGeoposition();

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { companyData, loading } = useSelector((state: AppState) => state.auth);

  const [zoom, setZoom] = useState<number>(14);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [position, setPosition] = useState<Coordinate | undefined>(
    companyData.machinePosition ?? geoposition ?? undefined,
  );

  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    map.current = mapInstance;
  }, []);

  const onZoomChanged = useCallback(() => {
    const currentZoom = map.current?.getZoom();
    if (currentZoom) setZoom(currentZoom);
  }, [map]);

  const resetCenter = useCallback(() => {
    const position = companyData.machinePosition || geoposition;
    if (position) {
      map.current?.setCenter(position);
    }
  }, [companyData.machinePosition, geoposition]);

  const onMapClick = useCallback(
    (e: google.maps.MapMouseEvent): void => {
      editMode &&
        e.latLng &&
        setPosition({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });
    },
    [editMode, setPosition],
  );

  const onMarkerDragEnd = useCallback(
    (e: google.maps.MapMouseEvent) =>
      e.latLng &&
      setPosition({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      }),
    [setPosition],
  );

  const saveNewPosition = useCallback((): void => {
    if (!position) return;

    const config = { machinePosition: position };
    dispatch(updateCompanyData(config));

    setEditMode(false);
    if (watching) removeWatcher();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  const edit = useCallback(() => {
    setEditMode(true);
    if (!watching) addWatcher();
  }, [watching, addWatcher]);

  const cancel = useCallback(() => {
    setEditMode(false);
    setPosition(companyData.machinePosition);
    if (marker) {
      marker.setMap(null);
    }
    setMarker(null);
  }, [companyData.machinePosition, marker]);

  useEffect(() => {
    return () => {
      if (watching) removeWatcher();
    };
  }, [watching, removeWatcher]);

  const SaveButtons = (
    <Grid container flexDirection={"column"} spacing={1}>
      <Grid item>
        <Tooltip title={t("profile.map.cancel_button") as string}>
          <Fab onClick={cancel} color="primary">
            <Cancel />
          </Fab>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={t("profile.map.save_button") as string}>
          <span>
            <Fab
              disabled={position == companyData.machinePosition}
              onClick={saveNewPosition}
              color="primary"
            >
              <Save />
            </Fab>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const EditButton = (
    <>
      <Tooltip title={t("profile.map.edit_button") as string}>
        <span>
          <Fab disabled={loading} onClick={edit} color="primary">
            <Edit />
          </Fab>
        </span>
      </Tooltip>
      {loading && <CircularProgress color="primary" size={68} />}
    </>
  );

  if (!companyData) {
    return null;
  }

  return (
    <>
      <Typography variant="h6">{t("profile.map.company_location")}</Typography>
      <Card sx={{ height: "100%" }}>
        <CardContent sx={{ height: "100%", padding: "0px !important" }}>
          <MapV2
            id="company-location-map"
            center={position}
            zoom={zoom}
            onLoad={onLoad}
            onZoomChanged={onZoomChanged}
            onClick={onMapClick}
          >
            <TopControls>{!editMode ? EditButton : SaveButtons}</TopControls>
            <BottomControls>
              <CenterControlV2 onCenter={resetCenter}></CenterControlV2>
              <FullScreenControlV2 mapRef={map} />
            </BottomControls>
            {position && (
              <MarkerV2
                position={position}
                draggable={editMode && true}
                onDragEnd={onMarkerDragEnd}
              />
            )}
          </MapV2>
        </CardContent>
      </Card>
    </>
  );
};
