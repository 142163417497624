import { type spec_shape } from "@ero/app-common/routes/validators";
import {
  createPlot,
  createPlotForParcel,
  deletePlots,
  getPlots,
  getPlotsOfParcel,
  updatePlot,
} from "Api";
import { errorToast } from "Services";
import { getTableParamsAfterDeletion } from "Utils";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import z from "zod";
import {
  ICreatePlotForParcelAction,
  IDeletePlotsOfParcelAction,
  PLOTS_ACTION_TYPES,
  type ICreatePlotAction,
  type IDeletePlotsAction,
  type IGetInitialPlotsRequestAction,
  type IGetPlotsForParcelRequestAction,
  type IGetPlotsRequestAction,
  type IUpdatePlotAction,
} from "./action-types";
import {
  getPlotsInitialError,
  getPlotsInitialSuccess,
  getPlotsSuccess,
  resetMeta,
  setError,
  setLoading,
  setSuccess,
} from "./actions";
const plotsSpec = [
  ["_id"],
  ["flurstuecksnummer"],
  ["size"],
] as unknown as z.infer<typeof spec_shape>;

export function* getInitialPlotsSaga({
  payload,
}: IGetInitialPlotsRequestAction) {
  const { params, fieldId } = payload;

  try {
    const store = yield select();

    const { data } = yield call(getPlots, {
      fieldId,
      params,
      search: store.plots.searchData,
      spec: plotsSpec,
    });

    yield put(getPlotsInitialSuccess(data.data, data.maxCount));
  } catch (error) {
    yield put(getPlotsInitialError());

    errorToast(error);
  }
}

export function* getPlotsSaga({ payload }: IGetPlotsRequestAction) {
  const { params, search } = payload;

  try {
    const store = yield select();

    const { data } = yield call(getPlots, {
      fieldId: store.plots.fieldId,
      params,
      search,
      spec: plotsSpec,
    });

    yield put(
      getPlotsSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: params,
        search,
      }),
    );
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* getPlotsForParcelSaga({
  payload,
}: IGetPlotsForParcelRequestAction) {
  const { id } = payload;

  try {
    //const store = yield select();
    const { data } = yield call(getPlotsOfParcel, {
      parcelId: id as unknown as string,
      spec: plotsSpec,
    });

    yield put(
      getPlotsSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: { page: -1, pageLength: -1 },
      }),
    );
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* createPlotSaga({ payload }: ICreatePlotAction) {
  const { data } = payload;

  try {
    yield put(setLoading());

    const store = yield select();

    yield call(createPlot, store.plots.fieldId, data);

    const plotsResult = yield call(getPlots, {
      fieldId: store.plots.fieldId,
      params: store.plots.listMeta,
      search: store.plots.searchData,
      spec: plotsSpec,
    });

    yield put(
      getPlotsSuccess({
        list: plotsResult.data.data,
        maxCount: plotsResult.data.maxCount,
        listMeta: store.plots.listMeta,
        search: store.plots.searchData,
      }),
    );

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* createPlotForParcelSaga({
  payload,
}: ICreatePlotForParcelAction) {
  const { data } = payload;

  try {
    yield put(setLoading());

    const store = yield select();

    yield call(createPlotForParcel, store.parcels.parcel._id, data);

    const plotsResult = yield call(getPlotsOfParcel, {
      parcelId: store.plots.parcelId,
      params: store.plots.listMeta,
      search: store.plots.searchData,
      spec: plotsSpec,
    });

    yield put(
      getPlotsSuccess({
        list: plotsResult.data.data,
        maxCount: plotsResult.data.maxCount,
        listMeta: store.plots.listMeta,
        search: store.plots.searchData,
      }),
    );

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* updatePlotSaga({ payload }: IUpdatePlotAction) {
  const { updateData, id } = payload;

  try {
    yield put(setLoading());

    const store = yield select();

    yield call(updatePlot, updateData, id);

    const plotsResult = yield call(getPlots, {
      fieldId: store.plots.fieldId,
      params: store.plots.listMeta,
      search: store.plots.searchData,
      spec: plotsSpec,
    });

    yield put(
      getPlotsSuccess({
        list: plotsResult.data.data,
        maxCount: plotsResult.data.maxCount,
        listMeta: store.plots.listMeta,
        search: store.plots.searchData,
      }),
    );

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* deletePlotsSaga({ payload }: IDeletePlotsAction) {
  const { ids } = payload;

  try {
    yield call(deletePlots, ids);

    const store = yield select();

    const listMeta = getTableParamsAfterDeletion(
      store.plots.listMeta,
      store.plots.list.length,
      ids.length,
    );

    const plotsResult = yield call(getPlots, {
      fieldId: store.plots.fieldId,
      params: listMeta,
      search: store.plots.searchData,
      spec: plotsSpec,
    });

    yield put(
      getPlotsSuccess({
        list: plotsResult.data.data,
        maxCount: plotsResult.data.maxCount,
        listMeta,
        search: store.plots.searchData,
      }),
    );

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export function* deletePlotsOfParcelSaga({
  payload,
}: IDeletePlotsOfParcelAction) {
  const { ids } = payload;

  try {
    yield call(deletePlots, ids);

    const store = yield select();

    const plotsResult = yield call(getPlotsOfParcel, {
      parcelId: store.plots.parcelId,
      search: store.plots.searchData,
      spec: plotsSpec,
    });

    yield put(
      getPlotsSuccess({
        list: plotsResult.data.data,
        listMeta: {
          pageLength: -1,
          page: -1,
        },
        maxCount: plotsResult.data.maxCount,
        search: store.plots.searchData,
      }),
    );

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(error);
  }
}

export default function* plotsSagas() {
  yield all([
    takeEvery(
      PLOTS_ACTION_TYPES.GET_INITIAL_PLOTS_REQUEST,
      getInitialPlotsSaga,
    ),
    takeEvery(PLOTS_ACTION_TYPES.GET_PLOTS_REQUEST, getPlotsSaga),
    takeEvery(
      PLOTS_ACTION_TYPES.GET_PLOTS_FOR_PARCEL_REQUEST,
      getPlotsForParcelSaga,
    ),
    takeEvery(PLOTS_ACTION_TYPES.CREATE_PLOT, createPlotSaga),
    takeEvery(
      PLOTS_ACTION_TYPES.CREATE_PLOT_FOR_PARCEL,
      createPlotForParcelSaga,
    ),
    takeEvery(PLOTS_ACTION_TYPES.UPDATE_PLOT, updatePlotSaga),
    takeEvery(PLOTS_ACTION_TYPES.DELETE_PLOTS, deletePlotsSaga),
    takeEvery(
      PLOTS_ACTION_TYPES.DELETE_PLOTS_OF_PARCEL,
      deletePlotsOfParcelSaga,
    ),
  ]);
}
