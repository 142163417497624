import { CustomerOutside } from "@ero/app-common/models";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { RequestBody, type GetTableDataParams } from "Types";
import { Action } from "redux";
import { type LocalCustomerType } from "./specs";

export enum CUSTOMERS_ACTION_TYPES {
  GET_INITIAL_CUSTOMERS_REQUEST = "customers/get-customers-initial-request",
  GET_INITIAL_CUSTOMERS_SUCCESS = "customers/get-customers-initial-success",
  GET_INITIAL_CUSTOMERS_ERROR = "customers/get-customers-error",
  GET_CUSTOMERS_REQUEST = "customers/get-customers-request",
  GET_CUSTOMERS_SUCCESS = "customers/get-customers-success",
  CREATE_CUSTOMER = "customers/create-customer",
  CREATE_CUSTOMER_SUCCESS = "customers/create-customer-success",
  UPDATE_CUSTOMER = "customers/update-customer",
  DELETE_CUSTOMERS = "customers/delete-customers",
  GET_EXAMPLE_FILE_REQUEST = "customers/get-example-file-request",
  SET_LOADING = "customers/set-loading",
  SET_SUCCESS = "customers/set-success",
  RESET_META = "customers/reset-meta",
  RESET_SEARCH = "customers/reset-search",
  SET_ERROR = "customers/set-error",
  SET_TABLE_HIDDEN_COLUMNS = "customers/set-table-hidden-columns",
  CHANGE_COLUMNS_ORDER = "customers/change-columns-order",
}

export interface ISetLoadingAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.RESET_META;
}

export interface IResetSearchAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.RESET_SEARCH;
}

export interface IGetInitialCustomersRequestAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_REQUEST;
  payload: {
    params: GetTableDataParams;
  };
}

export interface IGetInitialCustomersSuccessAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_SUCCESS;
  payload: {
    customers: LocalCustomerType[];
    maxCount: number;
  };
}

export interface IGetInitialCustomersErrorAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.GET_INITIAL_CUSTOMERS_ERROR;
}

export interface IGetCustomersRequestAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.GET_CUSTOMERS_REQUEST;
  payload: {
    params: GetTableDataParams;
    search?: SearchSpec;
    withSuccess?: boolean;
  };
}

export interface IGetCustomersSuccessAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.GET_CUSTOMERS_SUCCESS;
  payload: {
    customers: LocalCustomerType[];
    maxCount: number;
    listMeta: GetTableDataParams;
    search?: SearchSpec;
  };
}

export interface ICreateCustomerAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.CREATE_CUSTOMER;
  payload: { data: RequestBody<"/customers/create"> };
}

export interface ICreateCustomerSuccessAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.CREATE_CUSTOMER_SUCCESS;
  payload: { data: CustomerOutside };
}

export interface IUpdateCustomerAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.UPDATE_CUSTOMER;
  payload: { updateData: Record<string, string | number>; id: number };
}

export interface IDeleteCustomersAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.DELETE_CUSTOMERS;
  payload: { ids: number[] };
}

export interface IGetExampleFileRequestAction extends Action {
  type: CUSTOMERS_ACTION_TYPES.GET_EXAMPLE_FILE_REQUEST;
  payload: {
    table: "customers";
  };
}

export interface ISetTableHiddenColumns extends Action {
  type: CUSTOMERS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: { hiddenColumns: Record<string, boolean> };
}

export interface IChangeColumnsOrder extends Action {
  type: CUSTOMERS_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export type CustomersAction =
  | IGetInitialCustomersRequestAction
  | IGetInitialCustomersSuccessAction
  | IGetInitialCustomersErrorAction
  | IGetCustomersRequestAction
  | IGetCustomersSuccessAction
  | ICreateCustomerAction
  | IUpdateCustomerAction
  | IDeleteCustomersAction
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | IResetSearchAction
  | IGetExampleFileRequestAction
  | ICreateCustomerSuccessAction
  | ISetTableHiddenColumns
  | IChangeColumnsOrder;
