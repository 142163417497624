import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { Grid } from "@mui/material";
import { DateInput } from "ProjectComponents/FormikDateInput/DateInput";
import { DateTimeInput } from "ProjectComponents/FormikDateInput/DateTimeInput";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { ValuesType } from "../formConfig";

export const DateInputContent: React.FC = () => {
  const [t] = useTranslation();
  const { values } = useFormikContext<ValuesType>();

  const dateMode = values.dateRestrictions.dateMode;
  const timeMode = values.dateRestrictions.timeMode;

  switch (dateMode) {
    case DateMode.FIXEDDATE:
      if (timeMode == DateTimeMode.WITHTIME) {
        return (
          <DateTimeInput
            name="dateRestrictions.date"
            required
            label={t("orders.create_modal.date")}
          />
        );
      } else {
        return (
          <DateInput
            name="dateRestrictions.date"
            required
            label={t("orders.create_modal.date")}
          />
        );
      }

    case DateMode.DATERANGE:
      if (timeMode == DateTimeMode.WITHTIME) {
        return (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <DateTimeInput
                name="dateRestrictions.startDate"
                label={t("orders.create_modal.startDate")}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimeInput
                name="dateRestrictions.endDate"
                required
                label={t("orders.create_modal.endDate")}
              />
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <DateInput
                name="dateRestrictions.startDate"
                label={t("orders.create_modal.startDate")}
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                name="dateRestrictions.endDate"
                label={t("orders.create_modal.endDate")}
                required
              />
            </Grid>
          </Grid>
        );
      }
  }
};
