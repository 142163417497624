import { CARDINAL_DIRECTION } from "@ero/app-common/enums/cardinalDirection";
import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { STAIN } from "@ero/app-common/enums/stain";
import { OptionType } from "Types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useSizeFieldOptions = (): OptionType[] => {
  const [t] = useTranslation();
  const options = useMemo(
    () => [
      {
        label: t("parcels.fields.size_manual"),
        value: SIZE_FIELD.SIZE_MANUAL,
      },
      {
        label: t("parcels.fields.size_imported_nufl"),
        value: SIZE_FIELD.SIZE_IMPORTED_NUFL,
      },
      {
        label: t("parcels.fields.size_imported_kafl"),
        value: SIZE_FIELD.SIZE_IMPORTED_KAFL,
      },
      {
        label: t("parcels.fields.size_automatic"),
        value: SIZE_FIELD.SIZE_AUTOMATIC,
      },
    ],
    [t],
  );
  return options;
};

export const useStainOptions = (): OptionType[] => {
  const [t] = useTranslation();
  const options = useMemo(
    () => [
      { label: t("parcels.stain.none"), value: STAIN.NONE },
      { label: t("parcels.stain.weak"), value: STAIN.WEAK },
      { label: t("parcels.stain.strong"), value: STAIN.STRONG },
    ],
    [t],
  );
  return options;
};

const orientationOptions = Object.keys(CARDINAL_DIRECTION)
  .filter((key) => isNaN(Number(key)))
  .map((key) => {
    return {
      label: key,
      value: CARDINAL_DIRECTION[key],
    };
  });
export const useOrientationOptions = (): OptionType[] => {
  return orientationOptions;
};
