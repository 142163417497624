import { type MachineResponseBody } from "@ero/app-common/v2/routes/models/machine";
import { AssignmentOutlined, InfoOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { type MachineType, type SocketMachine } from "Types";
import { getJobStatusColor, getJobStatusLabel } from "Utils";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type Machine = MachineType | SocketMachine | MachineResponseBody;

interface IMachineInfoWindowV2 {
  machine: Machine;
  handleEdit: (
    type: "machine" | "job",
    entry: number,
    orderId: number | undefined,
  ) => void;
}

export const MachineInfoWindowV2: React.FC<IMachineInfoWindowV2> = ({
  machine,
  handleEdit,
}) => {
  const [t] = useTranslation();

  const driverLabel = useMemo((): string => {
    const driver = machine.currentDriver;
    if (driver === undefined || driver === null) {
      return t("machines.empty_machine");
    }
    return [driver.firstName, driver.lastName].join(" ");
  }, [machine.currentDriver, t]);

  const DriverAvatar = useCallback((): JSX.Element | null => {
    const driver = machine.currentDriver;
    if (driver === undefined || driver === null) {
      return null;
    }
    if (!driver.avatar) {
      return null;
    }

    return <Avatar src={driver.avatar} alt="driver" />;
  }, [machine.currentDriver]);

  const currentJobStatusLabel = useMemo(
    () =>
      getJobStatusLabel(
        machine.currentJob?._id != -1 ? machine.currentJob?.status : undefined,
      ),
    [machine.currentJob],
  );

  const currentJobStatusLabelColor = useMemo(
    () =>
      machine.currentJob?._id !== -1
        ? getJobStatusColor(machine.currentJob?.status)
        : "",
    [machine.currentJob],
  );

  const machineHasJob = useMemo(
    () => machine.currentJob?._id !== -1,
    [machine.currentJob],
  );

  return (
    // this grid uses fixed widths because of mui grids responsive width
    // and google maps info window taking the width of it's child containers
    <Box maxWidth={490} overflow="hidden" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
      <Grid container columnSpacing={1}>
        <Grid item sx={{ paddingTop: "2px" }}>
          {machine.avatar && (
            <Avatar
              style={{ width: 32, height: 32 }}
              src={machine.avatar}
              alt="machine"
            />
          )}
        </Grid>
        <Grid item style={{ width: 300 }} container direction="column">
          <Grid item>
            <Typography variant="h5" gutterBottom={true}>
              {machine.name}
            </Typography>
          </Grid>
          {!!machine.currentDriver && (
            <>
              <Grid item container spacing={1} alignItems="center">
                <Grid item xs={2}>
                  <DriverAvatar />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body1">{driverLabel}</Typography>
                </Grid>
              </Grid>
            </>
          )}
          {!machineHasJob && (
            <Grid item>
              <Typography variant="body1">{t("jobs.no_job_chosen")}</Typography>
            </Grid>
          )}
          {machineHasJob && (
            <>
              <Grid item>
                <Typography variant="body1">{`${t(
                  "orders.labels.details_tab",
                )}: ${
                  machine.currentOrder?.name || t("general.labels.not_selected")
                }`}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{`${t(
                  "general.fields.customer",
                )}: ${
                  machine.currentCustomer?.companyName ||
                  t("general.labels.not_selected")
                }`}</Typography>
              </Grid>
            </>
          )}
          {machineHasJob && (
            <>
              <Grid item>
                <Typography variant="body1">{`${t("general.labels.job")}: ${
                  machine.currentJob?.name || t("general.labels.not_selected")
                }`}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" display="inline">{`${t(
                  "general.fields.status",
                )}: `}</Typography>
                <Typography
                  display="inline"
                  variant="body1"
                  style={{
                    color: currentJobStatusLabelColor,
                  }}
                >
                  {currentJobStatusLabel}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          style={{ width: 50 }}
          container
          spacing={1}
          direction="column"
        >
          <Grid item>
            <Tooltip
              title={t("general.buttons.showMachineInformation") as string}
            >
              <IconButton
                size="small"
                onClick={() => handleEdit("machine", machine._id, undefined)}
                sx={{ color: "rgba(0, 0, 0, 0.87)" }}
              >
                <InfoOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
          {machineHasJob && (
            <Grid item>
              <Tooltip title={t("dashboard.calendar.open_job") as string}>
                <IconButton
                  onClick={() => {
                    if (machine.currentJob)
                      handleEdit(
                        "job",
                        machine.currentJob._id,
                        machine.currentOrder?._id,
                      );
                  }}
                  size="small"
                  sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                >
                  <AssignmentOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
