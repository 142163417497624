import { ParcelOutside } from "@ero/app-common/models";
import { type APISpec } from "@ero/app-common/routes/enforcer";
import {
  genericAllBody_shape,
  searchSpec_shape,
} from "@ero/app-common/routes/validators";
import {
  ParcelsRequestQuery,
  ParcelsResponseBody,
} from "@ero/app-common/v2/routes/models/parcel";
import { SubUrls, baseUrl } from "@ero/app-common/v2/routes/parcel";
import { apiInstance, apiInstanceV2 } from "App/axios";
import { GET_ALL_PARAMS } from "Constants";
import { ImportParcelTableRow } from "Screens/parcels/types";
import {
  type ApiListRequestParams,
  type ApiRequestParams,
  type GetTableDataParams,
} from "Types";
import z from "zod";

type Parcels_all = APISpec["/parcels/all"];
export const api_parcels_all = async (
  body: Parcels_all["body"],
  query: Parcels_all["query"],
) => {
  return apiInstance.post<Parcels_all["response"]>("/parcels/all", body, {
    params: query,
  });
};

type Parcels_create = APISpec["/parcels/create"];
export const api_parcels_create = async (
  body: Parcels_create["body"],
  query: Parcels_create["query"],
) => {
  return apiInstance.post<Parcels_create["response"]>("/parcels/create", body, {
    params: query,
  });
};

/**
 * Exists to aid in de-tangling confusion between possible store values to
 * minimize unintended behavior changes in deprecated functions. \
 * "If a bug exists long enough, some consumer will be depending on it."
 */
const spreadHelper = (
  params: GetTableDataParams | undefined,
): Partial<GetTableDataParams> => {
  return {
    employee: params === undefined ? undefined : params.employee,
    end: params === undefined ? undefined : params.end,
    excluded: params === undefined ? undefined : params.excluded,
    field: params === undefined ? undefined : params.field,
    machine: params === undefined ? undefined : params.machine,
    notJob: params === undefined ? undefined : params.notJob,
    order: params === undefined ? undefined : params.order,
    page: params === undefined ? undefined : params.page,
    pageLength: params === undefined ? undefined : params.pageLength,
    parcel: params === undefined ? undefined : params.parcel,
    permission: params === undefined ? undefined : params.permission,
    start: params === undefined ? undefined : params.start,
  };
};
/**
 * Since the store type which governs all possible legacy query parameters
 * for all document tables simultaneously i have to decide how to handle this
 * uncertainty. I've decided to give precedent to explicit field values given
 * in any deprecated function's signature over the probably not really existing
 * runtime-value taken from the store.
 */
const fieldDeterminer = (
  signatureProvided: string | undefined,
  fromStore: string | undefined,
): number | undefined => {
  const coalesced = signatureProvided || fromStore;
  if (coalesced === undefined) {
    return undefined;
  }

  const int = parseInt(coalesced);
  return isNaN(int) ? undefined : int;
};

/**
 * @deprecated
 * should be replaced with `api_parcels_all`
 */
export const getParcels = ({
  params,
  search,
  filters,
  fieldId,
  spec,
}: ApiListRequestParams<ParcelOutside> & { fieldId?: string }) => {
  const { excluded, field, order, page, pageLength } = spreadHelper(params);
  const finalField = fieldDeterminer(fieldId, field);

  const finalPage = page ?? GET_ALL_PARAMS.page;
  const finalPageLength = pageLength ?? GET_ALL_PARAMS.pageLength;

  return api_parcels_all(
    { excluded, field: finalField, filters, order, search, spec },
    { page: finalPage, pageLength: finalPageLength },
  );
};

export const getParcelsV2 = async (params: ParcelsRequestQuery) =>
  (
    await apiInstanceV2.get<ParcelsResponseBody>(baseUrl + SubUrls.ROOT, {
      params,
    })
  ).data;

export const getParcel = ({
  id,
  spec,
}: ApiRequestParams<ParcelOutside>): Promise<any> =>
  apiInstance.post("/parcels/view", { _id: id, spec });

/**
 * @deprecated
 * should be replaced with `api_parcels_all`
 */
export const getAllParcelsForOrder = (
  params: GetTableDataParams,
  search: z.infer<typeof searchSpec_shape>,
  filters: z.infer<typeof genericAllBody_shape>["filters"],
) => {
  const { page, pageLength, excluded, field, order } = params;
  const finalField = fieldDeterminer(undefined, field);

  return api_parcels_all(
    { excluded, field: finalField, filters, order, search, spec: [] },
    { page, pageLength },
  );
};

export const getParcelHistory = (id: number) =>
  apiInstanceV2.get(baseUrl + SubUrls.HISTORY.replace(":id", `${id}`));

/**
 * @deprecated
 * should be replaced with `api_parcels_all`
 */
export const getAllParcels = ({
  search,
  filters,
  spec,
}: ApiListRequestParams<ParcelOutside> = {}) =>
  api_parcels_all({ search, filters, spec }, GET_ALL_PARAMS);

export const deleteParcels = (ids: number[]): Promise<any> =>
  apiInstance.post("/parcels/delete", { ids });

export const updateParcel = (
  updateData: APISpec["/parcels/update"]["body"],
  /** @deprecated */
  _id?: number,
  fieldId?: string,
): Promise<any> =>
  apiInstance.post("/parcels/update", {
    ...updateData,
    field: fieldId ? +fieldId : undefined,
  });

export const addParcels = async (
  parcels: ImportParcelTableRow[],
  importID: number,
  accessToken?: string,
) => {
  await apiInstanceV2.post(
    baseUrl + "/:batch",
    {
      parcels,
      importID,
    },
    {
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
    },
  );
};
