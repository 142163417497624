import { type Message } from "@ero/app-common/util/Websocket";

export const createWsInstance = () => {
  let url = "wss://";
  if (process.env.NODE_ENV === "development") {
    url = "ws://";
  }
  url += `${location.host}${process.env.REACT_APP_WS_URL}`;
  return new WebSocket(url);
};

export const serializeWsMessage = <M extends Message, T extends Message["t"]>(
  type: T,
  payload: (M & { t: T })["payload"],
  meta: (M & { t: T })["meta"],
) => JSON.stringify({ t: type, payload, meta });

export const parseWsMessage = (evt: MessageEvent): Message | undefined => {
  try {
    const { t, payload, meta } = JSON.parse(evt.data);
    return { t, payload, meta };
  } catch {
    //do nothing
  }
};
