import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { PlotOutside } from "@ero/app-common/models";
import { UnitSymbol } from "@ero/app-common/util/convertArea";
import { ControlPoint } from "@mui/icons-material";
import { Grid, Stack, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { Input } from "Components";
import { useFormikContext } from "formik";
import { useAllCustomerOptions } from "Hooks/customerOptions";
import { useDropdownValues } from "Hooks/dropdownValues";
import i18n from "i18n";
import { SelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/SelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "ProjectComponents/FormikAutocomplete/StaticSelectWithAutoComplete";
import { ParcelMap } from "ProjectComponents/parcelInformationModal/components/map/map";
import { FormikValues } from "ProjectComponents/parcelInformationModal/validationConfig";
import { PlotModal } from "ProjectComponents/plotModal";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { useSizeFieldOptions } from "./autoCompletes";

export const ParcelData: React.FC = () => {
  const [t] = useTranslation();

  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const { unitOfMeasurement } = useSelector(
    (store: AppState) => store.auth.companyData,
  );

  const [showPlotModal, setShowPlotModal] = useState(false);
  const [plotToEdit, setPlotToEdit] = useState<PlotOutside | undefined>(
    undefined,
  );

  const { fetchCustomerOptions } = useAllCustomerOptions();

  const openPlotModal = useCallback((plot?: PlotOutside) => {
    setPlotToEdit(plot);
    setShowPlotModal(true);
  }, []);

  const plotSubmitHandler = useCallback(
    (value: PlotOutside) => {
      const filtered = values.flurstuecke?.filter(
        (curr) => curr.flurstuecksnummer != value.flurstuecksnummer,
      );
      setFieldValue("flurstuecke", [...(filtered || []), value]);
    },
    [setFieldValue, values.flurstuecke],
  );

  const sizeFieldOptions = useSizeFieldOptions();

  const { fetchDropdownValues: fetchCropOptions } = useDropdownValues("crop");

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Input
                name={"name"}
                type="text"
                label={i18n.t("general.labels.parcel_name")}
                mandatory={true}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectWithAutoComplete
                name="customer"
                required
                fetchOptions={fetchCustomerOptions}
                disableClearable
                label={t("general.fields.customer")}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectWithAutoComplete
                name="crop"
                fetchOptions={fetchCropOptions}
                label={t("orders.fields.crop")}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name="rowAmount"
                type="number"
                label={t("orders.fields.rowAmount")}
                placeholder={""}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name="rowGap"
                type="number"
                label={t("orders.fields.rowGap")}
                placeholder={""}
              />
            </Grid>
            <Grid item xs={3}>
              <StaticSelectWithAutoComplete
                name="sizeField"
                options={sizeFieldOptions}
                label={t("parcels.fields.size_field", {
                  unit: UnitSymbol[unitOfMeasurement],
                })}
                disabledValues={[
                  ...(!values.sizeImportedKAFL
                    ? [SIZE_FIELD.SIZE_IMPORTED_KAFL]
                    : []),
                  ...(!values.sizeImportedNUFL
                    ? [SIZE_FIELD.SIZE_IMPORTED_NUFL]
                    : []),
                ]}
              />
            </Grid>
            <Grid item xs={3}>
              {values.sizeField == SIZE_FIELD.SIZE_AUTOMATIC && (
                <Input
                  name="sizeAutomatic"
                  type="text"
                  label={t("parcels.fields.size_automatic_unit", {
                    unit: UnitSymbol[unitOfMeasurement],
                  })}
                  disabled={true}
                />
              )}
              {values.sizeField == SIZE_FIELD.SIZE_IMPORTED_KAFL && (
                <Input
                  name={"sizeImportedKAFL"}
                  type="number"
                  label={t("parcels.fields.size_imported_kafl_unit", {
                    unit: UnitSymbol[unitOfMeasurement],
                  })}
                  disabled={true}
                />
              )}
              {values.sizeField == SIZE_FIELD.SIZE_IMPORTED_NUFL && (
                <Input
                  name={"sizeImportedNUFL"}
                  type="number"
                  label={t("parcels.fields.size_imported_nufl_unit", {
                    unit: UnitSymbol[unitOfMeasurement],
                  })}
                  disabled={true}
                />
              )}
              {values.sizeField == SIZE_FIELD.SIZE_MANUAL && (
                <Input
                  name={"sizeManual"}
                  type="number"
                  label={t("parcels.fields.size_manual_unit", {
                    unit: UnitSymbol[unitOfMeasurement],
                  })}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <Input
                name="plantAmount"
                type="number"
                label={t("general.fields.plantAmount")}
                placeholder={""}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name="plantGap"
                type="number"
                label={t("general.fields.plantGap")}
                placeholder={""}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t("parcels.situation_information")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Input
                name={"growingarea"}
                type="text"
                label={i18n.t("parcels.fields.growingarea")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name={"region"}
                type="text"
                label={i18n.t("orders.fields.region")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="largeRegion"
                type="text"
                size="medium"
                label={t("orders.fields.regions")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="separatedregion"
                type="text"
                size="medium"
                label={t("parcels.fields.separatedregion")}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name={"regionette"}
                type="text"
                label={i18n.t("orders.fields.regionette")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t("parcels.plot_information")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Input
                name={"mark"}
                type="text"
                label={i18n.t("parcels.fields.gemarkung")}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={"gemarkungsnummer"}
                type="number"
                label={i18n.t("parcels.fields.gemarkung_number")}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                name={"flnr"}
                type="number"
                label={i18n.t("parcels.fields.flurnummer")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t("parcels.plots_contained")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={1}>
                {values.flurstuecke?.map((chipFlurstueck, index) => (
                  <Chip
                    key={index}
                    label={`${chipFlurstueck.flurstuecksnummer}`}
                    clickable
                    onClick={() => {
                      openPlotModal(chipFlurstueck as PlotOutside);
                    }}
                    onDelete={() =>
                      setFieldValue(
                        "flurstuecke",
                        values.flurstuecke?.filter(
                          (flurstueck) =>
                            chipFlurstueck.flurstuecksnummer !==
                            flurstueck.flurstuecksnummer,
                        ),
                      )
                    }
                  />
                ))}
                <IconButton
                  onClick={() => {
                    openPlotModal(undefined);
                  }}
                >
                  <ControlPoint />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <ParcelMap />
        </Grid>
      </Grid>
      <PlotModal
        submitHandler={plotSubmitHandler}
        isOpen={showPlotModal}
        onClose={() => setShowPlotModal(false)}
        plot={plotToEdit}
      />
    </>
  );
};
